import api from '@/api';
import type {
  BalanceInfoFromServer,
  BalancePerSubdivisionFromServer,
  GetBalanceStatsService,
} from '@/pages/Balance/types';

export const getBalanceStatsService: GetBalanceStatsService = {
  async getBalanceInfo(): Promise<BalanceInfoFromServer> {
    const response = await api.get<BalanceInfoFromServer>('/finance-stats/total-balance');
    return response.data;
  },

  async getBalancePerSubdivision(): Promise<BalancePerSubdivisionFromServer> {
    const response = await api.get<BalancePerSubdivisionFromServer>('/finance-stats/balance-per-subdivision');
    return response.data;
  },
};
