import type { PropsWithChildren } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';
import TableCell from './TableCell';
import type { TableCellProps, TableRowProps } from './types';

const TableRow = ({ children, className, onClick, sortable }: PropsWithChildren<TableRowProps>) => {
  const enhancedChildren = Children.map(children, (child) => {
    if (!isValidElement(child) || child.type !== TableCell) {
      return child;
    }

    return cloneElement(child, {
      sortable,
    } as TableCellProps);
  });

  return (
    <tr className={cn(styles.row, className)} onClick={onClick}>
      {enhancedChildren}
    </tr>
  );
};

export default TableRow;
