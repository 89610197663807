import { ENDPOINT } from './constants';

import api from '@/api';
import type { CreatePaymentService } from '@/features/Payment/types';

export const createPayment: CreatePaymentService = async (body) => {
  const response = await api.post(ENDPOINT, body);

  return response.data;
};

export const getPayment = async (id: string) => {
  const response = await api.get(`${ENDPOINT}/${id}`);
  return response.data;
};

export const editPayment = () => {};
