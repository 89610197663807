import { OrderStatusChangeReasonEnum, OrderStatusEnum } from '@/features/Order/types';

export const statusChangeReasons = {
  [OrderStatusChangeReasonEnum.NoAnswer]: 'Недозвон',
  [OrderStatusChangeReasonEnum.ChangedMind]: 'Передумал покупать/не приехал',
  [OrderStatusChangeReasonEnum.Spam]: 'Спам',
  [OrderStatusChangeReasonEnum.NoParts]: 'Нет запчастей',
  [OrderStatusChangeReasonEnum.DuplicateDeal]: 'Дубль сделки',
  [OrderStatusChangeReasonEnum.ServiceNotProvided]: 'Не оказываем такие услуги',
  [OrderStatusChangeReasonEnum.UnsuitablePrice]: 'Не устроил срок',
  [OrderStatusChangeReasonEnum.UnsuitableTime]: 'Не устроила цена',
  [OrderStatusChangeReasonEnum.WrongNumber]: 'Ошибся номером',
  [OrderStatusChangeReasonEnum.TechnicalConsultation]: 'Техническая консультация ',
  [OrderStatusChangeReasonEnum.SupplierOrEmployee]: 'Поставщик/Сотрудник',
};

export const orderStatuses = {
  [OrderStatusEnum.Created]: 'Неразобранное',
  [OrderStatusEnum.Consultation]: 'Консультация по продукту',
  [OrderStatusEnum.Negotiations]: 'Ведутся переговоры',
  [OrderStatusEnum.CallBack]: 'Перезвонить',
  [OrderStatusEnum.Appointment]: 'Записался',
  [OrderStatusEnum.Working]: 'Работы выполняются',
  [OrderStatusEnum.Ready]: 'Готов',
  [OrderStatusEnum.Refund]: 'Возврат',
  [OrderStatusEnum.Success]: 'Успешно',
  [OrderStatusEnum.Junk]: 'Мусор',
  [OrderStatusEnum.Declined]: 'Отказ',
};

export enum OrderTab {
  Job = 'job',
  Notes = 'notes',
}

export const orderTabs = [
  {
    label: 'Работы и товары',
    value: OrderTab.Job,
  },
  {
    label: 'Заметки',
    value: OrderTab.Notes,
  },
];
