import styles from './styles.module.scss';

import IconAvatar from '@/components/Icons/IconAvatar';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import { useAppSelector } from '@/store';
import { selectUser } from '@/store/slices/userSlice';
import type { UserType } from '@/types';

const UserInfo = () => {
  const user = useAppSelector(selectUser) as UserType;

  return (
    <div className={styles.userInfo}>
      {user?.avatar?.link ? <img src={user.avatar?.link} alt="Аватарка" /> : <IconAvatar />}
      <span className={styles.userInfoName}>{getAgentFullName(user) || 'Без имени'}</span>
    </div>
  );
};

export default UserInfo;
