import type { GetIndividualAgentsInput, GetIndividualAgentsRequestPayload } from './types';

import api from '@/api';

export const fetchSuppliers = async ({ pageNumber, pageSize }: GetIndividualAgentsInput) => {
  const params: GetIndividualAgentsRequestPayload = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 20,
  };

  const response = await api.get('/counterpart/suppliers', {
    params,
  });
  return response.data;
};
