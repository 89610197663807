const IconUserSquare = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.85301 18.1379L4.85301 18.1379L4.84995 18.137C4.80155 18.1228 4.75595 18.0687 4.75786 17.9997C4.9437 15.8434 7.17624 14.0167 10.0005 14.0167C12.8273 14.0167 15.0552 15.8389 15.244 18.0088C15.2467 18.0436 15.2376 18.0698 15.2246 18.0887C15.2115 18.1076 15.1898 18.126 15.1548 18.1359L15.1548 18.1359L15.148 18.1379C14.4348 18.3527 13.5703 18.4584 12.5005 18.4584H7.50053C6.43071 18.4584 5.56624 18.3527 4.85301 18.1379ZM5.11855 17.417L4.97516 17.9364L5.5039 18.0406C6.09478 18.157 6.75384 18.2084 7.50053 18.2084H12.5005C13.2472 18.2084 13.9063 18.157 14.4972 18.0406L15.0259 17.9364L14.8825 17.417C14.3634 15.5366 12.2851 14.2667 10.0005 14.2667C7.71591 14.2667 5.63762 15.5366 5.11855 17.417Z"
      stroke="#E2E1F0"
    />
    <path
      d="M12.5003 1.66669H7.50033C3.33366 1.66669 1.66699 3.33335 1.66699 7.50002V12.5C1.66699 15.65 2.61699 17.375 4.88366 18.0167C5.06699 15.85 7.29199 14.1417 10.0003 14.1417C12.7087 14.1417 14.9337 15.85 15.117 18.0167C17.3837 17.375 18.3337 15.65 18.3337 12.5V7.50002C18.3337 3.33335 16.667 1.66669 12.5003 1.66669ZM10.0003 11.8083C8.35033 11.8083 7.01699 10.4667 7.01699 8.8167C7.01699 7.1667 8.35033 5.83335 10.0003 5.83335C11.6503 5.83335 12.9837 7.1667 12.9837 8.8167C12.9837 10.4667 11.6503 11.8083 10.0003 11.8083Z"
      stroke="#ADADB9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99994 11.9333C8.28591 11.9333 6.8916 10.5337 6.8916 8.81666C6.8916 7.10113 8.28441 5.70831 9.99994 5.70831C11.7155 5.70831 13.1083 7.10113 13.1083 8.81666C13.1083 10.5337 11.714 11.9333 9.99994 11.9333ZM9.99994 5.95831C8.42379 5.95831 7.1416 7.24052 7.1416 8.81666C7.1416 10.4 8.42265 11.6833 9.99994 11.6833C11.5772 11.6833 12.8583 10.4 12.8583 8.81666C12.8583 7.24052 11.5761 5.95831 9.99994 5.95831Z"
      stroke="#E2E1F0"
    />
  </svg>
);

export default IconUserSquare;
