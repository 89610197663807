import { useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';

import { FormFields, initialValues } from './constants';
import { productFormSchema } from './schema';
import styles from './styles.module.scss';
import type { ProductFormProps } from './types';

import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import Input from '@/components/UI/Input';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Select from '@/components/UI/Select';
import { EVENTS } from '@/constants';
import type { ProductEntity } from '@/features/Product/types';
import { createWarehouseArrivalProduct } from '@/features/Warehouse/services';
import { useAppProvider } from '@/providers/AppProvider/hooks';
import { dispatchEvent } from '@/utils/event';

const ProductForm = ({ isOpen, onClose, onSuccess, warehouseArrival }: ProductFormProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const { brands, countries } = useAppProvider();

  const handleSubmit = async (values: ProductEntity) => {
    setIsFetching(true);

    try {
      const res = await createWarehouseArrivalProduct({
        ...values,
        warehouseArrival: warehouseArrival?._id,
      });
      toast.success('Продукт добавлен');
      dispatchEvent(EVENTS.REFRESH_DATA);
      // @ts-ignore
      onSuccess?.(res?.products.map((item) => item?.product));
      onClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <Formik
        enableReinitialize
        // @ts-ignore
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={productFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Добавить товар</h2>
            </ModalHeader>
            <ModalBody>
              <Flex rowGap="md" flexDirection="column">
                <Grid>
                  <Grid.Item col={6}>
                    <Input
                      error={errors[FormFields.Title]}
                      name={FormFields.Title}
                      onChange={handleChange}
                      value={values[FormFields.Title]}
                      label="Наименование"
                      placeholder="Введите наименование товарной позиции"
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Input
                      error={errors[FormFields.Article]}
                      name={FormFields.Article}
                      onChange={handleChange}
                      value={values[FormFields.Article]}
                      label="Артикул"
                      placeholder="Введите артикул"
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Select
                      error={errors[FormFields.Brand]}
                      name={FormFields.Brand}
                      options={brands.map((brand) => ({
                        label: brand.title,
                        value: brand.title,
                      }))}
                      onChange={(e) => {
                        setFieldValue(FormFields.Brand, e!.value);
                      }}
                      label="Бренд"
                      placeholder="Выберите бренд"
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Select
                      error={errors[FormFields.ManufacturerCountry]}
                      name={FormFields.ManufacturerCountry}
                      options={countries.map((country) => ({
                        label: country.title,
                        value: country.title,
                      }))}
                      onChange={(e) => {
                        setFieldValue(FormFields.ManufacturerCountry, e!.value);
                      }}
                      label="Страна производства"
                      placeholder="Выберите из списка"
                    />
                  </Grid.Item>
                </Grid>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button disabled={isFetching} isLoading={isFetching} type="submit">
                  Сохранить
                </Button>
                <Button disabled={isFetching} onClick={onClose} variant="secondary">
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ProductForm;
