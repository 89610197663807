import { useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { isEmpty } from 'lodash';

import styles from './styles.module.scss';
import type { OrderCarTableModalProps } from './types';

import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Radio from '@/components/UI/Radio';
import { EVENTS } from '@/constants';
import { updateOrder } from '@/features/Order/services';
import { triggerEvent } from '@/utils/event';

export const OrderCarTableModal = ({ isOpen, onClose, order }: OrderCarTableModalProps) => {
  const [selectedCar, setSelectedCar] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async () => {
    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        car: {
          sourceCar: selectedCar,
        },
      });

      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success('Автомобиль успешно добавлен');
      onClose?.();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const renderCars = () =>
    order?.client?.cars.map((car) => (
      <li className={styles.carListItem} key={car._id}>
        <Radio
          onChange={(e) => {
            setSelectedCar(e.target.value);
          }}
          value={car._id}
          label={`${car.brand} ${car.model}`}
        />
      </li>
    ));

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader onClose={onClose}>
        <h2>Список авто клиента</h2>
      </ModalHeader>
      <ModalBody>
        <ul className={styles.carList}>{renderCars()}</ul>
      </ModalBody>
      <ModalFooter>
        <Flex alignItems="center">
          <Button disabled={isEmpty(selectedCar) || isFetching} isLoading={isFetching} onClick={handleSubmit}>
            Добавить
          </Button>
          <Button disabled={isFetching} onClick={onClose} variant="secondary">
            Отмена
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
