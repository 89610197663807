export enum FormFields {
  Email = 'email',
  Password = 'password',
  PasswordRepeat = 'passwordRepeat',
}

export const authFormInitialValues = {
  email: '',
  password: '',
};
