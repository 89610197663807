import { useMemo } from 'react';

import cn from 'classnames';

import { CalendarRange, calendarRanges } from '../CalendarRangeCells/constants';

import styles from './styles.module.scss';
import type { CalendarRangesProps } from './types';

import IconArrowLeft from '@/components/Icons/IconArrowLeft';
import IconArrowRight from '@/components/Icons/IconArrowRight';
import IconCalendar2 from '@/components/Icons/IconCalendar2';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import { useCalendarContext } from '@/features/Calendar';
import { useQueryParams } from '@/hooks';
import dayjs from '@/utils/dayjs';

type Picker = {
  from?: string;
  to?: string;
  range: CalendarRange;
  onPrevDate?: (range: CalendarRange) => void;
  onNextDate?: (range: CalendarRange) => void;
};

export const CalendarRanges = ({ range, onSelect }: CalendarRangesProps) => {
  const [{ query }] = useQueryParams<{ from: string; to: string }>();
  const { onPrevDate, onNextDate, onResetDate } = useCalendarContext();

  const renderDatePicker = useMemo(() => {
    const props = {
      from: query.from,
      to: query.to,
      onNextDate,
      onPrevDate,
      range,
    };

    if (range === CalendarRange.Day) {
      return <CalendarDayPicker {...props} />;
    } else if (range === CalendarRange.Month) {
      return <CalendarMonthPicker {...props} />;
    } else {
      return <CalendarWeekPicker {...props} />;
    }
  }, [calendarRanges, range]);

  const handleOnSelect = (range: CalendarRange) => {
    onResetDate?.(range);
    onSelect(range);
  };

  const renderRanges = useMemo(
    () =>
      calendarRanges.map((calendarRange) => (
        <li key={calendarRange.label}>
          <button
            className={cn(styles.rangesButton, range === calendarRange.label && styles.rangesButtonActive)}
            onClick={() => handleOnSelect(calendarRange.label)}
          >
            {calendarRange.value}
          </button>
        </li>
      )),
    [calendarRanges, range],
  );

  return (
    <Flex alignItems="center">
      <Flex className={styles.picker} columnGap="zero" justifyContent="space-between">
        {renderDatePicker}
      </Flex>
      <ul className={styles.ranges}>{renderRanges}</ul>
    </Flex>
  );
};

const CalendarDayPicker = ({ from, range, onPrevDate, onNextDate }: Picker) => (
  <>
    <IconButton icon={IconArrowLeft} onClick={() => onPrevDate?.(range)} />
    <Flex alignItems="center" className={styles.pickerInner}>
      <IconCalendar2 />
      {dayjs(from).format('DD MMMM YYYY')}
    </Flex>
    <IconButton icon={IconArrowRight} onClick={() => onNextDate?.(range)} />
  </>
);

const CalendarWeekPicker = ({ from, to, range, onPrevDate, onNextDate }: Picker) => (
  <>
    <IconButton icon={IconArrowLeft} onClick={() => onPrevDate?.(range)} />
    <Flex alignItems="center" className={styles.pickerInner}>
      <IconCalendar2 />
      {dayjs(from).format('DD[ - ]')}
      {dayjs(to).format('DD MMMM YYYY')}
    </Flex>
    <IconButton icon={IconArrowRight} onClick={() => onNextDate?.(range)} />
  </>
);

const CalendarMonthPicker = ({ from, range, onPrevDate, onNextDate }: Picker) => (
  <>
    <IconButton icon={IconArrowLeft} onClick={() => onPrevDate?.(range)} />
    <Flex alignItems="center" className={styles.pickerInner}>
      <IconCalendar2 />
      {dayjs(from).format('MMMM YYYY')}
    </Flex>
    <IconButton icon={IconArrowRight} onClick={() => onNextDate?.(range)} />
  </>
);
