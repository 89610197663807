export enum FormFields {
  AccountingDate = 'accountingDate',
  Amount = 'amount',
  Comment = 'comment',
  From = 'from',
  To = 'to',
  PaymentType = 'paymentType',
}

export const initialValues = {
  [FormFields.AccountingDate]: null,
  [FormFields.Amount]: null,
  [FormFields.From]: null,
  [FormFields.To]: null,
  [FormFields.PaymentType]: 'receipt',
};
