import { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { JobCategoriesProps } from './types';

import IconLoading from '@/components/Icons/IconLoading';
import Button from '@/components/UI/Button';
import { EVENTS } from '@/constants';
import { CategoryType } from '@/features/Job/components/JobCategories/constants';
import { getJobCategories, getJobsByCategory } from '@/features/Job/services';
import type { JobCategoryEntity } from '@/features/Job/types';
import { useEventListener, useOnce } from '@/hooks';

const JobCategories = ({ height, type = CategoryType.Link }: JobCategoriesProps) => {
  const [category, setCategory] = useState<string>();
  const [categories, setCategories] = useState<JobCategoryEntity[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [params] = useSearchParams();

  const fetchData = () => {
    getJobCategories()
      .then((data) => {
        setCategories(data.items);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useOnce(fetchData);
  useEventListener(EVENTS.REFRESH_DATA, fetchData);

  useEffect(() => {
    if (category) {
      getJobsByCategory(category)
        .then((data) => {
          setCategories(data.items);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [category]);

  if (isFetching) {
    return <IconLoading />;
  }

  const renderMenu = () =>
    categories.map(({ title, _id }, index) => (
      <li className={styles.navItem} key={index}>
        {type === CategoryType.Button ? (
          <Button onClick={() => setCategory(_id)} className={styles.navButton} variant="link">
            {title}
          </Button>
        ) : (
          <NavLink
            className={cn(styles.navLink, params?.get('category') === _id && styles.navLinkActive)}
            end
            to={`/jobs?category=${_id}`}
          >
            {title}
          </NavLink>
        )}
      </li>
    ));

  return (
    <nav className={styles.nav}>
      <Scrollbar
        style={{ height: height ?? '50%' }}
        scrollerProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <span {...restProps} ref={elementRef} />;
          },
        }}
      >
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <NavLink className={cn(styles.navLink, !params?.get('category') && styles.navLinkActive)} end to="/jobs">
              Все работы
            </NavLink>
          </li>
          {renderMenu()}
        </ul>
      </Scrollbar>
    </nav>
  );
};

export default JobCategories;
