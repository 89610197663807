export const ORDER_TABLE_COLUMNS = 'orderTableColumns';

export const columns = [
  { name: 'increment', value: 'Номер' },
  { name: 'contact', value: 'Контакт' },
  { name: 'sum', value: 'Сумма в сделке' },
  { name: 'status', value: 'Статус оплаты' },
  { name: 'responsible', value: 'Ответственный' },
  { name: 'lastOrderDate', value: 'Дата последней сделки' },
  { name: 'firstOrderDate', value: 'Дата первого закрытия сделки' },
  { name: 'subdivision', value: 'Подразделение' },
  { name: 'mileage', value: 'Пробег' },
];
