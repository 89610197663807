import type { GetOrdersKanbanResponse, OrderEntity, OrderJobEntity, OrderStatusEnum } from '@/features/Order/types';
import { normalizeJobs } from '@/features/Order/utils';

export const getOrderByIdSerialize = (data: OrderEntity) => ({
  ...data,
  jobs: normalizeJobs(data.jobs as OrderJobEntity[]),
});

export const getOrderKanbanSerialize = (data: GetOrdersKanbanResponse[]) => {
  const result = {} as Record<OrderStatusEnum, OrderEntity[]>;

  data.forEach((item) => {
    const status = item.status;

    if (!result[status]) {
      result[status] = [];
    }

    result[status].push(...item.data.items);
  });

  return result;
};
