const IconStock = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99979 11.0833C9.89146 11.0833 9.78312 11.0583 9.68312 11L2.3248 6.74169C2.0248 6.56669 1.92478 6.18333 2.09978 5.88333C2.27478 5.58333 2.64978 5.48331 2.95811 5.65831L9.99979 9.73332L16.9998 5.68334C17.2998 5.50834 17.6831 5.61669 17.8581 5.90835C18.0331 6.20835 17.9248 6.59166 17.6331 6.76666L10.3248 11C10.2165 11.05 10.1081 11.0833 9.99979 11.0833Z"
      fill="#D4D4DE"
    />
    <path
      d="M10 18.6334C9.65833 18.6334 9.375 18.35 9.375 18.0084V10.45C9.375 10.1084 9.65833 9.82504 10 9.82504C10.3417 9.82504 10.625 10.1084 10.625 10.45V18.0084C10.625 18.35 10.3417 18.6334 10 18.6334Z"
      fill="#D4D4DE"
    />
    <path
      d="M10.0005 18.9583C9.26718 18.9583 8.54217 18.8 7.96717 18.4833L3.51718 16.0083C2.30884 15.3417 1.36719 13.7333 1.36719 12.35V7.64164C1.36719 6.2583 2.30884 4.65834 3.51718 3.98334L7.96717 1.51666C9.10884 0.883323 10.8922 0.883323 12.0338 1.51666L16.4838 3.99163C17.6922 4.6583 18.6338 6.26664 18.6338 7.64998V12.3583C18.6338 13.7417 17.6922 15.3416 16.4838 16.0166L12.0338 18.4833C11.4588 18.8 10.7338 18.9583 10.0005 18.9583ZM10.0005 2.29165C9.47551 2.29165 8.95885 2.39999 8.57552 2.60832L4.12552 5.0833C3.32552 5.5333 2.61719 6.72498 2.61719 7.64998V12.3583C2.61719 13.275 3.32552 14.475 4.12552 14.925L8.57552 17.4C9.33385 17.825 10.6672 17.825 11.4255 17.4L15.8755 14.925C16.6755 14.475 17.3838 13.2833 17.3838 12.3583V7.64998C17.3838 6.73331 16.6755 5.5333 15.8755 5.0833L11.4255 2.60832C11.0422 2.39999 10.5255 2.29165 10.0005 2.29165Z"
      fill="#D4D4DE"
    />
    <path
      d="M14.167 11.6584C13.8253 11.6584 13.542 11.375 13.542 11.0334V8.35005L5.94198 3.96671C5.64198 3.79171 5.54197 3.40836 5.71697 3.1167C5.89197 2.8167 6.26698 2.71668 6.56698 2.89168L14.4753 7.45838C14.667 7.56671 14.792 7.77502 14.792 8.00002V11.05C14.792 11.375 14.5086 11.6584 14.167 11.6584Z"
      fill="#D4D4DE"
    />
  </svg>
);

export default IconStock;
