import { useCallback, useEffect, useState } from 'react';

import type { PaymentTerminalEntity } from '../../types';

import { If } from '@/components/ConditionalRendering/If';
import Pagination from '@/components/UI/Pagination';
import Switch from '@/components/UI/Switch';
import Table from '@/components/UI/Table';
import { EVENTS } from '@/constants';
import { balanceTypes } from '@/features/Balance/constants';
import { getPaymentTerminals } from '@/features/PaymentTerminal/service';
import { useOnce, useTable } from '@/hooks';
import { useAppProvider } from '@/providers/AppProvider/hooks';

const PaymentTerminalTableList = () => {
  const { currentSubdivision } = useAppProvider();
  const [isFetching, setIsFetching] = useState(true);
  const [paymentTerminals, setPaymentTerminals] = useState<PaymentTerminalEntity[]>([]);

  const { pageNumber, pageSize, setTotalPages, setTotalItems, totalPages, totalItems, handlePageChange } = useTable();

  const handlFetchData = useCallback(
    () =>
      getPaymentTerminals({
        pageNumber,
        pageSize,
      })
        .then((data) => {
          setPaymentTerminals(data.items);
          setTotalPages(data.pagination.totalPages || 1);
          setTotalItems(data.pagination.totalItems || 0);
        })
        .finally(() => {
          setIsFetching(false);
        }),
    [pageNumber, pageSize],
  );

  useEffect(() => {
    setIsFetching(true);
    handlFetchData();
  }, [pageNumber, pageSize]);

  useOnce(() => {
    const refreshData = () => {
      setIsFetching(true);
      handlFetchData();
    };

    document.addEventListener(EVENTS.REFRESH_DATA, refreshData);

    return () => {
      document.removeEventListener(EVENTS.REFRESH_DATA, refreshData);
    };
  });

  return (
    <>
      <Table
        header={
          <Table.Row>
            <Table.Cell>Наименование</Table.Cell>
            <Table.Cell>Подразделение</Table.Cell>
            <Table.Cell>Тип баланса</Table.Cell>
            <Table.Cell>Юр. лицо орагнизации</Table.Cell>
            <Table.Cell>Статус баланса</Table.Cell>
          </Table.Row>
        }
        isFetching={isFetching}
        isEmpty={!paymentTerminals.length}
      >
        {paymentTerminals.map((paymentTerminal) => (
          <Table.Row key={paymentTerminal._id}>
            <Table.Cell>{paymentTerminal.title}</Table.Cell>
            <Table.Cell>{currentSubdivision?.title}</Table.Cell>
            <Table.Cell>{balanceTypes[paymentTerminal.wallet?.balanceType]}</Table.Cell>
            <Table.Cell>{paymentTerminal.legalEntityName}</Table.Cell>
            <Table.Cell>
              <Switch disabled checked={paymentTerminal.wallet?.isActive} value="Активный баланс" />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>
      <If condition={Boolean(paymentTerminals.length)}>
        <Pagination
          activePage={pageNumber}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>
    </>
  );
};

export default PaymentTerminalTableList;
