import { PatternFormat } from 'react-number-format';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { InputPhoneProps } from './types';

import Input from '@/components/UI/Input';

const InputPhone = ({ error, label, name, onChange, placeholder, value }: InputPhoneProps) => (
  <div className={styles.container}>
    {Boolean(label) && label}
    <PatternFormat
      name={name}
      className={cn(styles.input, error && styles.invalid)}
      placeholder={placeholder}
      onChange={onChange}
      customInput={Input}
      format="+#(###)###-##-##"
      inputMode="tel"
      value={value}
    />
    {Boolean(error) && <p className={styles.error}>{error}</p>}
  </div>
);

export default InputPhone;
