import { useEffect, useState } from 'react';

import type { OrderCalendarFormProps } from './types';

import IconAdd from '@/components/Icons/IconAdd';
import IconLoading from '@/components/Icons/IconLoading';
import Button from '@/components/UI/Button';
import { CalendarFormModal } from '@/features/Calendar';
import { fetchCalendarEntryByOrder } from '@/features/Calendar/services';
import type { CalendarEntity } from '@/features/Calendar/types';
import { getBaseDate } from '@/utils/date';

export const OrderCalendarForm = ({ order }: OrderCalendarFormProps) => {
  const [calendarEntity, setCalendarEntity] = useState<CalendarEntity>();
  const [showModal, setShowModal] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (order?._id) {
      fetchCalendarEntryByOrder(order?._id)
        .then((res) => {
          setCalendarEntity(res);
        })
        .catch(() => {})
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [order?._id]);

  if (isFetching) {
    return <IconLoading />;
  }

  return (
    <>
      {calendarEntity ? (
        <span>{getBaseDate(calendarEntity?.startAt)}</span>
      ) : (
        <>
          <Button
            disabled={!order?.client}
            onClick={() => setShowModal(true)}
            endIcon={IconAdd}
            variant="outline-primary"
          >
            Добавить запись в календарь
          </Button>
          {showModal && <CalendarFormModal order={order} onClose={() => setShowModal(false)} isOpen={showModal} />}
        </>
      )}
    </>
  );
};
