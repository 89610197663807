import { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';

import { isEmpty } from 'lodash';

import type { OrderEntity } from '../../types';

import { columns, ORDER_TABLE_COLUMNS } from './constants';

import { If } from '@/components/ConditionalRendering/If';
import Pagination from '@/components/UI/Pagination';
import Table from '@/components/UI/Table';
import { EVENTS } from '@/constants';
import { getOrders } from '@/features/Order/services';
import { useEventListener, useTable } from '@/hooks';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import type { IndividualAgent } from '@/types';
import { getBaseDate } from '@/utils/date';
import { getStorage, setStorage } from '@/utils/storage';

const OrderTableList = () => {
  const [items, setItems] = useState<OrderEntity[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [tableColumns, setTableColumns] = useState<{ name: string; value: string }[]>([]);
  const {
    pageNumber,
    pageSize,
    setTotalPages,
    setTotalItems,
    totalPages,
    totalItems,
    sortField,
    sortValue,
    handlePageChange,
  } = useTable();

  const fetchItems = () => {
    setIsFetching(true);

    getOrders({ pageNumber, pageSize, sortField, sortValue })
      .then((data) => {
        setItems(data.items);
        setTotalPages(data.pagination.totalPages || 1);
        setTotalItems(data.pagination.totalItems || 0);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const updateColumnsFromStorage = () => {
    const storageColumns = getStorage(ORDER_TABLE_COLUMNS, columns);

    setStorage(ORDER_TABLE_COLUMNS, storageColumns);
    setTableColumns(storageColumns);
  };

  useLayoutEffect(() => {
    updateColumnsFromStorage();
  }, []);

  useEffect(() => {
    fetchItems();
  }, [pageNumber, pageSize, sortField, sortValue]);

  useEventListener(EVENTS.UPDATE_COLUMNS_FROM_STORAGE, updateColumnsFromStorage);

  return (
    <>
      <Scrollbar>
        <Table
          header={
            <Table.Row sortable>
              {tableColumns.map((tableColumn, index) => (
                <Table.Cell
                  key={tableColumn.name}
                  index={index}
                  isLastCell={index === tableColumns.length - 1}
                  name={tableColumn.name}
                >
                  {tableColumn.value}
                </Table.Cell>
              ))}
            </Table.Row>
          }
          isEmpty={isEmpty(items)}
          isFetching={isFetching}
        >
          {items.map((item) => (
            <Table.Row key={item._id}>
              <Table.Cell>
                <Link to={`/orders/${item._id}`}>{item.increment}</Link>
              </Table.Cell>
              <Table.Cell>
                <If condition={Boolean(item.client?.counterpartProfile)}>
                  <Link to={`/agents/${item.client?.counterpartProfile?._id}/${item.client?.counterpartType}`}>
                    {getAgentFullName(item.client?.counterpartProfile as IndividualAgent)}
                  </Link>
                </If>
              </Table.Cell>
              <Table.Cell>{item.totalPrice} ₽</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <If condition={Boolean(item.responsible?.counterpartProfile)}>
                  <Link
                    to={`/agents/${item.responsible?.counterpartProfile?._id}/${item.responsible?.counterpartType}`}
                  >
                    {getAgentFullName(item.responsible?.counterpartProfile)}
                  </Link>
                </If>
              </Table.Cell>
              <Table.Cell>{getBaseDate(item.closedAt)}</Table.Cell>
              <Table.Cell>{getBaseDate(item.closedAt)}</Table.Cell>
              <Table.Cell>{item.section?.title}</Table.Cell>
              <Table.Cell>{item.car?.mileage}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Scrollbar>
      <If condition={Boolean(items.length)}>
        <Pagination
          activePage={pageNumber}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>
    </>
  );
};

export default OrderTableList;
