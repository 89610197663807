import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { ModalFooterProps } from './types';

const ModalFooter = ({ children }: PropsWithChildren<ModalFooterProps>) => (
  <div className={cn(styles.modalFooter)}>{children}</div>
);

export default ModalFooter;
