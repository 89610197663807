import * as Yup from 'yup';

import { FormFields } from '@/features/Agent/components/AgentForm/constants';
import { agentSocialMediaFormSchema } from '@/features/Agent/components/AgentSocialMediaForm/schema';
import type { IndividualAgent } from '@/types';
import { CounterpartRoleEnum } from '@/types';
import * as expect from '@/utils/validationExpect';
import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const agentFormSchema: Yup.ObjectSchema<IndividualAgent> = Yup.object().shape({
  [FormFields.Email]: Yup.string().email().required(messages.required),
  [FormFields.Role]: Yup.string().oneOf(Object.values(CounterpartRoleEnum)).required(messages.required),
  [FormFields.FirstName]: Yup.string().trim().required(messages.required),
  [FormFields.LastName]: Yup.string().trim().required(messages.required),
  [FormFields.BirthDate]: Yup.date().required(messages.required),
  [FormFields.Website]: Yup.string().url(messages.validUrl).optional(),
  [FormFields.Sex]: Yup.string().required(messages.required),
  [FormFields.Phone]: Yup.string().test('phone', messages.validNumber, expect.phoneNumber).required(messages.required),
  // postCode: Yup.string().required(messages.required),
  [FormFields.SubscribeForNews]: Yup.boolean().required(messages.required),
  [FormFields.SocialMedia]: Yup.array()
    .of(agentSocialMediaFormSchema)
    .min(1, messages.required)
    .required(messages.required),
  // [FormFields.City]: Yup.string().trim().required(messages.required),
  [FormFields.Discount]: Yup.number().min(0, messages.minValueZero).max(100, messages.maxValueOneHundred),
  [FormFields.IsEmployee]: Yup.boolean(),
});

// @ts-ignore
export const representativeFormSchema: Yup.ObjectSchema<IndividualAgent> = Yup.object().shape({
  [FormFields.FirstName]: Yup.string().trim().required(messages.required),
  [FormFields.LastName]: Yup.string().trim().required(messages.required),
  [FormFields.BirthDate]: Yup.date().required(messages.required),
  [FormFields.Website]: Yup.string().url(messages.validUrl).optional(),
  [FormFields.Sex]: Yup.string().required(messages.required),
  [FormFields.Phone]: Yup.string().test('phone', messages.validNumber, expect.phoneNumber).required(messages.required),
});
