const IconClock = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0013 18.4584C5.33578 18.4584 1.54297 14.6656 1.54297 10.0001C1.54297 5.33456 5.33578 1.54175 10.0013 1.54175C14.6668 1.54175 18.4596 5.33456 18.4596 10.0001C18.4596 14.6656 14.6668 18.4584 10.0013 18.4584ZM10.0013 1.79175C5.47516 1.79175 1.79297 5.47394 1.79297 10.0001C1.79297 14.5262 5.47516 18.2084 10.0013 18.2084C14.5274 18.2084 18.2096 14.5262 18.2096 10.0001C18.2096 5.47394 14.5274 1.79175 10.0013 1.79175Z"
      fill="#7C7A85"
      stroke="#7C7A85"
    />
    <path
      d="M13.0909 13.275C12.9826 13.275 12.8742 13.25 12.7742 13.1833L10.1909 11.6416C9.54922 11.2583 9.07422 10.4166 9.07422 9.67497V6.2583C9.07422 5.91663 9.35755 5.6333 9.69922 5.6333C10.0409 5.6333 10.3242 5.91663 10.3242 6.2583V9.67497C10.3242 9.97497 10.5742 10.4166 10.8326 10.5666L13.4159 12.1083C13.7159 12.2833 13.8076 12.6666 13.6326 12.9666C13.5076 13.1666 13.2992 13.275 13.0909 13.275Z"
      fill="#7C7A85"
    />
  </svg>
);

export default IconClock;
