import cx from 'classnames';

import styles from './styles.module.scss';
import type { RadioProps } from './types';

const Radio = ({ boxAlign = 'start', className = '', label, ...props }: RadioProps) => (
  <label className={cx(styles.radio, className)}>
    <input {...props} type="radio" className={styles.radioHandle} />
    <span className={cx(styles.radioBox, styles[`radioBox-align-${boxAlign}`])} />
    {label}
  </label>
);

export default Radio;
