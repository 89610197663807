import { useMemo } from 'react';

import cn from 'classnames';
import { map } from 'lodash';

import { CalendarRange, getRangeValues } from './constants';
import styles from './styles.module.scss';
import type { CalendarRangeProps } from './types';

import Flex from '@/components/UI/Flex';
import dayjs from '@/utils/dayjs';

export const CalendarRangeCells = ({ from, to, range }: CalendarRangeProps) => {
  const ranges = useMemo(() => getRangeValues(range, dayjs(from), dayjs(to)), [range, from]);

  const renderCells = useMemo(
    () =>
      map(ranges.values, (value, index) => (
        <div className={cn(styles.td)} key={index}>
          {value.format(ranges.format)}
        </div>
      )),
    [range],
  );

  return (
    <Flex className={styles.row} columnGap="zero" rowGap="zero">
      <div className={cn(styles.td, range === CalendarRange.Month ? styles.tdMonth : styles.tdFirst)}>
        {ranges.label}
      </div>
      {renderCells}
    </Flex>
  );
};
