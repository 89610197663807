import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { FlexProps } from './types';

const Flex = ({
  children,
  className,
  columnGap = 'md',
  rowGap = 'md',
  fullWidth = false,
  ...styleProps
}: PropsWithChildren<FlexProps>) => (
  <div
    style={styleProps}
    className={cn(
      styles.flex,
      fullWidth && styles.fullWidth,
      styles[`column-gap-${columnGap}`],
      styles[`row-gap-${rowGap}`],
      className,
    )}
  >
    {children}
  </div>
);

export default Flex;
