import { isEmpty } from 'lodash';

import styles from './styles.module.scss';
import type { OrderNotesProps } from './types';

const OrderNotes = ({ notes }: OrderNotesProps) => {
  const renderNotes = () =>
    notes.map((note) => (
      <div className={styles.note} key={note._id}>
        <p className={styles.noteText}>{note.text}</p>
      </div>
    ));

  if (isEmpty(notes)) {
    return <div className={styles.empty}>Заметок нет</div>;
  }

  return <div className={styles.notes}>{renderNotes()}</div>;
};

export default OrderNotes;
