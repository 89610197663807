export const columns = [
  {
    name: 'subdivision',
    title: 'Подразделение',
  },
  {
    name: 'status',
    title: 'Статус',
  },
  {
    name: 'createdAt',
    title: 'Дата создания',
  },
  {
    name: 'warehouse',
    title: 'Менеджер',
  },
  {
    name: 'amount',
    title: 'Кол-во позиций',
  },
  {
    name: 'responsible',
    title: 'Ответственный менеджер',
    params: {
      width: 120,
    },
  },
];
