import api from '@/api';

export const addCar = async (car: any) => {
  const response = await api.post('/car', car);
  return response.data;
};

export const getCar = async (id: string) => {
  const response = await api.get(`/car/${id}`);
  return response.data;
};

export const getCarTypes = async () => {
  const response = await api.get('/car-autocomplete/types');
  return response.data;
};

export const searchCarsByBrand = async (query = '', type = '000000000000000000000001') => {
  const response = await api.get(`/car-autocomplete/brands?query=${query}&type=${type}`);
  return response.data;
};

export const getCarGenerationsByModel = async (model: string) => {
  const response = await api.get(`/car-autocomplete/generations?model=${model}`);
  return response.data;
};

export const searchCarsByModel = async (brand: string) => {
  const response = await api.get(`/car-autocomplete/models?brand=${brand}`);
  return response.data;
};

export const getCarModificationsByGeneration = async (series: string) => {
  const response = await api.get(`/car-autocomplete/modifications?series=${series}`);
  return response.data;
};

export const getCarSeriesByGeneration = async (generation: string) => {
  const response = await api.get(`/car-autocomplete/series?generation=${generation}`);
  return response.data;
};

export const decodeVin = async (vin: string) => {
  const response = await api.get(`/car/decode-vin?vin=${vin}`);
  return response.data;
};

export const fetchCounterpartAccount = async () => {
  const response = await api.get('/counterpart');
  return response.data;
};

export const fetchCarServiceBay = async () => {
  const response = await api.get('/car-service-bay');
  return response.data;
};

export const fetchCreateCarServiceBay = async (body: any) => {
  const response = await api.post('/car-service-bay', body);
  return response.data;
};
