import api from '@/api';
import type { ProductEntity } from '@/features/Product/types';

export const createProduct = async (body: ProductEntity) => {
  const response = await api.post('/product', body);
  return response.data;
};

export const fetchSearchProducts = async (query: string) => {
  const response = await api.get(`/product?query=${query}&limit=1000`);
  return response.data;
};

export const getProducts = async (body: any) => {
  const response = await api.get('/product', body);
  return response.data;
};
