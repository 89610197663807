import { AgentDetailTabs } from './types';

export const tabs = [
  {
    label: 'Основная информация',
    value: AgentDetailTabs.Main,
  },
  {
    label: 'Сделки',
    value: AgentDetailTabs.Deals,
  },
  {
    label: 'Заметки и задачи',
    value: AgentDetailTabs.Notes,
  },
  {
    label: 'Взаиморасчеты',
    value: AgentDetailTabs.Settlements,
  },
  {
    label: 'Организации',
    value: AgentDetailTabs.Organizations,
  },
  {
    label: 'Банковские счета',
    value: AgentDetailTabs.BankAccounts,
  },
  {
    label: 'Звонки',
    value: AgentDetailTabs.Calls,
  },
  {
    label: 'Календарь',
    value: AgentDetailTabs.Calendar,
  },
];
