import React, { useState } from 'react';

import { map } from 'lodash';

import { SettingsTab, settingsTabs } from '../../constants';
import { SettingsCoefficient } from '../SettingsCoefficient';
import { SettingsIPAddresses } from '../SettingsIPAddresses';
import { SettingsMarkupForMaterial } from '../SettingsMarkupForMaterial';
import { SettingsMarkupForWork } from '../SettingsMarkupForWork';
import { SettingsServices } from '../SettingsServices';
import { SettingsSubdivision } from '../SettingsSubdivision';

import styles from './styles.module.scss';

import Header from '@/components/Header';
import Tabs from '@/components/UI/Tabs';

const SettingsView = () => {
  const [settingsTab, setSettingsTab] = useState<SettingsTab>(SettingsTab.Services);

  return (
    <div>
      <Header title="Основные" showSearch={false} />
      <Tabs className={styles.tabs} value={settingsTab} onSelect={setSettingsTab}>
        {map(settingsTabs, ({ label, value }) => (
          <Tabs.Item key={value} value={value}>
            {label}
          </Tabs.Item>
        ))}
      </Tabs>

      {settingsTab === SettingsTab.Subdivision && <SettingsSubdivision />}
      {settingsTab === SettingsTab.Services && <SettingsServices />}
      {settingsTab === SettingsTab.IPAddresses && <SettingsIPAddresses />}
      {settingsTab === SettingsTab.Coefficient && <SettingsCoefficient />}
      {settingsTab === SettingsTab.MarkupForWork && <SettingsMarkupForWork />}
      {settingsTab === SettingsTab.MarkupForMaterial && <SettingsMarkupForMaterial />}
    </div>
  );
};

export default SettingsView;
