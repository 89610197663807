import type { PropsWithChildren } from 'react';

import { isFunction } from 'lodash';

import styles from './styles.module.scss';
import type { ModalHeaderProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconClose from '@/components/Icons/IconClose';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';

const ModalHeader = ({ children, onClose }: PropsWithChildren<ModalHeaderProps>) => (
  <div className={styles.modalHeader}>
    <Flex>
      {children}
      <If condition={isFunction(onClose)}>
        <IconButton
          icon={IconClose}
          label="Закрыть модальное окно"
          onClick={onClose!}
          size="sm"
          className={styles.closeBtn}
          variant="secondary"
        />
      </If>
    </Flex>
  </div>
);

export default ModalHeader;
