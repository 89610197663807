const IconPlay = ({ size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.55898 17.7333C5.90065 17.7333 5.27565 17.575 4.72565 17.2583C3.42565 16.5083 2.70898 14.9833 2.70898 12.975V7.03333C2.70898 5.01667 3.42565 3.5 4.72565 2.75C6.02565 2 7.70065 2.14167 9.45065 3.15L14.5923 6.11667C16.334 7.125 17.3007 8.50833 17.3007 10.0083C17.3007 11.5083 16.3423 12.8917 14.5923 13.9L9.45065 16.8667C8.44232 17.4417 7.45898 17.7333 6.55898 17.7333ZM6.55898 3.51667C6.10898 3.51667 5.70898 3.61667 5.35065 3.825C4.45065 4.34167 3.95898 5.48333 3.95898 7.03333V12.9667C3.95898 14.5167 4.45065 15.65 5.35065 16.175C6.25065 16.7 7.48398 16.55 8.82565 15.775L13.9673 12.8083C15.309 12.0333 16.0507 11.0417 16.0507 10C16.0507 8.95833 15.309 7.96667 13.9673 7.19167L8.82565 4.225C8.00898 3.75833 7.24232 3.51667 6.55898 3.51667Z"
      fill="white"
    />
  </svg>
);

export default IconPlay;
