import api from '@/api';
import type { GetSubdivisionsResponse, GetSubdivisionsService } from '@/features/Subdivision/types';

export const createSubdivision = async (body: any) => {
  const response = await api.post('/car-service-subdivision', body);
  return response.data;
};

export const getSubdivisions: GetSubdivisionsService = async () => {
  const response = await api.get<GetSubdivisionsResponse>('/car-service-subdivision');
  return response.data;
};
