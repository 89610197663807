import type { CarServiceSectionEntity } from '@/features/Order/types';
import type { ProductEntity } from '@/features/Product/types';
import type { CarServiceSubdivisionEntity, CounterpartAccountEntity } from '@/types';

export interface WarehouseArrivalEntity {
  __v: string;
  _id: string;
  increment: number;
  createdAt: string;
  updatedAt: string;
  supplier: CounterpartAccountEntity | string;
  responsible: CounterpartAccountEntity | string;
  subdivision?: CarServiceSubdivisionEntity | string;
  status?: WarehouseArrivalStatusEnum;
  documentType: WarehouseArrivalDocumentTypeEnum | string;
  warehouse: WarehouseEntity | string;
  accountingDate?: string;
  products?: WarehouseArrivalProduct[] | number;
}

export interface WarehouseArrivalProduct {
  product: ProductEntity;
  amount: number;
  buyingPrice: number;
}

export enum WarehouseAddressTypeEnum {
  PackingDesk = 'packing-desk',
  ReceivingDesk = 'receiving-desk',
  Wagon = 'wagon',
  Shelf = 'shelf',
  Cell = 'cell',
}

export interface WarehouseAddressEntity {
  _id?: string;
  title: string;
  type: WarehouseAddressTypeEnum;
  floors: number;
  rowsPerFloor: number;
  racksPerRow: number;
  shelvesPerRack: number;
  cellsPerShelf: number;
  warehouse: WarehouseEntity;
}

export interface WarehouseEntity {
  _id?: string;
  title: string;
  address: string;
  section?: CarServiceSectionEntity;
  warehouseAddresses?: WarehouseAddressEntity[];
  isActive?: boolean;
  supplier?: CounterpartAccountEntity;
}

export enum WarehouseArrivalStatusEnum {
  Created = 'created',
  InProgress = 'in-progress',
  Completed = 'completed',
  Canceled = 'canceled',
}

export enum WarehouseArrivalDocumentTypeEnum {
  UniversalTransferDocument = 'UTD',
  GoodsReceiptNote = 'goods-receipt-note',
  Invoice = 'invoice',
  InventoryCheck = 'inventory-check',
  Receipt = 'receipt',
}
