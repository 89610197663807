import type { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import type { PrivateRouteProps } from './types';

import { PATHS } from '@/constants';
import AppLayout from '@/layouts/AppLayout';
import { useAppSelector } from '@/store';
import { selectIsAuthenticated } from '@/store/slices/userSlice';

const PrivateRoute = ({ children }: PropsWithChildren<PrivateRouteProps>) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    return <AppLayout>{children}</AppLayout>;
  }

  return <Navigate to={PATHS.AUTH} />;
};

export default PrivateRoute;
