import styles from './styles.module.scss';

import IconAvatar from '@/components/Icons/IconAvatar';
import Table from '@/components/UI/Table';

const WorkScheduleList = () => (
  <div>
    <Table className={styles.table}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
        <Table.Row key={i}>
          <Table.Cell className={styles.tableInfo}>
            <IconAvatar width={42} height={42} />
            <div>
              <h2 className={styles.tableUser}>Скилков С.А.</h2>
              <p className={styles.tableUserJob}>Мастер цеха/Механик</p>
            </div>
          </Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
          <Table.Cell>11</Table.Cell>
        </Table.Row>
      ))}
    </Table>
  </div>
);

export default WorkScheduleList;
