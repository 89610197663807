import { useState } from 'react';

import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';

import styles from './styles.module.scss';
import type { CarEntity } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconAdd from '@/components/Icons/IconAdd';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import { CarDetail } from '@/features/Car/components/CarDetail';
import CarForm from '@/features/Car/components/CarForm';
import type { IndividualAgent, IterableEntity } from '@/types';

const Cars = () => {
  const { values, setValues } = useFormikContext<IndividualAgent>();
  const [cars, setCars] = useState<IterableEntity[]>([]);

  const handleRemoveCar = (_id: string) => {
    setCars((prev) => prev.filter((item) => item._id !== _id));
  };

  const handleAddCar = () => {
    setCars((prev) =>
      prev.concat({
        _id: uuid(),
      }),
    );
  };

  const handleSubmit = (car: CarEntity) => {
    setValues({
      ...values,
      cars: (values?.cars || []).concat(car),
    });
  };

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <h2 className={styles.formTitle}>Автомобили</h2>
        <Button endIcon={IconAdd} onClick={handleAddCar} variant="empty-primary">
          Автомобиль
        </Button>
      </Flex>

      <If condition={Boolean(values?.cars?.length)}>
        <Flex className={styles.representative} flexDirection="column" rowGap="sm">
          {values?.cars?.map((car) => <CarDetail key={car._id} car={car} />)}
        </Flex>
      </If>

      <If condition={Boolean(cars.length)}>
        <Flex flexDirection="column" rowGap="xs">
          {cars.map((car) => (
            <CarForm key={car._id} id={car._id} onSuccess={handleSubmit} onRemove={handleRemoveCar} />
          ))}
        </Flex>
      </If>
    </>
  );
};

export default Cars;
