import { useEffect, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router';
import { Scrollbar } from 'react-scrollbars-custom';

import cn from 'classnames';
import parsePhoneNumber from 'libphonenumber-js';

import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import LoadingIndicatorPage from '@/components/LoadingIndicatorPage';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import { orderStatuses } from '@/features/Order/constants';
import { changeStatus, getOrdersKanban, updateOrder } from '@/features/Order/services';
import type { OrderEntity, OrderStatusEnum } from '@/features/Order/types';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import { getBaseDate } from '@/utils/date';

const OrderBoard = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [board, setBoard] = useState<Record<OrderStatusEnum, OrderEntity[]>>({
    appointment: [],
    'call-back': [],
    created: [],
    consultation: [],
    declined: [],
    junk: [],
    negotiations: [],
    ready: [],
    refund: [],
    success: [],
    working: [],
  });

  const fetchItems = () => {
    setIsFetching(true);

    getOrdersKanban([
      {
        status: 'created',
        page: 1,
        pageSize: 10,
      },
    ])
      .then((data) => {
        setBoard(data);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const onDragEnd = async ({ draggableId, destination, source }: DropResult) => {
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const [start] = board[source.droppableId as OrderStatusEnum].splice(source.index, 1);

    const end = board[destination.droppableId as OrderStatusEnum]?.[destination.index]?._id;

    board[destination.droppableId as OrderStatusEnum].splice(destination.index, 0, start);

    setBoard(board);

    const requests = [];

    requests.push(
      updateOrder(start._id, {
        sort: destination.index,
      }),
      changeStatus(draggableId, {
        newStatus: destination.droppableId,
      }),
    );

    if (end) {
      requests.push(
        updateOrder(end, {
          sort: source.index,
        }),
      );
    }

    await Promise.all(requests);
  };

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <Scrollbar>
      <DragDropContext onDragEnd={onDragEnd}>
        <Flex className={styles.wrapper}>
          {Object.entries(board).map(([status, items]) => (
            <Droppable key={status} droppableId={status} type="group">
              {(provided, snapshot) => (
                <div
                  key={status}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={cn(styles.column, snapshot.isDraggingOver && styles.columnDrag)}
                >
                  <Flex flexDirection="column" rowGap="zero">
                    <div className={styles.columnTop}>
                      <Flex alignItems="center" columnGap="sm">
                        <h2 className={styles.columnTitle}>{orderStatuses[status as OrderStatusEnum]}</h2>
                        <span className={styles.columnCounter}>{items.length}</span>
                      </Flex>
                    </div>

                    {items.map((item, i) => (
                      <Draggable draggableId={item._id} index={i} key={item._id}>
                        {(provided, snapshot) => (
                          <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                            <Item isDragging={snapshot.isDragging} item={item} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </Flex>
                </div>
              )}
            </Droppable>
          ))}
        </Flex>
      </DragDropContext>
    </Scrollbar>
  );
};

// @ts-ignore
export function Item(props) {
  const { item, isDragging } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/orders/${item._id}`);
  };

  return (
    <div className={cn(styles.item, isDragging && styles.itemDrag)} role="button" onClick={handleClick}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" columnGap="xs">
          <span className="text-primary">#{item.increment}</span>
          <span className="text-secondary">{getBaseDate(item.createdAt)}</span>
        </Flex>
        {/* <IconMore />*/}
      </Flex>
      <If condition={Boolean(item.client?.counterpartProfile)}>
        <Grid gap="xxs">
          <Grid.Item col={6}>
            <span className={styles.client}>{getAgentFullName(item.client?.counterpartProfile, false)}</span>
          </Grid.Item>
          <Grid.Item col={6}>
            <span className="text-secondary">
              {parsePhoneNumber(item.client?.counterpartProfile?.phone || '')?.formatInternational()}
            </span>
          </Grid.Item>
          <Grid.Item col={6}>
            <span className="text-secondary">{getBaseDate(item.client?.counterpartProfile?.birthDate)}</span>
          </Grid.Item>
        </Grid>
      </If>
      <Flex alignItems="center" justifyContent="space-between">
        <If condition={Boolean(item.responsible?.counterpartProfile)}>
          <span className={styles.responsible}>{getAgentFullName(item.responsible?.counterpartProfile, false)}</span>
        </If>
        <span className={styles.price}>{item.totalPrice} ₽</span>
      </Flex>
    </div>
  );
}

export default OrderBoard;
