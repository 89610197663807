import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import IconAdd from '@/components/Icons/IconAdd';
import IconTrash from '@/components/Icons/IconTrash';
import LoadingIndicatorPage from '@/components/LoadingIndicatorPage';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Table from '@/components/UI/Table';
import { EVENTS } from '@/constants';
import type { BrandEntity } from '@/features/Brand/types';
import { CountryForm } from '@/features/Country/components/CountryForm';
import { fetchGetCountries, fetchRemoveCountry } from '@/features/Country/services';
import type { CountriesEntity, CountryEntity } from '@/features/Country/types';
import { useEventListener, useTable } from '@/hooks';
import { triggerEvent } from '@/utils/event';

export const CountryTableList = () => {
  const [countries, setCountries] = useState<CountriesEntity>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modal, setModal] = useState<{
    country?: CountryEntity | null;
    show: boolean;
  }>({
    country: null,
    show: false,
  });

  const [removeModal, setRemoveModal] = useState<{
    country?: BrandEntity | null;
    show: boolean;
  }>({
    country: null,
    show: false,
  });

  const { pageNumber, pageSize } = useTable();

  const handleFetchGetCountries = useCallback(() => {
    fetchGetCountries()
      .then((data) => {
        setCountries(data.items);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [pageNumber, pageSize]);

  useEffect(() => {
    handleFetchGetCountries();
  }, [pageNumber, pageSize]);

  const handleCloseRemoveModal = useCallback(() => {
    setRemoveModal({ country: null, show: false });
  }, []);

  useEventListener(EVENTS.REFRESH_DATA, handleFetchGetCountries);

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <>
      <Button className={styles.btn} onClick={() => setModal({ show: true })} endIcon={IconAdd} variant="secondary">
        Добавить страну
      </Button>
      <Table
        header={
          <Table.Row>
            <Table.Cell>Название</Table.Cell>
          </Table.Row>
        }
        isEmpty={isEmpty(countries)}
      >
        {countries.map((country) => (
          <Table.Row key={country._id}>
            <Table.Cell>
              <Button
                className={styles.itemBtn}
                onClick={() => setModal({ country, show: true })}
                small
                variant="empty"
              >
                {country.title}
              </Button>
              <IconButton
                icon={IconTrash}
                onClick={() =>
                  setRemoveModal({
                    country,
                    show: true,
                  })
                }
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>

      <CountryForm country={modal.country} isOpen={modal.show} onClose={() => setModal({ show: false })} />

      <Modal isOpen={removeModal.show}>
        <CountryRemoveModal id={removeModal.country?._id as string} onClose={handleCloseRemoveModal} />
      </Modal>
    </>
  );
};

const CountryRemoveModal = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: 'remove-country',
    mutationFn: async (brandId: string) => fetchRemoveCountry(brandId),
    onSuccess: () => {
      toast.success('Страна успешно удалена');
      triggerEvent(EVENTS.REFRESH_DATA);
      onClose();
    },
  });

  return (
    <>
      {isLoading}
      <ModalHeader onClose={onClose}>
        <h2>Удаление страны</h2>
      </ModalHeader>
      <ModalBody>Вы уверены, что хотите удалить страну?</ModalBody>
      <ModalFooter>
        <Flex>
          <Button onClick={() => mutateAsync(id)}>Удалить</Button>
          <Button onClick={onClose} variant="secondary">
            Отменить
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};
