import { useState } from 'react';

import cn from 'classnames';
import { v4 } from 'uuid';

import styles from './styles.module.scss';
import type { InputPasswordProps } from './types';

import IconEye from '@/components/Icons/IconEye';
import IconEyeSlash from '@/components/Icons/IconEyeSlash';
import IconButton from '@/components/UI/IconButton';

const InputPassword = (props: InputPasswordProps) => {
  const { id = v4(), error, label, variant } = props;
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const handleToggleVisiblePassword = () => {
    setVisiblePassword((prev) => !prev);
  };

  return (
    <label className={styles.label} htmlFor={id}>
      {Boolean(label) && label}
      <div className={styles.content}>
        <input
          id={id}
          className={cn(styles.input, {
            [styles.inputInvalid]: Boolean(error),
            [styles.inputSmall]: variant === 'small',
          })}
          {...props}
          type={visiblePassword ? 'text' : 'password'}
        />
        <IconButton
          className={styles.btn}
          icon={visiblePassword ? IconEyeSlash : IconEye}
          label=""
          onClick={handleToggleVisiblePassword}
        />
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </label>
  );
};

export default InputPassword;
