import { useState } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

import IconClose from '@/components/Icons/IconClose';
import Navigation from '@/components/Navigation';
import IconButton from '@/components/UI/IconButton';

export const BurgerMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <button
        className="flex items-center justify-center text-white p-3 border-none cursor-pointer w-[48px]"
        onClick={() => setShowMenu(true)}
      >
        <svg className="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </button>

      {showMenu && (
        <div className="fixed w-full h-full bg-dark-primary z-[999] left-0 top-0 pt-[64px]">
          <div className="h-full px-4">
            <Scrollbar>
              <Navigation onClick={() => setShowMenu(false)} />
            </Scrollbar>
          </div>
          <IconButton
            className="text-white absolute top-2 right-2"
            icon={IconClose}
            onClick={() => setShowMenu(false)}
            size="xl"
          />
        </div>
      )}
    </>
  );
};
