import type { RequestResetPasswordService, ResetPasswordService } from './types';

import api from '@/api';

export const requestPasswordReset: RequestResetPasswordService = async (body) => {
  const response = await api.post('/auth/request-password-reset', body);
  return response.data;
};

export const resetPassword: ResetPasswordService = async ({ password, recoveryToken }) => {
  const body = {
    newPassword: password,
    recoveryToken,
  };

  const response = await api.post('/auth/reset-password', body);
  return response.data;
};
