import { expensesSerialize } from './serializer';
import type { ExpensesFromServer, GetExpensesService } from './types';

import api from '@/api';

export const getExpenses: GetExpensesService = async () => {
  const response = await api.get<ExpensesFromServer>('/expense-type');
  return expensesSerialize(response.data);
};

export const getExpense = async (id: string) => {
  const response = await api.get(`/expense-type/${id}`);
  return response.data;
};
