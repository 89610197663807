import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { parse, stringify } from 'qs';

const useQueryParams = <TQuery extends object>(initialParams?: TQuery) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => {
    const searchQuery = search.startsWith('?') ? search.slice(1) : search;

    if (!search && initialParams) {
      return initialParams;
    }

    return { ...initialParams, ...parse(searchQuery) } as TQuery;
  }, [search, initialParams]);

  const setQuery = useCallback(
    (nextParams: TQuery, method: 'push' | 'remove' = 'push', replace = false) => {
      const nextQuery = { ...query, ...nextParams };

      navigate({ search: stringify(nextQuery, { encode: false }) }, { replace });
    },
    [navigate, query],
  );

  return [{ query, rawQuery: search }, setQuery] as const;
};

export default useQueryParams;
