import { useQuery } from 'react-query';

import type { CalendarRange } from '@/features/Calendar/components/CalendarRangeCells/constants';
import { fetchCalendarEntry } from '@/features/Calendar/services';

type Props = {
  from: string;
  to: string;
  range: CalendarRange;
};

export const useCalendarEntryQuery = ({ from, to, range }: Props) =>
  useQuery({
    enabled: Boolean(from) && Boolean(to),
    queryKey: ['calendarEntry', from, to, range],
    queryFn: async () => {
      const { items } = await fetchCalendarEntry({
        from,
        to,
      });
      return items;
    },
  });
