import type { ChangeEvent } from 'react';
import { useState } from 'react';

import styles from './styles.module.scss';
import type { AvatarUploadProps } from './types';
import { checkCanUpload } from './utils';

import IconAvatarUpload from '@/components/Icons/IconAvatarUpload';
import IconLoading from '@/components/Icons/IconLoading';
import { uploadFile } from '@/services';

const AvatarUpload = ({
  id,
  accept,
  multiple,
  invalid,
  url = null,
  errorMessageId,
  descriptionId,
  onChange,
  onUpload,
}: AvatarUploadProps) => {
  const [link, setLink] = useState<string | null>(url);
  const [isFetching, setIsFetching] = useState(false);

  const handleUploadFile = (file: File | null) => {
    if (!file || isFetching) return;

    setIsFetching(true);

    uploadFile(file)
      .then((res) => {
        setLink(res.link);
        onUpload?.(res);
      })
      .catch(() => {})
      .finally(() => {
        setIsFetching(false);
      });
  };

  const filterFiles = (files: FileList) => Array.from(files).filter((file) => checkCanUpload({ accept, file }));
  const uploadFiles = (data: File[]) => {
    if (typeof onUpload !== 'function') {
      return;
    }

    handleUploadFile(data[0] || null);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange(e);
    }
    if (!e.target.files) {
      return;
    }

    uploadFiles(filterFiles(e.target.files));
  };

  return (
    <label htmlFor={id} className={styles.label}>
      {isFetching ? (
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <IconLoading width={24} height={24} />
        </div>
      ) : (
        <>
          <input
            value=""
            type="file"
            id={id}
            accept={accept}
            multiple={multiple}
            aria-invalid={invalid}
            aria-errormessage={errorMessageId}
            aria-describedby={descriptionId}
            className={styles.labelInput}
            onChange={handleChange}
          />
          {link ? (
            <img className={styles.labelAvatar} src={String(link)} width={40} height={40} alt="" />
          ) : (
            <IconAvatarUpload />
          )}
          <div>
            <h3 className={styles.labelTitle}>Загрузите аватар</h3>
            <p className={styles.labelDescription}>Загрузите аватарку</p>
          </div>
        </>
      )}
    </label>
  );
};

export default AvatarUpload;
