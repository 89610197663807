import { useFormikContext } from 'formik';

import styles from './styles.module.scss';

import IconEdit from '@/components/Icons/IconEdit';
import IconTrash from '@/components/Icons/IconTrash';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import { socialMediaIcons } from '@/constants';
import type { IndividualAgent, SocialMediaPlatformEnum } from '@/types';

const AgentSocialMediaList = () => {
  const { values, setValues } = useFormikContext<IndividualAgent>();

  const handleRemove = (index: number) => {
    values.socialMedia.splice(index, 1);

    setValues({
      ...values,
      socialMedia: Array.from(values.socialMedia),
    });
  };

  return (
    <Flex className={styles.social} flexDirection="column" rowGap="sm">
      {values.socialMedia.map((item, index) => {
        const Icon = socialMediaIcons[item.platform as SocialMediaPlatformEnum];
        return (
          <div key={item.nickname} className={styles.socialItem}>
            <div className={styles.socialNickname}>
              <Icon /> @{item.nickname}
            </div>
            <Flex alignItems="center" columnGap="zero" className={styles.socialActions}>
              <IconButton icon={IconEdit} label="Редактировать социальную сеть" onClick={() => null} />
              <IconButton
                icon={IconTrash}
                label="Удалить социальную сеть"
                onClick={() => handleRemove(index)}
                variant="empty-red"
              />
            </Flex>
          </div>
        );
      })}
    </Flex>
  );
};

export default AgentSocialMediaList;
