export enum OrderInfoTab {
  History = 'history',
}

export const orderInfoTabs = {
  [OrderInfoTab.History]: 'История сделки',
};

export const individualAgentFormInitialValues = {
  role: 'client',
  firstName: '',
  phone: '',
  discount: 0,
  isEmployee: false,
};
