import { useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';

import AvatarUpload from '../AvatarUpload';

import { FormFields, ownerFormInitialValues } from './constants';
import { ownerFormSchema } from './schema';
import styles from './styles.module.scss';
import type { AgentWelcomeEntity } from './types';

import { If } from '@/components/ConditionalRendering/If';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import Input from '@/components/UI/Input';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Switch from '@/components/UI/Switch';
import { updateCarServiceOwner } from '@/features/Agent/service';
import { createSubdivision } from '@/features/Subdivision/services';
import type { CarServiceSubdivisionEntity } from '@/features/Subdivision/types';
import { useAppProvider } from '@/providers/AppProvider/hooks';
import { useAppSelector } from '@/store';
import { selectUser } from '@/store/slices/userSlice';

const AgentWelcomeForm = () => {
  const { showWelcomeModal } = useAppProvider();
  const [subdivision, setSubdivision] = useState<CarServiceSubdivisionEntity>({
    address: '',
    _id: '',
    bays: [],
    funnels: [],
    increment: 0,
    logo: undefined,
    schedule: [],
    sections: [],
    website: '',
    title: '',
  });
  const [useCompanyLogo, setUseCompanyLogo] = useState(false);
  const user = useAppSelector(selectUser);
  const [owner] = useState<AgentWelcomeEntity>(
    // @ts-ignore
    () => ownerFormInitialValues,
  );
  const [isFetching, setIsFetching] = useState(false);
  const handleCreateAgentForm = async (values: AgentWelcomeEntity) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await Promise.all([
        await updateCarServiceOwner(user?.owner?._id as string, values),
        await createSubdivision({
          ...subdivision,
          logo: values.logo,
          owner: user?.owner?._id,
        }),
      ]);
      window.location.reload();
    } catch (err) {
      setIsFetching(false);

      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    }
  };

  return (
    <Modal className={styles.modal} isOpen={showWelcomeModal}>
      <Formik
        // @ts-ignore
        initialValues={owner}
        enableReinitialize
        onSubmit={handleCreateAgentForm}
        validationSchema={ownerFormSchema}
        validateOnChange={false}
      >
        {({ values, setFieldValue, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className={styles.form}>
            <ModalHeader>
              <h2>Начало работы</h2>
            </ModalHeader>
            <ModalBody className={styles.modalBody}>
              <h2 className={styles.formTitle}>1. Информация о компании</h2>
              <Grid className={styles.column} gap="sm">
                <Grid.Item col={12}>
                  <AvatarUpload
                    url={values.companyLogo?.link}
                    onUpload={(file) => {
                      setFieldValue(FormFields.CompanyLogo, file._id);
                    }}
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <Input
                    label="Название"
                    maxLength={60}
                    onChange={handleChange}
                    name={FormFields.CompanyName}
                    placeholder="Введите название компании"
                    value={values[FormFields.CompanyName]}
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <Input
                    label="Адрес"
                    maxLength={60}
                    name={FormFields.Address}
                    onChange={handleChange}
                    placeholder="Введите адрес компании"
                    value={values[FormFields.Address]}
                  />
                </Grid.Item>
              </Grid>
              <h2 className={styles.formTitle}>2. Добавление автосервиса</h2>
              <Grid gap="sm">
                <If condition={!useCompanyLogo}>
                  <Grid.Item col={12}>
                    <AvatarUpload
                      onUpload={(file) => {
                        setFieldValue(FormFields.Logo, file._id);
                      }}
                    />
                  </Grid.Item>
                </If>
                <Grid.Item col={12}>
                  <Switch
                    checked={useCompanyLogo}
                    onChange={() => setUseCompanyLogo(!useCompanyLogo)}
                    value="Использовать логотип компании"
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <Input
                    label="Название"
                    maxLength={60}
                    name={FormFields.SubdivisionTitle}
                    placeholder="Введите название автосервиса"
                    onChange={(e) =>
                      setSubdivision((prev) => ({
                        ...prev,
                        title: e?.target.value,
                      }))
                    }
                    value={subdivision.title}
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <Input
                    label="Адрес"
                    maxLength={60}
                    name={FormFields.SubdivisionAddress}
                    onChange={(e) =>
                      setSubdivision((prev) => ({
                        ...prev,
                        address: e?.target.value,
                      }))
                    }
                    value={subdivision.address}
                    placeholder="Введите адрес автосервиса"
                  />
                </Grid.Item>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Flex alignItems="center" columnGap="sm">
                <Button disabled={isFetching} isLoading={isFetching} type="submit">
                  Начать работу
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AgentWelcomeForm;
