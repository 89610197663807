import { useState } from 'react';

import { initialPagination } from './constants';
import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import Pagination from '@/components/UI/Pagination';
import Table from '@/components/UI/Table';
import { useOnce } from '@/hooks';
import { getExpenses } from '@/pages/Expenses/components/ExpensesListTable/service';
import type { ExpenseEntity, MetaPagination } from '@/types';

const ExpensesListTable = () => {
  const [expenses, setExpenses] = useState<ExpenseEntity[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [pagination, setPagination] = useState<MetaPagination>(initialPagination);

  useOnce(() => {
    getExpenses()
      .then((res) => {
        setExpenses(res.items);
        setPagination(res.pagination);
      })
      .catch(() => {})
      .finally(() => {
        setIsFetching(false);
      });
  });

  return (
    <>
      <Table
        className={styles.table}
        header={
          <Table.Row>
            <Table.Cell>№</Table.Cell>
            <Table.Cell>Номер</Table.Cell>
            <Table.Cell>Сумма</Table.Cell>
            <Table.Cell>Основание</Table.Cell>
            <Table.Cell>Кому</Table.Cell>
            <Table.Cell>Откуда</Table.Cell>
          </Table.Row>
        }
        isEmpty={!expenses.length}
        isFetching={isFetching}
      >
        {expenses.map((expense) => (
          <Table.Row key={expense._id}>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>2345</Table.Cell>
            <Table.Cell>39 515.37 ₽</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>Касса: Касса </Table.Cell>
            <Table.Cell>Касса: Касса </Table.Cell>
          </Table.Row>
        ))}
      </Table>
      <If condition={Boolean(expenses.length)}>
        <Pagination
          totalPages={pagination.totalPages}
          activePage={pagination.pageNumber}
          pageSize={pagination.totalPages}
          totalItems={pagination.totalItems}
          onPageChange={() => null}
        />
      </If>
    </>
  );
};

export default ExpensesListTable;
