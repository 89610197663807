import { useState } from 'react';

import type { WarehouseStatusProps } from './types';

import Flex from '@/components/UI/Flex';
import Switch from '@/components/UI/Switch';
import { updateWarehouse } from '@/features/Warehouse/services';

const WarehouseStatus = ({ id, isActive }: WarehouseStatusProps) => {
  const [active, setActive] = useState(isActive);
  const [isFetching, setIsFetching] = useState(false);

  const handleToggleActive = async () => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await updateWarehouse(id, {
        isActive: !active,
      });
      setActive((prev) => !prev);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Flex alignItems="center" columnGap="xs">
      <Switch disabled={isFetching} checked={active} onChange={() => handleToggleActive()} />
      <span>Активный</span>
    </Flex>
  );
};

export default WarehouseStatus;
