import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import type { CalendarContextValue } from './types';

import { CalendarRange } from '@/features/Calendar/components/CalendarRangeCells/constants';
import { useCalendarEntryQuery } from '@/features/Calendar/hooks/useCalendarEntryQuery';
import { useQueryParams } from '@/hooks';
import dayjs from '@/utils/dayjs';

const CalendarContext = createContext<CalendarContextValue & Partial<ReturnType<typeof useCalendarEntryQuery>>>({});

export const CalendarProvider = ({ children }: PropsWithChildren) => {
  const [range, setRange] = useState<CalendarRange>(CalendarRange.Day);
  const [showCalendarEntityModal, setShowCalendarEntityModal] = useState({
    show: false,
  });
  const [
    {
      query: { to, from },
    },
    setQuery,
  ] = useQueryParams<{ from: string; to: string }>({
    from: dayjs().format('YYYY-MM-DD'),
    to: dayjs().add(1, 'day').format('YYYY-MM-DD'),
  });

  const { isFetching, data, refetch } = useCalendarEntryQuery({
    from,
    to,
    range,
  });

  const onResetDate = (range: CalendarRange) => {
    if (range === CalendarRange.Month) {
      setQuery({
        from: dayjs(from).startOf('month').format('YYYY-MM-DD'),
        to: dayjs(from).endOf('month').format('YYYY-MM-DD'),
      });
    } else if (range === CalendarRange.Week) {
      setQuery({
        from: dayjs(from).startOf('week').format('YYYY-MM-DD'),
        to: dayjs(from).endOf('week').format('YYYY-MM-DD'),
      });
    } else {
      setQuery({
        from: dayjs(from).format('YYYY-MM-DD'),
        to: dayjs(from).add(1, 'day').format('YYYY-MM-DD'),
      });
    }
  };

  const onPrevDate = (range: CalendarRange) => {
    if (range === CalendarRange.Month) {
      const _from = dayjs(from).subtract(1, getOperationValue(range));

      setQuery({
        from: _from.startOf('month').format('YYYY-MM-DD'),
        to: _from.endOf('month').format('YYYY-MM-DD'),
      });
    } else {
      setQuery({
        from: dayjs(from).subtract(1, getOperationValue(range)).format('YYYY-MM-DD'),
        to: dayjs(from).format('YYYY-MM-DD'),
      });
    }
  };

  const onNextDate = (range: CalendarRange) => {
    if (range === CalendarRange.Month) {
      const _from = dayjs(from).add(1, getOperationValue(range));

      setQuery({
        from: _from.startOf('month').format('YYYY-MM-DD'),
        to: _from.endOf('month').format('YYYY-MM-DD'),
      });
    } else {
      setQuery({
        from: dayjs(to).format('YYYY-MM-DD'),
        to: dayjs(to).add(1, getOperationValue(range)).format('YYYY-MM-DD'),
      });
    }
  };

  const value = useMemo(
    () => ({
      data,
      from,
      to,
      isFetching,
      refetch,
      onPrevDate,
      onNextDate,
      onResetDate,
      range,
      setRange,
      showCalendarEntityModal,
      setShowCalendarEntityModal,
    }),
    [
      data,
      from,
      to,
      isFetching,
      refetch,
      onPrevDate,
      onNextDate,
      onResetDate,
      range,
      setRange,
      showCalendarEntityModal,
      setShowCalendarEntityModal,
    ],
  );

  return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};

export const useCalendarContext = () => useContext(CalendarContext);

export const getOperationValue = (range: CalendarRange) => {
  if (range === CalendarRange.Day) return 'day';
  if (range === CalendarRange.Month) return 'month';
  return 'week';
};
