export const columns = [
  {
    name: 'increment',
    title: 'Номер',
    link: '/arrival/',
    params: {
      width: 80,
    },
  },
  {
    name: 'supplier',
    title: 'Поставщик',
  },
  {
    name: 'status',
    title: 'Статус',
  },
  {
    name: 'createdAt',
    title: 'Дата создания',
  },
  {
    name: 'warehouse',
    title: 'Склад',
  },
  {
    name: 'subdivision',
    title: 'Подразделение',
  },
  {
    name: 'totalBuyingPrice',
    title: 'Сумма документа',
  },
  {
    name: 'products',
    title: 'Количество позиций',
  },
  {
    name: 'responsible',
    title: 'Ответственный менеджер',
    params: {
      width: 120,
    },
  },
];
