import React from 'react';

import cx from 'classnames';

import styles from './styles.module.scss';
import type { RadioGroupProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import Radio from '@/components/UI/Radio';
import type { RadioProps } from '@/components/UI/Radio/types';

const RadioGroup = ({
  value,
  name = '',
  invalid = false,
  error,
  descriptionId,
  className = '',
  children,
  onChange,
  label,
  ...props
}: RadioGroupProps) => {
  const enhancedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child) || child.type !== Radio) {
      return null;
    }

    const inputValue = (child.props as RadioProps).value || '';

    return React.cloneElement(child, {
      name,
      onChange,
      checked: inputValue === value,
    } as RadioProps);
  });

  return (
    <div
      {...props}
      role="radiogroup"
      aria-invalid={invalid}
      aria-describedby={descriptionId}
      className={cx(styles.radioGroup, className)}
    >
      <span className={styles.radioGroupLabel}>{label}</span>
      <div className={styles.radioGroupItems}>{enhancedChildren}</div>
      <If condition={Boolean(error)}>
        <p className={styles.error}>{error}</p>
      </If>
    </div>
  );
};

export default RadioGroup;
