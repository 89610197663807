import { useParams } from 'react-router';

import styles from './styles.module.scss';

import Header from '@/components/Header';
import { fetchWarehouse } from '@/features/Warehouse/services';
import { useOnce } from '@/hooks';

const WarehouseDetail = () => {
  const { id } = useParams();

  useOnce(() => {
    fetchWarehouse(id as string).then(() => {});
  });

  return (
    <div className={styles.container}>
      <Header showSearch={false} title="Склад" />
    </div>
  );
};

export default WarehouseDetail;
