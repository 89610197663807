import * as Yup from 'yup';

import { FormFields } from './constants';

import type { IndividualAgent } from '@/types';
import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const warehouseArrivalFormSchema: Yup.ObjectSchema<IndividualAgent> = Yup.object().shape({
  [FormFields.AccountingDate]: Yup.string().required(messages.required),
  [FormFields.DocumentType]: Yup.string().required(messages.required),
  [FormFields.Responsible]: Yup.string().required(messages.required),
  [FormFields.Supplier]: Yup.string().required(messages.required),
});
