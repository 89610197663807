import type { ReactNode } from 'react';
import type { DropdownIndicatorProps, GroupBase, Props } from 'react-select';
import ReactSelect, { components } from 'react-select';

import cn from 'classnames';

import getStyles from './styles';
import s from './styles.module.scss';
import type { SelectProps } from './types';

import IconArrowDown from '@/components/Icons/IconArrowDown';
import IconLoading from '@/components/Icons/IconLoading';

const IndicatorsContainer = ({ children }: { children: ReactNode }) => <>{children}</>;

const LoadingIndicator = () => <IconLoading stroke="red" />;

const defaultNoOptionsMessage = () => 'Ничего не найдено';

const Select = function <
  Option,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<Option> = GroupBase<Option>,
>({
  id = '',
  className,
  invalid = false,
  errorMessageId = '',
  descriptionId,
  menuPlacement = 'auto',
  isRounded = false,
  placeholder = 'Выберите из списка',
  label,
  error,
  isEmpty = false,
  size = 'md',
  noOptionsMessage = defaultNoOptionsMessage,
  ...props
}: Props<Option, IsMulti, GroupType> & SelectProps) {
  const styles = getStyles<Option, IsMulti, GroupType>({ isRounded, isEmpty });

  const DropdownIndicator = (props: DropdownIndicatorProps<Option, IsMulti, GroupType>) => (
    <components.DropdownIndicator className={s.containerIndicator} {...props}>
      <IconArrowDown />
    </components.DropdownIndicator>
  );

  return (
    <div className={s.container}>
      {Boolean(label) && <label className={s.label}>{label}</label>}
      <div>
        <ReactSelect
          {...props}
          aria-describedby={descriptionId}
          aria-errormessage={errorMessageId}
          aria-invalid={invalid}
          styles={styles}
          menuPlacement={menuPlacement}
          components={{
            IndicatorsContainer,
            DropdownIndicator,
            LoadingIndicator,
          }}
          placeholder={placeholder}
          className={cn(s.containerSelect, size === 'sm' && s.containerSelectSmall, className)}
          inputId={id}
          noOptionsMessage={noOptionsMessage}
        />
      </div>
      {Boolean(error) && <p className={s.error}>{error}</p>}
    </div>
  );
};

export default Select;
