const IconTelegram = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
      fill="#0088CC"
    />
    <path
      d="M6.07494 11.7812C9.29616 10.3778 11.4441 9.45256 12.5189 9.00553C15.5875 7.72919 16.2251 7.50747 16.6407 7.50007C16.7322 7.49854 16.9365 7.52119 17.0689 7.62862C17.1807 7.71932 17.2115 7.84186 17.2262 7.92786C17.2409 8.01386 17.2592 8.20977 17.2447 8.36285C17.0784 10.1101 16.3588 14.3501 15.9928 16.307C15.8379 17.1351 15.5329 17.4127 15.2376 17.4399C14.596 17.4989 14.1087 17.0158 13.4872 16.6084C12.5147 15.9709 11.9653 15.5741 11.0213 14.952C9.93032 14.2331 10.6375 13.8379 11.2593 13.1922C11.422 13.0232 14.2492 10.4516 14.3039 10.2183C14.3108 10.1891 14.3171 10.0804 14.2525 10.023C14.1879 9.96553 14.0926 9.98516 14.0238 10.0008C13.9262 10.0229 12.3726 11.0498 9.36292 13.0814C8.92193 13.3842 8.5225 13.5318 8.16462 13.524C7.77009 13.5155 7.01117 13.301 6.44699 13.1176C5.755 12.8926 5.20502 12.7737 5.25291 12.3917C5.27785 12.1927 5.55186 11.9892 6.07494 11.7812Z"
      fill="white"
    />
  </svg>
);

export default IconTelegram;
