import styles from './styles.module.scss';

import IconPlay from '@/components/Icons/IconPlay';
import Flex from '@/components/UI/Flex';
import Table from '@/components/UI/Table';

const AgentCallsListTable = () => (
  <>
    <Table
      className={styles.table}
      header={
        <Table.Row>
          <Table.Cell>Тип звонка</Table.Cell>
          <Table.Cell>Менеджер</Table.Cell>
          <Table.Cell>Подразделение</Table.Cell>
          <Table.Cell>UTM</Table.Cell>
          <Table.Cell>Дата и время</Table.Cell>
          <Table.Cell>Продолжительность</Table.Cell>
          <Table.Cell>Клиент ждал до ответа</Table.Cell>
          <Table.Cell>Номер сделки</Table.Cell>
          <Table.Cell>Статус сделки</Table.Cell>
          <Table.Cell>Воронка продаж</Table.Cell>
        </Table.Row>
      }
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
        <Table.Row key={index}>
          <Table.Cell>Исходящий звонок</Table.Cell>
          <Table.Cell>Колесниченко Евгений Русланович</Table.Cell>
          <Table.Cell>0,00 ₽</Table.Cell>
          <Table.Cell>utm_call=site</Table.Cell>
          <Table.Cell>04.04.2024 18:18</Table.Cell>
          <Table.Cell>
            <Flex alignItems="center" columnGap="xs">
              <IconPlay />
              <span>6 мин 45 сек</span>
            </Flex>
          </Table.Cell>
          <Table.Cell>04.04.2024 18:18</Table.Cell>
          <Table.Cell>3698</Table.Cell>
          <Table.Cell>Неразобранное</Table.Cell>
          <Table.Cell>34 345 км</Table.Cell>
        </Table.Row>
      ))}
    </Table>
  </>
);

export default AgentCallsListTable;
