import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import IconClose from '@/components/Icons/IconClose';
import IconPlay from '@/components/Icons/IconPlay';
import { vizualizer } from '@/features/Telephony/components/TelephonyCallModal/constants';

export const TelephonyCallModal = () => {
  return (
    <Flex className="w-[643px] min-h-[104px] rounded-[8px] p-4 bg-white" rowGap="sm" flexDirection="column">
      <Flex alignItems="center" justifyContent="space-between">
        <h2 className="text-sm text-dark-primary">Запись звонка</h2>
        <IconButton icon={IconClose} onClick={() => null} size="xs" />
      </Flex>
      <div>
        <Flex>
          <IconButton className="!rounded-full" icon={IconPlay} onClick={() => null} variant="dark" size="xl" />
          <Flex className="!gap-x-[2px]">
            {vizualizer.map((i) => (
              <div key={i} className="w-[2px] h-[20px] bg-dark-primary" />
            ))}
          </Flex>
        </Flex>
      </div>
    </Flex>
  );
};
