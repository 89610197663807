import api from '@/api';
import { CallsEntity } from '@/features/Telephony/types';

export const fetchGetCalls = async (): Promise<{ items: CallsEntity }> => {
  const response = await api.get('/call');
  return response.data;
};

export const fetchDownloadCall = async (callId: string) => {
  const response = await api.get(`/call/download-record?recordId=${callId}`, {
    responseType: 'blob',
  });
  return response.data;
};
