import cn from 'classnames';

import styles from './styles.module.scss';
import type { CalendarMarkerProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import Button from '@/components/UI/Button';

export const CalendarMarker = ({ isActive, onSelect, variant }: CalendarMarkerProps) => {
  const handleClick = () => {
    onSelect(variant);
  };

  return (
    <Button className={styles.btn} onClick={handleClick} variant="empty">
      <If condition={isActive}>
        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" className={styles.icon}>
          <path
            d="M3.5775 7.99988C3.3775 7.99988 3.1875 7.91059 3.0475 7.75432L0.2175 4.59562C-0.0725 4.27194 -0.0725 3.73619 0.2175 3.4125C0.5075 3.08882 0.9875 3.08882 1.2775 3.4125L3.5775 5.97965L8.7175 0.24264C9.0075 -0.0810429 9.4875 -0.0810429 9.7775 0.24264C10.0675 0.566324 10.0675 1.10207 9.7775 1.42576L4.1075 7.75432C3.9675 7.91059 3.7775 7.99988 3.5775 7.99988Z"
            fill="#fff"
          />
        </svg>
      </If>

      <div className={cn(styles.marker, styles[`marker--${variant}`])} />
    </Button>
  );
};
