import type { PropsWithChildren } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import cn from 'classnames';

import GridItem from './GridItem';
import styles from './styles.module.scss';
import type { GridItemProps, GridProps } from './types';

const Grid = ({ children, className, gap = 'md' }: PropsWithChildren<GridProps>) => {
  const enhancedChildren = Children.map(children, (child) => {
    if (!isValidElement(child) || child.type !== GridItem) {
      return child;
    }

    const props = child.props;

    return cloneElement(child, {
      col: props.col,
    } as GridItemProps);
  });

  return <div className={cn(styles.grid, styles[`grid--${gap}`], className)}>{enhancedChildren}</div>;
};

Grid.Item = GridItem;

export default Grid;
