import * as Yup from 'yup';

import { FormFields } from './constants';

import type { Payment } from '@/features/Payment/types';
import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const orderPaymentFormSchema: Yup.ObjectSchema<Payment> = Yup.object().shape({
  [FormFields.Amount]: Yup.number().required(messages.required),
});
