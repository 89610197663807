import { useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { Menu, NavigationToggleProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconArrowDown from '@/components/Icons/IconArrowDown';
import IconArrowUp from '@/components/Icons/IconArrowUp';
import { isCurrentPath } from '@/components/Navigation/utils';
import Button from '@/components/UI/Button';

const NavigationToggle = ({ item }: NavigationToggleProps) => {
  const { pathname } = useLocation();
  const [isOpened, setIsOpened] = useState(false);

  const Icon = item.icon;

  const opened = useMemo(() => isCurrentPath(item.path, pathname), [item.path, pathname]);

  const handleToggle = () => {
    setIsOpened((prev) => !prev);
  };

  const renderMenu = (item: Menu) =>
    item?.submenu?.map(({ icon: Icon, path, title }) => (
      <li key={item.path}>
        <NavLink
          state={{
            title,
          }}
          className={({ isActive }) => cn(styles.navLink, isActive && styles.navSubLinkActive)}
          to={path}
        >
          <div className={styles.navRow}>
            {Icon && <Icon />}
            {title}
          </div>
        </NavLink>
      </li>
    ));

  return (
    <li className={styles.navItem}>
      <div className={cn(styles.navRow, opened && styles.navRowOpened)}>
        <Button
          align="left"
          className={styles.navToggleButton}
          startIcon={Icon}
          endIcon={isOpened ? IconArrowUp : IconArrowDown}
          fullWidth
          onClick={handleToggle}
          variant="empty"
        >
          {item.title}
        </Button>
      </div>
      <If condition={isOpened}>
        <ul className={cn(styles.navList, styles.navListSubmenu)}>{renderMenu(item)}</ul>
      </If>
    </li>
  );
};

export default NavigationToggle;
