import styles from './styles.module.scss';

import IconArrowDown from '@/components/Icons/IconArrowDown';
import Flex from '@/components/UI/Flex';

const DealDetail = () => (
  <div className={styles.container}>
    <Flex alignItems="center" justifyContent="space-between">
      <h1 className={styles.title}>Сделка #3143</h1>
      <ul className={styles.infoList}>
        <li className={styles.infoListItem}>
          Слесарный
          <IconArrowDown />
        </li>
        <li className={styles.infoListItem}>
          ИП Нисифоров Роман Алексеевич <IconArrowDown />
        </li>
      </ul>
    </Flex>
  </div>
);

export default DealDetail;
