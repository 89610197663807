import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import IconAdd from '@/components/Icons/IconAdd';
import IconTrash from '@/components/Icons/IconTrash';
import LoadingIndicatorPage from '@/components/LoadingIndicatorPage';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Table from '@/components/UI/Table';
import { EVENTS } from '@/constants';
import { BrandForm } from '@/features/Brand/components/BrandForm';
import { fetchGetBrands, fetchRemoveBrand } from '@/features/Brand/services';
import type { BrandEntity, BrandsEntity } from '@/features/Brand/types';
import { useEventListener, useTable } from '@/hooks';
import { triggerEvent } from '@/utils/event';

export const BrandTableList = () => {
  const [brands, setBrands] = useState<BrandsEntity>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modal, setModal] = useState<{
    brand?: BrandEntity | null;
    show: boolean;
  }>({
    brand: null,
    show: false,
  });
  const [removeModal, setRemoveModal] = useState<{
    brand?: BrandEntity | null;
    show: boolean;
  }>({
    brand: null,
    show: false,
  });

  const handleCloseRemoveModal = useCallback(() => {
    setRemoveModal({ brand: null, show: false });
  }, []);

  const { pageNumber, pageSize } = useTable();

  const handleFetchGetBrands = useCallback(() => {
    fetchGetBrands()
      .then((data) => {
        setBrands(data.items);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [pageNumber, pageSize]);

  useEffect(() => {
    handleFetchGetBrands();
  }, [pageNumber, pageSize]);

  useEventListener(EVENTS.REFRESH_DATA, handleFetchGetBrands);

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <>
      <Button className={styles.btn} onClick={() => setModal({ show: true })} endIcon={IconAdd} variant="secondary">
        Добавить бренд
      </Button>
      <Table
        header={
          <Table.Row>
            <Table.Cell>Название</Table.Cell>
          </Table.Row>
        }
        isEmpty={isEmpty(brands)}
      >
        {brands.map((brand) => (
          <Table.Row key={brand._id}>
            <Table.Cell>
              <Button className={styles.itemBtn} onClick={() => setModal({ brand, show: true })} small variant="empty">
                {brand.title}
              </Button>
              <IconButton
                icon={IconTrash}
                onClick={() =>
                  setRemoveModal({
                    brand,
                    show: true,
                  })
                }
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>

      <BrandForm brand={modal.brand} isOpen={modal.show} onClose={() => setModal({ show: false })} />

      <Modal isOpen={removeModal.show}>
        <BrandRemoveModal id={removeModal.brand?._id as string} onClose={handleCloseRemoveModal} />
      </Modal>
    </>
  );
};

const BrandRemoveModal = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: 'remove-brand',
    mutationFn: async (brandId: string) => fetchRemoveBrand(brandId),
    onSuccess: () => {
      toast.success('Бренд успешно удален');
      triggerEvent(EVENTS.REFRESH_DATA);
      onClose();
    },
  });

  return (
    <>
      {isLoading}
      <ModalHeader onClose={onClose}>
        <h2>Удаление бренда</h2>
      </ModalHeader>
      <ModalBody>Вы уверены, что хотите удалить бренд?</ModalBody>
      <ModalFooter>
        <Flex>
          <Button onClick={() => mutateAsync(id)}>Удалить</Button>
          <Button onClick={onClose} variant="secondary">
            Отменить
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};
