import { walletsSerialize } from './serializer';

import api from '@/api';
import type { GetWalletsRequestPayload, GetWalletsService, WalletsFromServer } from '@/features/Wallet/types';

export const getWallets: GetWalletsService = async ({ pageNumber, pageSize }) => {
  const params: GetWalletsRequestPayload = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 20,
  };

  const response = await api.get<WalletsFromServer>('/wallet', {
    params,
  });

  return walletsSerialize(response.data);
};
