import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';

import IconLoading from '@/components/Icons/IconLoading';
import type { ButtonProps } from '@/components/UI/Button/types';

const Button = ({
  align = 'center',
  children,
  className,
  disabled,
  isLoading = false,
  endIcon: EndIcon,
  startIcon: StartIcon,
  large,
  type = 'button',
  fullWidth,
  small,
  variant = 'default',
  ...props
}: PropsWithChildren<ButtonProps>) => (
  <button
    className={cn(
      styles.button,
      styles[`button--${variant}`],
      styles[`button--align-${align}`],
      large && styles['button--large'],
      small && styles['button--small'],
      fullWidth && styles['button--full-width'],
      className,
    )}
    disabled={disabled || isLoading}
    type={type}
    {...props}
  >
    <>
      {StartIcon && <StartIcon />}
      {isLoading && (
        <div className={styles.buttonSpinner}>
          <IconLoading />
        </div>
      )}
      <span className={cn(isLoading && styles['button--is-loading'])}>{children}</span>
      {!isLoading && EndIcon && <EndIcon />}
    </>
  </button>
);

export default Button;
