import api from '@/api';
import { getOrderByIdSerialize, getOrderKanbanSerialize } from '@/features/Order/serializer';
import type { GetOrdersParams, GetOrdersService, OrderEntity } from '@/features/Order/types';
import { serverResponseSerializer } from '@/utils/serializer';

export const getOrderByID = async (id: string) => {
  const response = await api.get(`/order/${id}`);
  return getOrderByIdSerialize(response.data);
};

export const getOrders: GetOrdersService = async ({ pageNumber, pageSize, sortField, sortValue }) => {
  const params: GetOrdersParams = {
    pageNumber,
    pageSize,
  };

  if (sortField?.trim().length && sortValue?.trim().length) {
    params.sort = {
      [sortField]: sortValue,
    };
  }

  const response = await api.post('/order/all', params);
  return serverResponseSerializer<OrderEntity>(response.data);
};

export const getOrdersKanban = async (body: any) => {
  const response = await api.post('/order/kanban', body);
  return getOrderKanbanSerialize(response.data);
};

export const createOrder = async (body: any) => {
  const response = await api.post('/order', body);
  return response.data;
};

export const updateOrder = async (id: string, body: any) => {
  const response = await api.patch(`/order/${id}`, body);
  return response.data;
};

export const changeStatus = async (id: string, body: any) => {
  const response = await api.post(`/order/change-status/${id}`, body);
  return response.data;
};

export const searchJobsByTitle = async (query: string) => {
  const response = await api.get(`/job/search-by-title?query=${query}`);
  return response.data;
};

export const fetchOrdersByClient = async (clientId: string) => {
  const response = await api.get(`/order/by-client/${clientId}`);
  return response.data;
};
