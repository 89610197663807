import { useState } from 'react';

import ExpensesListTable from './components/ExpensesListTable';

import Header from '@/components/Header';
import Meta from '@/components/Meta';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import Input from '@/components/UI/Input';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Select from '@/components/UI/Select';

const Expenses = () => {
  const [showModal] = useState(false);

  return (
    <div>
      <Meta title="Затраты" />
      <Header
        title="Затраты"
        // actions={
        //   <>
        //     <IconButton icon={IconSwitchOff} label="123" onClick={() => null} />
        //     <IconButton icon={IconFilter} label="123" onClick={() => null} />
        //     <IconButton icon={IconExport} label="123" onClick={() => null} />
        //     <IconButton icon={IconQuestion} label="123" onClick={() => null} />
        //   </>
        // }
      />
      <ExpensesListTable />

      <Modal isOpen={showModal} onClose={() => null}>
        <ModalHeader>
          <h2>Транзакция затраты</h2>
        </ModalHeader>
        <ModalBody>
          <Grid>
            <Grid.Item col={4}>
              <Select label="Статья затрат" placeholder="Выберите из списка" />
            </Grid.Item>
            <Grid.Item col={4}>
              <Select label="Из каких средств" placeholder="Выберите из списка" />
            </Grid.Item>
            <Grid.Item col={4}>
              <Select label="Получатель" placeholder="Выберите из списка" />
            </Grid.Item>
            <Grid.Item col={6}>
              <Input label="Дата операции" placeholder="Выберите дату операции" />
            </Grid.Item>
            <Grid.Item col={6}>
              <Input label="Сумма" placeholder="Введите сумму" />
            </Grid.Item>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Flex alignItems="center">
            <Button>Создать</Button>
            <Button variant="secondary">Отмена</Button>
          </Flex>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Expenses;
