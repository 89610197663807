import Header from '@/components/Header';
import { TelephonyListTable } from '@/features/Telephony';
import styles from './styles.module.scss';

export const TelephonyView = () => {
  return (
    <div className={styles.container}>
      <Header title="Звонки" showSearch={false} />
      <TelephonyListTable />
    </div>
  );
};
