import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';

dayjs.locale(ru);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Moscow');

export default dayjs;
