import Meta from '@/components/Meta';
import PaymentView from '@/features/Payment/components/PaymentView';

const Payments = () => (
  <>
    <Meta title="Движение денежных средств" />
    <PaymentView />
  </>
);

export default Payments;
