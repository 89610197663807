export enum DirectoryTabs {
  Brands = 'brands',
  Countries = 'countries',
}

export const tabs = [
  {
    label: 'Бренды',
    value: DirectoryTabs.Brands,
  },
  {
    label: 'Страны',
    value: DirectoryTabs.Countries,
  },
];
