import { useState } from 'react';

import JobCategories from '../JobCategories';
import JobsListTable from '../JobTableList';

import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import Header from '@/components/Header';
import IconAdd from '@/components/Icons/IconAdd';
import Button from '@/components/UI/Button';
import JobCategoryForm from '@/features/Job/components/JobCategoryForm';
import JobForm from '@/features/Job/components/JobForm';

const JobView = () => {
  const [showJobForm, setShowJobForm] = useState(false);
  const [showJobCategoryForm, setShowJobCategoryForm] = useState(false);

  return (
    <div className={styles.container}>
      <Header
        actions={
          <>
            <Button endIcon={IconAdd} onClick={() => setShowJobCategoryForm(true)}>
              Раздел
            </Button>
            <Button endIcon={IconAdd} variant="secondary" onClick={() => setShowJobForm(true)}>
              Работа
            </Button>
          </>
        }
        showSearch={false}
        title="Каталог работ"
      />
      <div className={styles.content}>
        <div className={styles.contentCategories}>
          <JobCategories height="95%" />
        </div>
        <JobsListTable />
      </div>
      <JobCategoryForm isOpen={showJobCategoryForm} onClose={() => setShowJobCategoryForm(false)} />
      <If condition={showJobForm}>
        <JobForm isOpen={true} onClose={() => setShowJobForm(false)} />
      </If>
    </div>
  );
};

export default JobView;
