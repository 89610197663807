import * as Yup from 'yup';

import { FormFields } from './constants';

import { agentSocialMediaFormSchema } from '@/features/Agent/components/AgentSocialMediaForm/schema';
import type { LegalAgent } from '@/types';
import * as expect from '@/utils/validationExpect';
import * as messages from '@/utils/validationMessages';
import * as patterns from '@/utils/validationPatterns';

// @ts-ignore
export const legalAgentFormSchema: Yup.ObjectSchema<LegalAgent> = Yup.object().shape({
  [FormFields.Email]: Yup.string().matches(patterns.emailRegex, messages.validEmail).required(messages.required),
  [FormFields.INN]: Yup.string().required(messages.required),
  [FormFields.LegalEntityType]: Yup.string().required(messages.required),
  [FormFields.Role]: Yup.string().required(messages.required),
  // @ts-ignore
  [FormFields.Phone]: Yup.string().test('phone', messages.validNumber, expect.phoneNumber).required(messages.required),
  [FormFields.SocialMedia]: Yup.array()
    .of(agentSocialMediaFormSchema)
    .min(1, messages.required)
    .required(messages.required),
  [FormFields.Title]: Yup.string().required(messages.required),
  [FormFields.Discount]: Yup.number()
    .min(0, messages.minValueZero)
    .max(100, messages.maxValueOneHundred)
    .required(messages.required),
});
