import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { first } from 'lodash';

import WarehouseArrivalTable from '../WarehouseArrivalTable';

import styles from './styles.module.scss';

import Header from '@/components/Header';
import IconAdd from '@/components/Icons/IconAdd';
import Button from '@/components/UI/Button';
import { createWarehouseArrival, getWarehouses } from '@/features/Warehouse/services';
import type { WarehouseEntity } from '@/features/Warehouse/types';

const WarehouseArrivalForm = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);

  const handleCreateArrival = async () => {
    setIsFetching(true);

    try {
      const warehouses = await getWarehouses();
      const warehouse = (first(warehouses.items) as WarehouseEntity)._id;
      const arrival = await createWarehouseArrival({ warehouse });
      navigate(`/arrival/${arrival._id}`);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={styles.container}>
      <Header
        actions={
          <>
            <Button disabled={isFetching} onClick={handleCreateArrival} endIcon={IconAdd}>
              Поступление
            </Button>
            {/* <Button endIcon={IconAdd} variant="secondary">*/}
            {/*  Создать коррекцию*/}
            {/* </Button>*/}
          </>
        }
        showSearch={false}
        title="Поступления"
      />
      <WarehouseArrivalTable />
    </div>
  );
};

export default WarehouseArrivalForm;
