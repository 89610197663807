import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { first, isEmpty } from 'lodash';
import noop from 'lodash/noop';

import type { AppProviderContextValue } from './types';

import api from '@/api';
import Overlay from '@/components/UI/Overlay';
import { SUBDIVISION } from '@/constants';
import { fetchGetBrands } from '@/features/Brand/services';
import type { BrandsEntity } from '@/features/Brand/types';
import { fetchGetCountries } from '@/features/Country/services';
import type { CountriesEntity } from '@/features/Country/types';
import { getUserGroups } from '@/features/Group/services';
import type { UserGroupEntity } from '@/features/Group/types';
import type { CarServiceSubdivisionEntity, GetSubdivisionsResponse } from '@/features/Subdivision/types';
import { useAppSelector } from '@/store';
import { selectUser } from '@/store/slices/userSlice';
import { setCookie } from '@/utils/cookies';

export const AppProviderContext = createContext<AppProviderContextValue>({
  appIsReady: false,
  brands: [],
  countries: [],
  showWelcomeModal: false,
  subdivisions: [],
  userGroups: [],
  currentSubdivision: null,
  onSetCurrentSubdivision: noop,
  handleCloseWelcomeModal: noop,
  getSubdivisions: noop,
});

export const AppProvider = ({ children }: PropsWithChildren) => {
  const [, setIsOpen] = useState(false);
  const [brands, setBrands] = useState<BrandsEntity>([]);
  const [countries, setCountries] = useState<CountriesEntity>([]);
  const [appIsReady, setAppIsReady] = useState(false);
  const user = useAppSelector(selectUser);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [subdivisions, setSubdivisions] = useState<CarServiceSubdivisionEntity[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroupEntity[]>([]);
  const [currentSubdivision, setCurrentSubdivision] = useState<CarServiceSubdivisionEntity | null>(null);

  const onSetCurrentSubdivision = (subdivisionId: string) => {
    setCookie(SUBDIVISION, subdivisionId, 365);
    const item = subdivisions.find((s) => s?._id === subdivisionId);
    item && setCurrentSubdivision(item);
  };

  const handleCloseWelcomeModal = useCallback(() => {
    setShowWelcomeModal(false);
  }, []);

  useEffect(() => {
    if (!user?._id) return;

    const initData = async () => {
      try {
        const [{ items: subdivisionItems }, { items: userGroups }, { items: brands }, { items: countries }] =
          await Promise.all([getSubdivisions(), getUserGroups(), fetchGetBrands(), fetchGetCountries()]);

        setBrands(brands);
        setCountries(countries);
        setUserGroups(userGroups);
        setSubdivisions(subdivisionItems);
        setShowWelcomeModal(isEmpty(subdivisionItems));
        setCurrentSubdivision(first(subdivisionItems) || null);
      } catch (err) {
        console.log(err);
      } finally {
        setAppIsReady(true);
      }
    };

    initData();
  }, [user?._id]);

  const getSubdivisions = async () => {
    const { data } = await api.get<GetSubdivisionsResponse>('/car-service-subdivision');

    return data;
  };

  const lockApp = useCallback(() => {
    setIsOpen(true);
  }, []);

  const unlockApp = useCallback(() => {
    setIsOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      appIsReady,
      brands,
      countries,
      currentSubdivision,
      lockApp,
      unlockApp,
      subdivisions,
      showWelcomeModal,
      onSetCurrentSubdivision,
      handleCloseWelcomeModal,
      getSubdivisions,
      userGroups,
    }),
    [appIsReady, brands, countries, currentSubdivision, lockApp, unlockApp, subdivisions, showWelcomeModal, userGroups],
  );

  return (
    <AppProviderContext.Provider value={value}>
      {children}
      {showWelcomeModal ? createPortal(<Overlay />, globalThis?.document.body) : null}
    </AppProviderContext.Provider>
  );
};
