import { useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';

import { FormFields, initialValues } from './constants';
import { jobFormSchema } from './schema';
import styles from './styles.module.scss';
import type { OrderJobFormProps } from './types';

import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import Input from '@/components/UI/Input';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Select from '@/components/UI/Select';
import Switch from '@/components/UI/Switch';
import { EVENTS } from '@/constants';
import { createJob, getJobCategories } from '@/features/Job/services';
import type { JobCategoryEntity, JobEntity } from '@/features/Job/types';
import { useOnce } from '@/hooks';
import { triggerEvent } from '@/utils/event';

const OrderJobForm = ({ isOpen, onClose }: OrderJobFormProps) => {
  const [isFetching] = useState(false);
  const [categories, setCategories] = useState<JobCategoryEntity[]>([]);

  useOnce(() => {
    getJobCategories().then((data) => {
      setCategories(data?.items);
    });
  });

  const handeSubmit = async (values: JobEntity) => {
    try {
      await createJob(values);
      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success('Работа добавлена');
      onClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <Formik
        enableReinitialize
        // @ts-ignore
        initialValues={initialValues}
        validationSchema={jobFormSchema}
        onSubmit={handeSubmit}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Добавить работу</h2>
            </ModalHeader>
            <ModalBody className={styles.modalBody}>
              <Flex flexDirection="column" rowGap="lg">
                <Grid>
                  <Grid.Item col={6}>
                    <Input
                      error={errors[FormFields.Title]}
                      name={FormFields.Title}
                      label="Наименование работы"
                      onChange={handleChange}
                      placeholder="Введите наименование работы"
                      value={values[FormFields.Title]}
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Select
                      // @ts-ignore
                      error={errors[FormFields.Category]}
                      label="Раздел"
                      placeholder="Выберите раздел"
                      options={categories.map(({ _id, title }) => ({
                        label: title,
                        value: _id,
                      }))}
                      onChange={(e) => {
                        setFieldValue(FormFields.Category, e?.value);
                      }}
                    />
                  </Grid.Item>
                </Grid>
                <Grid>
                  <Grid.Item col={4}>
                    <Input
                      error={errors[FormFields.Price]}
                      name={FormFields.Price}
                      label="Цена работы"
                      onChange={handleChange}
                      placeholder="Введите цену работы"
                      value={values[FormFields.Price]}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      error={errors[FormFields.ManHours]}
                      name={FormFields.ManHours}
                      label="Количество НЧ"
                      onChange={handleChange}
                      placeholder="Введите количество НЧ"
                      value={values[FormFields.ManHours]}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      error={errors[FormFields.ManHourPrice]}
                      name={FormFields.ManHourPrice}
                      label="Цена НЧ"
                      onChange={handleChange}
                      placeholder="Введите цена НЧ"
                      value={values[FormFields.ManHourPrice]}
                    />
                  </Grid.Item>
                </Grid>
                <Switch
                  checked={values.isFixedPayment}
                  onChange={() => setFieldValue(FormFields.IsFixedPayment, !values.isFixedPayment)}
                  value="Фиксированная ЗП за услугу"
                />
                <Input label="Фиксированная ЗП за услугу" placeholder="Введите фиксированную ЗП за услугу" />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button disabled={isFetching} isLoading={isFetching} onChange={handleChange} type="submit">
                  Добавить
                </Button>
                <Button disabled={isFetching} onClick={onClose} variant="secondary">
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default OrderJobForm;
