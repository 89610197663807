const IconPlus = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.333 10.125H5.33301C5.30286 10.125 5.27158 10.1127 5.24594 10.0871C5.22029 10.0614 5.20801 10.0301 5.20801 10C5.20801 9.96985 5.22029 9.93857 5.24594 9.91293C5.27158 9.88728 5.30286 9.875 5.33301 9.875H15.333C15.3632 9.875 15.3944 9.88728 15.4201 9.91293C15.4457 9.93857 15.458 9.96985 15.458 10C15.458 10.0301 15.4457 10.0614 15.4201 10.0871C15.3944 10.1127 15.3632 10.125 15.333 10.125Z"
      fill="#2F2D39"
      stroke="#2F2D39"
    />
    <path
      d="M10.333 15.625C9.99134 15.625 9.70801 15.3417 9.70801 15V5C9.70801 4.65833 9.99134 4.375 10.333 4.375C10.6747 4.375 10.958 4.65833 10.958 5V15C10.958 15.3417 10.6747 15.625 10.333 15.625Z"
      fill="#2F2D39"
    />
  </svg>
);

export default IconPlus;
