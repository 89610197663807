import cx from 'classnames';

import styles from './styles.module.scss';
import type { CheckboxProps } from './types';

import IconCheck from '@/components/Icons/IconCheck';

const Checkbox = ({ boxAlign = 'start', className = '', value, ...props }: CheckboxProps) => (
  <label className={cx(styles.checkbox, className)}>
    <input {...props} type="checkbox" className={styles.checkboxHandle} />

    <span className={cx(styles.checkboxBox, styles[`checkboxBox-align-${boxAlign}`])}>
      <IconCheck />
    </span>

    {Boolean(value) && <span className={styles.checkboxLabel}>{value}</span>}
  </label>
);

export default Checkbox;
