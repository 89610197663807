import styles from './styles.module.scss';

import Table from '@/components/UI/Table';

const FinanceStatementListTable = () => (
  <>
    <Table
      className={styles.table}
      header={
        <Table.Row>
          <Table.Cell>Услуга</Table.Cell>
          <Table.Cell>Наш долг на начало периода</Table.Cell>
          <Table.Cell>Долг клиента на начало периода</Table.Cell>
          <Table.Cell>Поступило агенту</Table.Cell>
          <Table.Cell>Поступило от агента</Table.Cell>
          <Table.Cell>Наш долг на конец периода</Table.Cell>
          <Table.Cell>Долг клиента на конец периода</Table.Cell>
          <Table.Cell>Начислить</Table.Cell>
        </Table.Row>
      }
    >
      {[1, 2, 3, 4, 5, 6].map((_, index) => (
        <Table.Row key={index}>
          <Table.Cell>Химчистка салона автомобиля</Table.Cell>
          <Table.Cell>39 515.37 ₽</Table.Cell>
          <Table.Cell>39 515.37 ₽</Table.Cell>
          <Table.Cell>39 515.37 ₽</Table.Cell>
          <Table.Cell>39 515.37 ₽</Table.Cell>
          <Table.Cell>39 515.37 ₽</Table.Cell>
          <Table.Cell>39 515.37 ₽</Table.Cell>
          <Table.Cell>39 515.37 ₽</Table.Cell>
        </Table.Row>
      ))}
    </Table>
  </>
);

export default FinanceStatementListTable;
