import styles from './styles.module.scss';

import Container from '@/components/Container';
import Grid from '@/components/UI/Grid';

const AgentCalendar = () => (
  <Container className={styles.calendar} center>
    <Grid>
      <Grid.Item col={4}>
        <div className={styles.calendarItem}>
          <Grid>
            <Grid.Item col={6}>1</Grid.Item>
            <Grid.Item col={6}>1</Grid.Item>
          </Grid>
        </div>
      </Grid.Item>
      <Grid.Item col={4}>
        <div className={styles.calendarItem}>
          <Grid>
            <Grid.Item col={6}>1</Grid.Item>
            <Grid.Item col={6}>1</Grid.Item>
          </Grid>
        </div>
      </Grid.Item>
      <Grid.Item col={4}>
        <div className={styles.calendarItem}>
          <Grid>
            <Grid.Item col={6}>1</Grid.Item>
            <Grid.Item col={6}>1</Grid.Item>
          </Grid>
        </div>
      </Grid.Item>
    </Grid>
  </Container>
);

export default AgentCalendar;
