const IconMore = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.16667 12.2916C2.9 12.2916 1.875 11.2666 1.875 9.99992C1.875 8.73325 2.9 7.70825 4.16667 7.70825C5.43333 7.70825 6.45833 8.73325 6.45833 9.99992C6.45833 11.2666 5.43333 12.2916 4.16667 12.2916ZM4.16667 8.95825C3.59167 8.95825 3.125 9.42492 3.125 9.99992C3.125 10.5749 3.59167 11.0416 4.16667 11.0416C4.74167 11.0416 5.20833 10.5749 5.20833 9.99992C5.20833 9.42492 4.74167 8.95825 4.16667 8.95825Z"
      fill="currentColor"
    />
    <path
      d="M15.8346 12.2916C14.568 12.2916 13.543 11.2666 13.543 9.99992C13.543 8.73325 14.568 7.70825 15.8346 7.70825C17.1013 7.70825 18.1263 8.73325 18.1263 9.99992C18.1263 11.2666 17.1013 12.2916 15.8346 12.2916ZM15.8346 8.95825C15.2596 8.95825 14.793 9.42492 14.793 9.99992C14.793 10.5749 15.2596 11.0416 15.8346 11.0416C16.4096 11.0416 16.8763 10.5749 16.8763 9.99992C16.8763 9.42492 16.4096 8.95825 15.8346 8.95825Z"
      fill="currentColor"
    />
    <path
      d="M9.9987 12.2916C8.73203 12.2916 7.70703 11.2666 7.70703 9.99992C7.70703 8.73325 8.73203 7.70825 9.9987 7.70825C11.2654 7.70825 12.2904 8.73325 12.2904 9.99992C12.2904 11.2666 11.2654 12.2916 9.9987 12.2916ZM9.9987 8.95825C9.4237 8.95825 8.95703 9.42492 8.95703 9.99992C8.95703 10.5749 9.4237 11.0416 9.9987 11.0416C10.5737 11.0416 11.0404 10.5749 11.0404 9.99992C11.0404 9.42492 10.5737 8.95825 9.9987 8.95825Z"
      fill="currentColor"
    />
  </svg>
);

export default IconMore;
