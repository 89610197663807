import { useState } from 'react';

import cn from 'classnames';
import { isArray, map } from 'lodash';

import { CalendarEntryItem } from '../CalendarEntryItem';

import styles from './styles.module.scss';
import type { CalendarTableProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconEdit from '@/components/Icons/IconEdit';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import { CalendarPostModal, useCalendarContext } from '@/features/Calendar';
import { CalendarRangeCells } from '@/features/Calendar/components/CalendarRangeCells';
import { CalendarRange } from '@/features/Calendar/components/CalendarRangeCells/constants';
import type { ServiceBay } from '@/features/Order/types';

export const CalendarTable = ({ data, range }: CalendarTableProps) => {
  const [post, setPost] = useState<ServiceBay | null>(null);
  const [showPostModal, setShowPostModal] = useState(false);
  const { setShowCalendarEntityModal, from, to } = useCalendarContext();

  const handleEditPost = (value: ServiceBay) => {
    setPost(value);
    setShowPostModal(true);
  };

  return (
    <>
      <Flex className={styles.container} rowGap="zero" flexDirection="column">
        <CalendarRangeCells from={from as string} to={to as string} range={range} />
        {map(data, (item, index) => (
          <Flex key={index} className={styles.row} columnGap="zero" rowGap="zero">
            <Flex
              alignItems="center"
              rowGap="zero"
              justifyContent="space-between"
              className={cn(styles.td, range === CalendarRange.Month ? styles.tdMonth : styles.tdFirst)}
            >
              <span>{item?.serviceBay?.title}</span>
              <If condition={range !== CalendarRange.Month}>
                <IconButton
                  icon={IconEdit}
                  onClick={() => handleEditPost(item?.serviceBay)}
                  variant="empty-dark"
                  size="sm"
                />
              </If>
            </Flex>
            {map(item?.entries, (entry) => (
              <div key={entry._id} className={styles.td}>
                <If condition={isArray(entry)}>
                  {map(entry, (entryItem, index) => (
                    <CalendarEntryItem key={entryItem._id} index={index} item={entryItem} />
                  ))}
                </If>

                <button
                  className={styles.tdCreateBtn}
                  onClick={() =>
                    setShowCalendarEntityModal!({
                      date: entry.date,
                      show: true,
                      serviceBay: item?.serviceBay,
                    })
                  }
                />
              </div>
            ))}
          </Flex>
        ))}
      </Flex>

      <If condition={showPostModal}>
        <CalendarPostModal post={post} isOpen={showPostModal} onClose={() => setShowPostModal(false)} />
      </If>
    </>
  );
};
