import api from '@/api';

export const createWarehouse = async (body: any) => {
  const response = await api.post('/warehouse', body);
  return response?.data?.items;
};

export const fetchWarehouse = async (id: string) => {
  const response = await api.get(`/warehouse/${id}`);
  return response?.data;
};

export const updateWarehouse = async (id: string, body: any) => {
  const response = await api.patch(`/warehouse/${id}`, body);
  return response?.data?.items;
};

export const createWarehouseArrival = async (body?: any) => {
  const response = await api.post('/warehouse-arrival', body);
  return response?.data;
};

export const updateWarehouseArrival = async (id: string, body: any) => {
  const response = await api.patch(`/warehouse-arrival/${id}`, body);
  return response?.data;
};

export const getWarehouses = async () => {
  const response = await api.get('/warehouse');
  return response?.data;
};

export const getWarehouseArrival = async () => {
  const response = await api.get('/warehouse-arrival');
  return response?.data;
};

export const getWarehouseArrivalById = async (id: string) => {
  const response = await api.get(`/warehouse-arrival/${id}`);
  return response?.data;
};

export const getWarehouseTransfer = async () => {
  const response = await api.get('/warehouse-transfer');
  return response?.data;
};

export const getWarehouseProductStock = async (body: any) => {
  const response = await api.get('/warehouse/product-stock', body);
  return response.data;
};

export const getWarehouseProductStockGrouped = async (body: any) => {
  const response = await api.get('/warehouse/product-stock-grouped', body);
  return response.data;
};

export const getWarehouseProductStockGroupedById = async (id: string, body: any) => {
  const response = await api.get(`/warehouse/product-stock-grouped/${id}`, body);
  return response.data;
};

export const createWarehouseArrivalProduct = async (body: any) => {
  const response = await api.post('/warehouse-arrival/add-new-product', body);
  return response?.data;
};
