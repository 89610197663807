const IconArrowUp = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9418 11.6749C12.7835 11.6749 12.6251 11.6166 12.5001 11.4916L10.0001 8.9916L7.50013 11.4916C7.25846 11.7333 6.85846 11.7333 6.6168 11.4916C6.37513 11.2499 6.37513 10.8499 6.6168 10.6083L9.55846 7.6666C9.80013 7.42493 10.2001 7.42493 10.4418 7.6666L13.3835 10.6083C13.6251 10.8499 13.6251 11.2499 13.3835 11.4916C13.2585 11.6166 13.1001 11.6749 12.9418 11.6749Z"
      fill="currentColor"
    />
  </svg>
);

export default IconArrowUp;
