import styles from './styles.module.scss';

import Meta from '@/components/Meta';
import { PATHS } from '@/constants';
import WarehouseArrivalCreateView from '@/features/Warehouse/components/WarehouseArrivalCreateView';
import Header from '@/pages/CreateAgent/components/Header';

const WarehouseArrivalCreate = () => (
  <div className={styles.content}>
    <Meta title="Склад - Поступления - Новое поступление" />
    <Header
      breadcrumbs={[{ label: 'Поступления', path: PATHS.ARRIVAL }, { label: 'Новое поступление' }]}
      title="Новое поступление"
    />
    <WarehouseArrivalCreateView />
  </div>
);

export default WarehouseArrivalCreate;
