import { useState } from 'react';

import { useOnce, useTable } from '@/hooks';
import type { ResponseFromServer, ResponseFromService } from '@/types';

type RequestHandler<T> = (params?: any) => Promise<ResponseFromServer<T>>;
type Serializer<T> = (data: ResponseFromServer<T>) => ResponseFromService<T>;

const useRequest = <T>(request: RequestHandler<T>, serializer: Serializer<T>) => {
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState<T[]>();

  const { pageNumber, pageSize, setTotalPages, setTotalItems, totalPages, totalItems, handlePageChange } = useTable();

  const fetchData = () =>
    request()
      .then((response) => {
        const serializedItems = serializer(response);
        setData(serializedItems.items);
        setTotalPages(serializedItems.pagination?.totalPages || 1);
        setTotalItems(serializedItems.pagination?.totalItems || 0);
      })
      .finally(() => {
        setIsFetching(false);
      });

  useOnce(() => {
    fetchData();
  });

  return {
    data,
    isFetching,
    pageNumber,
    pageSize,
    setTotalPages,
    setTotalItems,
    totalPages,
    totalItems,
    handlePageChange,
    fetchData,
  };
};

export default useRequest;
