import type { Dayjs } from 'dayjs';

import type { OrderEntity, ServiceBay } from '@/features/Order/types';
import type { UserType } from '@/types';

export enum CalendarEntryColorEnum {
  Blue = 'blue',
  Black = 'black',
  LightPurple = 'purple',
  Pink = 'pink',
  Orange = 'orange',
  LightGreen = 'light-green',
  Green = 'green',
  Red = 'red',
  Brown = 'brown',
  Teal = 'teal',
}

export enum CalendarEntryTypeEnum {
  Order = 'order',
  Reserve = 'reserve',
  Blocking = 'blocking',
}

export interface CalendarEntity {
  _id?: string;
  __v?: string;
  date?: Dayjs;
  count?: number;
  hasItem?: boolean;
  marker: CalendarEntryColorEnum;
  serviceBay?: ServiceBay | string;
  manager?: string;
  startAt: string;
  endAt: string;
  type: CalendarEntryTypeEnum;
  contact?: UserType | string;
  contactPhone?: string;
  contactCarModel?: string;
  order?: OrderEntity | string;
  comment?: string;
}
