import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';

import { getPayments } from './service';
import styles from './styles.module.scss';
import type { PaymentsListTableProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import Pagination from '@/components/UI/Pagination';
import Table from '@/components/UI/Table';
import { EVENTS } from '@/constants';
import { paymentTypes } from '@/features/Payment/constants';
import type { Payment } from '@/features/Payment/types';
import { useOnce } from '@/hooks';
import { getBaseDate } from '@/utils/date';

const PaymentsListTable = ({ onSelectPayment }: PaymentsListTableProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const page = Number(queryParams.get('page')) || 1;
  const pageSize = Number(queryParams.get('pageSize')) || 20;
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);

  useOnce(() => {
    const refreshData = () => {
      setIsFetching(true);
      handleGetPayments();
    };

    document.addEventListener(EVENTS.REFRESH_DATA, refreshData);

    return () => {
      document.removeEventListener(EVENTS.REFRESH_DATA, refreshData);
    };
  });

  const handleGetPayments = useCallback(
    () =>
      getPayments({
        pageNumber: page,
        pageSize,
      })
        .then((data) => {
          setPayments(data.items);
          setTotalPages(data.pagination.totalPages || 1);
          setTotalItems(data.pagination.totalItems || 0);
        })
        .finally(() => {
          setIsFetching(false);
        }),
    [page, pageSize],
  );

  useEffect(() => {
    setIsFetching(true);
    handleGetPayments();
  }, [page, pageSize]);

  const handlePageChange = (nextPage: number) => {
    queryParams.set('page', String(nextPage));
    replaceQuery();
  };

  const replaceQuery = () => {
    navigate({
      ...location,
      search: String(queryParams),
    });
  };

  return (
    <>
      <Scrollbar>
        <Table
          className={styles.table}
          header={
            <Table.Row>
              <Table.Cell>Дата</Table.Cell>
              <Table.Cell>Тип</Table.Cell>
              <Table.Cell>Откуда</Table.Cell>
              <Table.Cell>Куда</Table.Cell>
              <Table.Cell>Баланс до операции</Table.Cell>
              <Table.Cell>Баланс после операции</Table.Cell>
              <Table.Cell>Сумма</Table.Cell>
              <Table.Cell>Основание</Table.Cell>
              <Table.Cell>Дата учета</Table.Cell>
              <Table.Cell>Дата исполнения</Table.Cell>
              <Table.Cell>Комментарий</Table.Cell>
            </Table.Row>
          }
          isEmpty={!payments.length}
          isFetching={isFetching}
        >
          {payments.map((payment) => (
            <Table.Row className={styles.row} onClick={() => onSelectPayment(payment)} key={payment._id}>
              <Table.Cell>{getBaseDate(payment.createdAt)}</Table.Cell>
              <Table.Cell>
                <span className={styles.operationType}>{paymentTypes[payment.paymentType]}</span>
              </Table.Cell>
              <Table.Cell>{payment.from?.ownerTitle}</Table.Cell>
              <Table.Cell>{payment.to?.ownerTitle}</Table.Cell>
              <Table.Cell>{payment.balanceBefore} ₽</Table.Cell>
              <Table.Cell>{payment.balanceAfter} ₽</Table.Cell>
              <Table.Cell>{payment.amount} ₽</Table.Cell>
              <Table.Cell>{payment.reason}</Table.Cell>
              <Table.Cell>{getBaseDate(payment.accountingDate)}</Table.Cell>
              <Table.Cell>{getBaseDate(payment.executionDate)}</Table.Cell>
              <Table.Cell>{payment.comment}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Scrollbar>
      <If condition={Boolean(payments.length)}>
        <Pagination
          activePage={page}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>
    </>
  );
};

export default PaymentsListTable;
