import styles from './styles.module.scss';

import Meta from '@/components/Meta';
import { PATHS } from '@/constants';
import WarehouseTransferCreateView from '@/features/Warehouse/components/WarehouseTransferCreateView';
import Header from '@/pages/CreateAgent/components/Header';

const WarehouseTransferCreate = () => (
  <div className={styles.content}>
    <Meta title="Склад - Перемещения - Новое перемещение" />
    <Header
      breadcrumbs={[{ label: 'Перемещения', path: PATHS.TRANSFER }, { label: 'Новое перемещение' }]}
      title="Новое перемещение"
    />
    <WarehouseTransferCreateView />
  </div>
);

export default WarehouseTransferCreate;
