import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { InputNumberProps } from './types';

import Input from '@/components/UI/Input';

// @ts-ignore
const InputNumber = forwardRef<NumericFormat, InputNumberProps>(
  ({ className, error, fullWidth, label, light, max, name, onChange, placeholder, value, ...props }, ref) => (
    <div className={cn(styles.container, fullWidth && styles.containerFullWidth, className)}>
      {Boolean(label) && label}
      <NumericFormat
        {...props}
        name={name}
        // @ts-ignore
        ref={ref}
        className={cn(styles.input, error && styles.invalid, light && styles.inputLight)}
        isAllowed={({ floatValue }) => {
          const val = Number(floatValue);

          if (max) {
            return val <= Number(max);
          }

          return true;
        }}
        placeholder={placeholder}
        onChange={onChange}
        customInput={Input}
        inputMode="tel"
        value={value}
      />
      {Boolean(error) && <p className={styles.error}>{error}</p>}
    </div>
  ),
);

export default InputNumber;
