import Header from '../Header';
import SettingsServicesForm from '../SettingsServicesForm';

import { breadcrumbs } from './constants';
import styles from './styles.module.scss';

const SettingsServiceView = () => (
  <div className={styles.content}>
    <Header breadcrumbs={breadcrumbs} title="Новый автосервис" />
    <SettingsServicesForm />
  </div>
);

export default SettingsServiceView;
