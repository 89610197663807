import { useState } from 'react';

import { isEmpty } from 'lodash';
import { Drawer } from 'rsuite';

import styles from './styles.module.scss';
import type { OrderTitleProps } from './types';

import IconClose from '@/components/Icons/IconClose';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import Tabs from '@/components/UI/Tabs';
import { OrderInfoTab, orderInfoTabs } from '@/features/Order/components/OrderForm/constants';
import { getBaseDate } from '@/utils/date';

export const OrderTitle = ({ order }: OrderTitleProps) => {
  const [showInfo, setShowInfo] = useState(false);
  const [tab, setTab] = useState<OrderInfoTab>(OrderInfoTab.History);

  return (
    <>
      <h1 className={styles.title}>
        <Button onClick={() => setShowInfo(true)} variant="empty">
          Сделка #{order?.increment}
        </Button>
      </h1>

      <Drawer open={showInfo}>
        <Drawer.Header>
          <Flex alignItems="center" fullWidth justifyContent="space-between">
            <Drawer.Title>Информация по сделке #{order?.increment}</Drawer.Title>
            <IconButton
              icon={IconClose}
              label="Закрыть модальное окно"
              onClick={() => setShowInfo(false)}
              size="sm"
              className={styles.closeBtn}
              variant="secondary"
            />
          </Flex>
        </Drawer.Header>
        <Drawer.Body>
          <Tabs value={tab} onSelect={setTab}>
            {Object.entries(orderInfoTabs).map(([key, value]) => (
              <Tabs.Item key={key} value={key}>
                {value}
              </Tabs.Item>
            ))}
          </Tabs>
          {tab === OrderInfoTab.History && (
            <>
              {isEmpty(order?.history) ? (
                <div className={styles.historyEmpty}>Истории нет</div>
              ) : (
                <div className={styles.history}>
                  <Flex flexDirection="column" rowGap="zero" className={styles.historyItems}>
                    {order?.history?.map((item, index) => (
                      <div className={styles.historyItem} key={index}>
                        <div className={styles.historyTime}>{getBaseDate(item.createdAt)}</div>
                        <span className={styles.historyComment}>{item.comment}</span>
                      </div>
                    ))}
                  </Flex>
                </div>
              )}
            </>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};
