import { CounterpartRoleEnum } from '@/types';

export const agentsType = [
  {
    label: 'Клиент',
    value: CounterpartRoleEnum.Client,
  },
  {
    label: 'Офис',
    value: CounterpartRoleEnum.Office,
  },
  {
    label: 'Предприятие',
    value: CounterpartRoleEnum.Enterprise,
  },
  {
    label: 'Сотрудник',
    value: CounterpartRoleEnum.Employee,
  },
  {
    label: 'Холдинг',
    value: CounterpartRoleEnum.Holding,
  },
  {
    label: 'Поставщик',
    value: CounterpartRoleEnum.Supplier,
  },
  {
    label: 'Бывший сотрудник',
    value: CounterpartRoleEnum.FormerEmployee,
  },
  {
    label: 'Бывший поставщик',
    value: CounterpartRoleEnum.FormerSupplier,
  },
  {
    label: 'Представитель',
    value: CounterpartRoleEnum.Representative,
  },
  {
    label: 'Перевозчик',
    value: CounterpartRoleEnum.Carrier,
  },
  {
    label: 'Retail',
    value: CounterpartRoleEnum.Representative,
  },
];

export const createAgentFormInitialValues = {
  email: '',
  role: '',
  firstName: '',
  lastName: '',
  birthDate: null,
  sex: '',
  phone: '',
  postCode: '123232',
  subscribeForNews: false,
  socialMedia: [],
  discount: '',
  isEmployee: false,
};

export enum FormFields {
  Avatar = 'avatar',
  BirthDate = 'birthDate',
  Brand = 'brand',
  Model = 'model',
  City = 'city',
  Discount = 'discount',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  IsEmployee = 'isEmployee',
  LastName = 'lastName',
  Email = 'email',
  Password = 'password',
  Phone = 'phone',
  PostCode = 'postCode',
  Role = 'role',
  SalaryFormula = 'salaryFormula',
  Sex = 'sex',
  SocialMedia = 'socialMedia',
  SubscribeForNews = 'subscribeForNews',
  Subdivision = 'subdivision',
  UserGroup = 'userGroup',
  Website = 'website',
}
