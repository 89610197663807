import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { TypographyProps } from './types';

const Typography = ({ children, variant }: PropsWithChildren<TypographyProps>) => (
  <div className={cn(styles.text, styles[`text--${variant}`])}>{children}</div>
);

export default Typography;
