const IconArrowRightLong = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.6204 12.5467C9.49374 12.5467 9.36707 12.5 9.26707 12.4C9.07374 12.2067 9.07374 11.8867 9.26707 11.6933L12.9604 7.99999L9.26707 4.30665C9.07374 4.11332 9.07374 3.79332 9.26707 3.59999C9.4604 3.40665 9.7804 3.40665 9.97374 3.59999L14.0204 7.64665C14.2137 7.83999 14.2137 8.15999 14.0204 8.35332L9.97374 12.4C9.87374 12.5 9.74707 12.5467 9.6204 12.5467Z"
      fill="#5570F1"
    />
    <path
      d="M13.553 8.5H2.33301C2.05967 8.5 1.83301 8.27333 1.83301 8C1.83301 7.72667 2.05967 7.5 2.33301 7.5H13.553C13.8263 7.5 14.053 7.72667 14.053 8C14.053 8.27333 13.8263 8.5 13.553 8.5Z"
      fill="#5570F1"
    />
  </svg>
);

export default IconArrowRightLong;
