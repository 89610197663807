import type { Dayjs } from 'dayjs';

import dayjs from '@/utils/dayjs';

export enum CalendarRange {
  Day = 'day',
  Month = 'month',
  Week = 'week',
}

export const calendarRanges = [
  { label: CalendarRange.Day, value: 'День' },
  { label: CalendarRange.Week, value: 'Неделя' },
  { label: CalendarRange.Month, value: 'Месяц' },
];

export const getRangeValues = (
  range: CalendarRange,
  startDate = dayjs(),
  endDate = dayjs(),
): { format: string; label: string; endDate?: Dayjs; values: Dayjs[] } => {
  switch (range) {
    case CalendarRange.Month: {
      startDate = startDate.startOf('month');
      const daysInMonth = startDate.daysInMonth();

      const values = [];

      for (let i = 0; i < daysInMonth; i++) {
        values.push(startDate.add(i, 'day'));
      }

      return {
        format: 'DD.MM',
        label: 'Дата',
        values,
      };
    }

    case CalendarRange.Week: {
      startDate = startDate.startOf('week');

      const values = [];

      for (let i = 0; i < 7; i++) {
        values.push(startDate.add(i, 'day'));
      }

      return {
        format: 'DD.MM dddd',
        label: 'Дата',
        values,
      };
    }

    default: {
      startDate = startDate.set('hour', 9).set('minutes', 0).set('seconds', 0);
      endDate = startDate.endOf('day').set('hour', 18).set('minutes', 0).set('seconds', 0);

      const values = [];

      for (let i = startDate; i.isBefore(endDate); i = i.add(1, 'hour')) {
        values.push(i);
      }

      return {
        format: 'HH:mm',
        label: 'Время',
        endDate,
        values,
      };
    }
  }
};
