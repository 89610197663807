import * as Yup from 'yup';

import type { ResetPasswordFormData } from '../../types';

import * as messages from '@/utils/validationMessages';

export const resetPasswordFormSchema: Yup.ObjectSchema<ResetPasswordFormData> = Yup.object().shape({
  password: Yup.string().required(messages.required),
  recoveryToken: Yup.string().required(messages.required),
});
