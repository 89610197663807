import { useNavigate } from 'react-router';

import WarehouseTransferTable from '../WarehouseTransferTable';

import styles from './styles.module.scss';

import Header from '@/components/Header';
import IconAdd from '@/components/Icons/IconAdd';
import Button from '@/components/UI/Button';
import { PATHS } from '@/constants';

const WarehouseTransferView = () => {
  const navigate = useNavigate();

  const goToWarehouseTransferCreate = () => {
    navigate(PATHS.TRANSFER_CREATE);
  };

  return (
    <div className={styles.container}>
      <Header
        actions={
          <>
            <Button onClick={goToWarehouseTransferCreate} endIcon={IconAdd}>
              Перемещения
            </Button>
          </>
        }
        showSearch={false}
        title="Перемещения"
      />
      <WarehouseTransferTable />
    </div>
  );
};

export default WarehouseTransferView;
