import styles from './styles.module.scss';

import IconAdd from '@/components/Icons/IconAdd';
import Button from '@/components/UI/Button';
import Table from '@/components/UI/Table';

const AgentsDealsListTable = () => (
  <Table
    className={styles.table}
    header={
      <Table.Row>
        <Table.Cell>Номер</Table.Cell>
        <Table.Cell>Контакт</Table.Cell>
        <Table.Cell>Сумма в сделке</Table.Cell>
        <Table.Cell>Статус оплаты</Table.Cell>
        <Table.Cell>Сегмент сделки</Table.Cell>
        <Table.Cell>Ответственный</Table.Cell>
      </Table.Row>
    }
    footer={
      <div className={styles.tableFooter}>
        <Button endIcon={IconAdd} variant="secondary">
          Сделка
        </Button>
      </div>
    }
  >
    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
      <Table.Row key={i}>
        <Table.Cell>7483</Table.Cell>
        <Table.Cell>Звездилин Вадим Анатольевич</Table.Cell>
        <Table.Cell>0,00 ₽</Table.Cell>
        <Table.Cell>Полная оплата</Table.Cell>
        <Table.Cell>Розница</Table.Cell>
        <Table.Cell>Ажиненко Д.</Table.Cell>
      </Table.Row>
    ))}
  </Table>
);

export default AgentsDealsListTable;
