export enum SettingsTab {
  Directory = 'directory',
  Coefficient = 'coefficient',
  MarkupForWork = 'markupForWork',
  MarkupForMaterial = 'markupForMaterial',
  IPAddresses = 'IPAddresses',
  Subdivision = 'subdivision',
  Services = 'services',
}

export const settingsTabs = [
  {
    label: 'Информация о компании',
    value: SettingsTab.Subdivision,
  },
  {
    label: 'Автосервисы',
    value: SettingsTab.Services,
  },
  {
    label: 'Разрешенные IP адреса',
    value: SettingsTab.IPAddresses,
  },
  {
    label: 'Наценки на работы',
    value: SettingsTab.MarkupForWork,
  },
  {
    label: 'Наценки на запчасти и материалы',
    value: SettingsTab.MarkupForMaterial,
  },
  {
    label: 'Коэффициент на  авто к н.ч',
    value: SettingsTab.Coefficient,
  },
];
