import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { isString } from 'lodash';
import { AutoComplete } from 'rsuite';

import styles from './styles.module.scss';
import type { OrderProductsProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconLoading from '@/components/Icons/IconLoading';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Table from '@/components/UI/Table';
import { OrderProduct } from '@/features/Order/components/OrderProducts/OrderProduct';
import { ProductOrderModal } from '@/features/Product';
import { fetchSearchProducts } from '@/features/Product/services';
import type { ProductEntity } from '@/features/Product/types';
import { useDebounce } from '@/hooks';

const OrderProducts = ({ order }: OrderProductsProps) => {
  const [search, setSearch] = useState<string>();
  const debounceSearch = useDebounce(search, 300);
  const [isFetching] = useState(false);
  const [products, setProducts] = useState<ProductEntity[]>([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<string>('');

  const handleOpenShowForm = useCallback(() => {
    if (order?.client) {
      setShowProductModal(true);
    } else {
      toast.warning('Необходимо добавить клиента');
    }
  }, [order?.client]);

  const handleHideProductModal = useCallback(() => {
    setShowProductModal(false);
  }, []);

  const handleSearchProducts = async (query: string) => {
    if (!isString(query)) return;

    try {
      const data = await fetchSearchProducts(query);

      setProducts(
        data?.map((item: ProductEntity) => ({
          value: item._id,
          label: item.title,
        })),
      );
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    }
  };

  const handleAddProduct = async (product: string) => {
    setSelectedProduct(product);
    handleOpenShowForm();
    setSearch('');
    // setIsFetching(true);
    //
    // try {
    //   await updateOrder(order?._id as string, {
    //     products: order?.products
    //       ?.map((product) => ({ stock: product.stock?._id }))
    //       .concat({ stock: product }),
    //   });
    //
    //   setProducts([]);
    //   triggerEvent(EVENTS.REFRESH_DATA);
    //   toast.success("Товар успешно добавлен в сделку");
    // } catch (err) {
    //   if (isAxiosError(err)) {
    //     toast.error(err?.response?.data?.error || err?.response?.data?.message);
    //   }
    // } finally {
    //   setIsFetching(false);
    // }
  };

  useEffect(() => {
    if (!debounceSearch?.trim().length) return;
    handleSearchProducts(debounceSearch);
  }, [debounceSearch]);

  return (
    <>
      <div className={styles.products}>
        <Flex alignItems="center" justifyContent="flex-start" columnGap="xs">
          <span className={styles.productsLabel}>Товары</span>
          {isFetching ? (
            <div className={styles.autocomplete}>
              <IconLoading />
            </div>
          ) : (
            <div>
              <AutoComplete
                data={products}
                onChange={setSearch}
                onSelect={handleAddProduct}
                placeholder="Поиск"
                className={styles.autocomplete}
              />
            </div>
          )}
          <Button onClick={handleOpenShowForm} variant="secondary">
            Склад
          </Button>
        </Flex>
        <If condition={Boolean(order?.products?.length)}>
          <Table
            className={styles.table}
            header={
              <Table.Row>
                <Table.Cell>ID товара</Table.Cell>
                <Table.Cell>Название товара</Table.Cell>
                <Table.Cell>Ответственный</Table.Cell>
                <Table.Cell>Закупочная цена</Table.Cell>
                <Table.Cell>Розничная цена</Table.Cell>
                <Table.Cell>Скидка</Table.Cell>
                <Table.Cell>Сумма</Table.Cell>
              </Table.Row>
            }
          >
            {order?.products.map((product, index) => (
              <OrderProduct key={product._id} index={index} order={order} product={product} />
            ))}
          </Table>
        </If>
      </div>

      <If condition={showProductModal}>
        <ProductOrderModal
          isOpen={showProductModal}
          onClose={handleHideProductModal}
          order={order}
          id={selectedProduct}
        />
      </If>
    </>
  );
};

export default OrderProducts;
