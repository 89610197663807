import Meta from '@/components/Meta';
import { WarehouseView } from '@/features/Warehouse';

const Warehouse = () => (
  <>
    <Meta title="Склад" />
    <WarehouseView />
  </>
);

export default Warehouse;
