import type { OrderJobEntity } from '@/features/Order/types';

export const normalizeJobs = (jobs: OrderJobEntity[], isJob = true) => {
  const data = [] as OrderJobEntity[];

  jobs.forEach((job) => {
    if (isJob) {
      job.job.isJob = true;
    }

    data.push(job);

    if (job.additionalJobs?.length) {
      data.push(...normalizeJobs(job.additionalJobs as OrderJobEntity[], false));
    }
  });

  return data;
};
