import { CounterpartRoleEnum } from '@/types';

export const agentRepresentativeInitialValues = {
  email: '',
  role: CounterpartRoleEnum.Supplier,
  firstName: '',
  lastName: '',
  birthDate: null,
  sex: '',
  phone: '',
  postAddress: '',
  subscribeForNews: false,
  socialMedia: [],
  discount: 0,
  isEmployee: false,
};
