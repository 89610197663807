import { columns } from './constants';

import TableBuilder from '@/components/TableBuilder';
import { EVENTS } from '@/constants';
import { warehouseArrivalSerialize } from '@/features/Warehouse/serializer';
import { getWarehouseTransfer } from '@/features/Warehouse/services';
import type { WarehouseArrivalEntity } from '@/features/Warehouse/types';
import { useEventListener, useRequest } from '@/hooks';

const WarehouseTransferTable = () => {
  const { data, isFetching, fetchData, totalPages, pageNumber, pageSize, totalItems } =
    useRequest<WarehouseArrivalEntity>(getWarehouseTransfer, warehouseArrivalSerialize);

  useEventListener(EVENTS.REFRESH_DATA, fetchData);

  return (
    <TableBuilder
      isFetching={isFetching}
      columns={columns}
      data={data as WarehouseArrivalEntity[]}
      pageNumber={pageNumber}
      pageSize={pageSize}
      handlePageChange={() => null}
      totalItems={totalItems}
      totalPages={totalPages}
    />
  );
};

export default WarehouseTransferTable;
