import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { SectionProps } from './types';

const Section = ({ children, className, padding = 'lg' }: PropsWithChildren<SectionProps>) => (
  <section className={cn(styles.section, styles[`padding-${padding}`], className)}>{children}</section>
);

export default Section;
