import type { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';

import type { UnauthorizedLayoutProps } from './types';

import IconLogo from '@/components/Icons/IconLogo';

const UnauthorizedLayout = ({ children }: PropsWithChildren<UnauthorizedLayoutProps>) => (
  <div className="grid grid-rows-[48px_1fr] md:grid-rows-[64px_1fr] gap-6 lg:gap-[48px]">
    <header className="flex items-center lg:px-6 px-4 border-b border-bright-secondary">
      <NavLink to="/">
        <IconLogo />
      </NavLink>
    </header>
    <main className="h-full px-4 lg:flex lg:items-center lg:justify-center">{children}</main>
  </div>
);

export default UnauthorizedLayout;
