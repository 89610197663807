import type { OrderEntity } from '@/features/Order/types';
import type { CounterpartAccountEntity } from '@/types';

export type CallEntity = {
  _id: string;
  type: CallTypeEnum;
  order: OrderEntity;
  manager: CounterpartAccountEntity;
  UTM: string;
  callId: string;
  callExternalId: string;
  date: Date;
  duration: number;
};

export enum CallTypeEnum {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export type CallsEntity = CallEntity[];
