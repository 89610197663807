import { useState } from 'react';

import WarehouseFormModal from '../WarehouseFormModal';
import WarehouseTable from '../WarehouseTable';

import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import Header from '@/components/Header';
import IconAdd from '@/components/Icons/IconAdd';
import Button from '@/components/UI/Button';

const WarehouseView = () => {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className={styles.container}>
      <Header
        actions={
          <>
            <Button onClick={handleShowForm} endIcon={IconAdd}>
              Склад
            </Button>
          </>
        }
        showSearch={false}
        title="Склад"
      />
      <WarehouseTable />
      <If condition={showForm}>
        <WarehouseFormModal open={showForm} onClose={handleCloseForm} />
      </If>
    </div>
  );
};

export default WarehouseView;
