import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';

import { FormFields, initialValues } from './constants';
import { paymentFormSchema } from './schema';
import styles from './styles.module.scss';
import type { PaymentFormProps } from './types';

import Button from '@/components/UI/Button';
import DatePicker from '@/components/UI/DatePicker';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import InputNumber from '@/components/UI/InputNumber';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Select from '@/components/UI/Select';
import Textarea from '@/components/UI/Textarea';
import { EVENTS } from '@/constants';
import { createPayment } from '@/features/Payment/service';
import type { Payment } from '@/features/Payment/types';
import { getWallets } from '@/features/Wallet/service';
import type { WalletEntity } from '@/features/Wallet/types';
import { dispatchEvent } from '@/utils/event';

const PaymentFormModal = ({ isOpen, onClose, paymentType }: PaymentFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [payment, setPayment] = useState(() => initialValues);
  const [wallets, setWallets] = useState<WalletEntity[]>([]);

  useEffect(() => {
    paymentType && setPayment((prev) => ({ ...prev, paymentType }));
  }, [paymentType]);

  useEffect(() => {
    getWallets({
      pageSize: 100,
    }).then((data) => {
      setWallets(data?.items);
    });
  }, []);

  const handleCreatePayment = async (values: Payment) => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      await createPayment(values);
      toast.success('Новое ДДС добавлено');
      dispatchEvent(EVENTS.REFRESH_DATA);
      onClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Formik
        // @ts-ignore
        initialValues={payment}
        onSubmit={handleCreatePayment}
        enableReinitialize
        validateOnChange={false}
        validationSchema={paymentFormSchema}
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
          <Form className={styles.form} onSubmit={handleSubmit}>
            <ModalHeader onClose={onClose}>
              <h2>Новое ДДС</h2>
            </ModalHeader>
            <ModalBody>
              <Grid>
                <Grid.Item col={12}>
                  <p className={styles.formDescription}>
                    Укажите какую сумму денежных средств вы приходуете в организацию и на какой счет-баланс?
                  </p>
                </Grid.Item>
                <Grid.Item col={6}>
                  <Select
                    error={errors[FormFields.From]}
                    name={FormFields.From}
                    label="Плательщик"
                    placeholder="Выберите из списка"
                    onChange={(e) => {
                      setFieldValue(FormFields.From, e?.value);
                    }}
                    options={wallets.map((wallet) => ({
                      label: wallet.ownerTitle,
                      value: wallet._id,
                    }))}
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <Select
                    error={errors[FormFields.To]}
                    name={FormFields.To}
                    label="На какой баланс"
                    placeholder="Выберите из списка"
                    onChange={(e) => {
                      setFieldValue(FormFields.To, e?.value);
                    }}
                    options={wallets.map((wallet) => ({
                      label: wallet.ownerTitle,
                      value: wallet._id,
                    }))}
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <DatePicker
                    error={errors[FormFields.AccountingDate] as string}
                    name={FormFields.AccountingDate}
                    maxDate={new Date()}
                    label="Дата операции"
                    onChange={(value: Date | null) => {
                      setFieldValue(FormFields.AccountingDate, value ? new Date(value) : null);
                    }}
                    date={values[FormFields.AccountingDate]}
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <InputNumber
                    error={errors.amount}
                    name={FormFields.Amount}
                    label="Сумма"
                    placeholder="Введите сумму"
                    onChange={handleChange}
                    value={values.amount}
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <div className={styles.box}>
                    <p className={styles.boxLabel}>Остаток на балансе до операции:</p>
                    <div className={styles.boxValue}>
                      {Number(wallets.find((wallet) => wallet._id === String(values[FormFields.From]))?.balance || 0)} ₽
                    </div>
                  </div>
                </Grid.Item>
                <Grid.Item col={6}>
                  <div className={styles.box}>
                    <p className={styles.boxLabel}>Изменение за сегодня:</p>
                    <div className={styles.boxValue}>
                      {Number(
                        wallets.find((wallet) => wallet._id === String(values[FormFields.From]))?.todayChange || 0,
                      )}{' '}
                      {' ₽'}
                    </div>
                  </div>
                </Grid.Item>
                <Grid.Item col={12}>
                  <Textarea
                    label="Комментарий"
                    onChange={handleChange}
                    name={FormFields.Comment}
                    maxLength={300}
                    placeholder="Введите текст комментария"
                    value={values.comment}
                  />
                </Grid.Item>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Flex alignItems="center">
                <Button isLoading={isLoading} type="submit">
                  Сохранить
                </Button>
                <Button disabled={isLoading} onClick={onClose} variant="secondary">
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PaymentFormModal;
