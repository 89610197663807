import { useMemo } from 'react';

import styles from './styles.module.scss';
import type { PaymentDetailModalProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import type { PaymentTypeEnum } from '@/features/Payment/constants';
import { paymentTypes } from '@/features/Payment/constants';
import { getBaseDate, getDateTime } from '@/utils/date';

const PaymentDetailModal = ({ onClose, payment }: PaymentDetailModalProps) => {
  const showModal = useMemo(() => Boolean(payment?._id), [payment?._id]);
  return (
    <Modal className={styles.modal} isOpen={showModal}>
      <ModalHeader onClose={onClose}>
        <Flex alignItems="center">
          <h2>Транзакция ДДС</h2>
          <If condition={Boolean(payment?.paymentType)}>
            <div>{paymentTypes[payment?.paymentType as PaymentTypeEnum]}</div>
          </If>
          <div className={styles.createdBy}>
            Создал:
            <br />
            {payment?.createdBy}, {getDateTime(payment?.createdAt)}
          </div>
        </Flex>
      </ModalHeader>
      <ModalBody>
        <Grid>
          <Grid.Item col={6}>
            <div className={styles.items}>
              <div className={styles.item}>
                <span className={styles.itemLabel}>Плательщик</span>
                <span className={styles.itemValue}>{payment?.from?.ownerTitle}</span>
              </div>
              <div className={styles.item}>
                <span className={styles.itemLabel}>На какой баланс</span>
                <span className={styles.itemValue}>{payment?.to?.ownerTitle}</span>
              </div>
              <div className={styles.item}>
                <span className={styles.itemLabel}>Дата операции</span>
                <span className={styles.itemValue}>{getBaseDate(payment?.accountingDate)}</span>
              </div>
              <div className={styles.item}>
                <span className={styles.itemLabel}>Сумма транзакции</span>
                <span className={styles.itemValue}>{payment?.amount} ₽</span>
              </div>
              <If condition={Boolean(payment?.comment?.trim().length)}>
                <div className={styles.item}>
                  <span className={styles.itemLabel}>Комментарий</span>
                  <p className={styles.itemValue}>{payment?.comment}</p>
                </div>
              </If>
            </div>
          </Grid.Item>
          <Grid.Item col={6}>
            <h3 className={styles.title}>Связанные документы</h3>
          </Grid.Item>
        </Grid>
      </ModalBody>
    </Modal>
  );
};

export default PaymentDetailModal;
