import { useMutation } from 'react-query';
import { loginUser } from '@/pages/Auth/service';
import { setUser } from '@/store/slices/userSlice';
import { setAuthToken } from '@/api';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@/store';

export const useAuthForm = () => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: ['auth'],
    mutationFn: loginUser,
    onError: () => {
      toast.error('Ошибка авторизации');
    },
    onSuccess: (user) => {
      dispatch(setUser(user));
      setAuthToken(user.token as string);
      toast.success('Вы вошли в систему');
    },
  });
};
