import { useMemo } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import PrivateRoute from '@/components/PrivateRoute';
import { pages, PATHS } from '@/constants';
import UnauthorizedLayout from '@/layouts/UnauthorizedLayout';
import { Auth, ResetPassword } from '@/pages';
import { useAppSelector } from '@/store';
import { selectIsAuthenticated } from '@/store/slices/userSlice';

const Routes = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const renderPages = useMemo(
    () =>
      pages.map(({ Component, path, isPrivate }) => {
        const Wrapper = isPrivate ? PrivateRoute : UnauthorizedLayout;

        return (
          <Route
            key={path}
            path={path}
            element={
              <Wrapper>
                <Component />
              </Wrapper>
            }
          />
        );
      }),
    [pages],
  );

  return (
    <ReactRoutes>
      {renderPages}
      {!isAuthenticated && (
        <>
          <Route
            path={PATHS.AUTH}
            element={
              <UnauthorizedLayout>
                <Auth />
              </UnauthorizedLayout>
            }
          />
          <Route
            path={PATHS.RESET_PASSWORD}
            element={
              <UnauthorizedLayout>
                <ResetPassword />
              </UnauthorizedLayout>
            }
          />
        </>
      )}
    </ReactRoutes>
  );
};

export default Routes;
