import { forwardRef } from 'react';

import cx from 'classnames';

import styles from './styles.module.scss';
import type { TextareaProps } from './types';

import IconClose from '@/components/Icons/IconClose';

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      value,
      invalid = false,
      disabled = false,
      errorMessageId = '',
      descriptionId,
      label,
      maxLength = 0,
      className,
      onClear,
      ...props
    },
    ref,
  ) => (
    <div>
      {Boolean(label) && <label className={styles.label}>{label}</label>}
      <div className={styles.container}>
        {onClear && (
          <button className={styles.button} onClick={onClear}>
            <IconClose />
          </button>
        )}

        <textarea
          {...props}
          ref={ref}
          className={cx(
            styles.textarea,
            invalid && styles['textarea-invalid'],
            disabled && styles['textarea-disabled'],
            className,
          )}
          aria-invalid={invalid}
          aria-errormessage={errorMessageId}
          aria-describedby={descriptionId}
          maxLength={maxLength}
          value={value}
        />
        {maxLength > 0 && (
          <div className={cx(styles.chars, disabled && styles['chars-disabled'])}>
            {`${value?.length || 0} / ${maxLength}`}
          </div>
        )}
      </div>
    </div>
  ),
);

export default Textarea;
