import { useState } from 'react';

import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';

import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import ErrorMessage from '@/components/ErrorMessage';
import IconAdd from '@/components/Icons/IconAdd';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import { AgentSocialMediaForm } from '@/features/Agent';
import AgentSocialMediaList from '@/features/Agent/components/AgentSocialMediaList';
import type { IndividualAgent, IterableEntity } from '@/types';

const AgentSocialMedia = () => {
  const [socialMediaItems, setSocialMediaItems] = useState<IterableEntity[]>([]);
  const { errors, values, setErrors } = useFormikContext<IndividualAgent>();

  const handlePushItem = () => {
    setErrors({
      ...errors,
      socialMedia: [],
    });

    setSocialMediaItems((prev) =>
      prev.concat({
        _id: uuid(),
      }),
    );
  };

  const handleRemoveItem = (_id: string) => {
    setSocialMediaItems((prev) => prev.filter((item) => item._id !== _id));
  };

  return (
    <div>
      <Flex alignItems="center" justifyContent="space-between">
        <h2 className={styles.title}>Добавить социальную сеть</h2>
        <Button endIcon={IconAdd} onClick={handlePushItem} variant="empty-primary">
          Соц. сеть
        </Button>
      </Flex>

      <If condition={Boolean(errors?.socialMedia?.length)}>
        <ErrorMessage message="Вы не добавили ни одной социальной сети" />
      </If>

      <If condition={Boolean(socialMediaItems.length)}>
        <div className={styles.socialMediaItems}>
          {socialMediaItems.map((item) => (
            <AgentSocialMediaForm
              key={item._id}
              _id={item._id}
              index={values.socialMedia.length}
              onRemove={handleRemoveItem}
            />
          ))}
        </div>
      </If>
      <If condition={!isEmpty(values.socialMedia)}>
        <div className={styles.socialMediaItems}>
          <AgentSocialMediaList />
        </div>
      </If>
    </div>
  );
};

export default AgentSocialMedia;
