import type { CounterpartAccountEntity } from '@/features/Order/types';
import type { WarehouseAddressEntity, WarehouseEntity } from '@/features/Warehouse/types';
import type { FileEntity } from '@/types';

export interface GroupProductEntity {
  _id?: string;
  title: string;
  brand: string;
  count: number;
  article: string;
  amount?: number;
  buyingPrice?: number;
  price: number;
  pricePerItem: number;
  pricePerVolume: number;
  barcode: string;
  avatar: FileEntity;
  sellProductAsVolume: boolean;
  productVolume: ProductVolume[];
  vat: ProductVatEnum;
  manufacturerCountry: string;
  items: ProductEntity[];
}

export interface ProductEntity {
  _id?: string;
  title: string;
  brand: string;
  article: string;
  amount?: number;
  buyingPrice?: number;
  price: number;
  pricePerItem: number;
  pricePerVolume: number;
  barcode: string;
  avatar: FileEntity;
  sellProductAsVolume: boolean;
  productVolume: ProductVolume[];
  vat: ProductVatEnum;
  manufacturerCountry: string;
}

export interface ProductVolume {
  units: ProductVolumeUnits;
  amount: number;
}

export enum ProductVatEnum {
  NoVat = 'no-vat',
  Eighteen = '18%',
  Ten = '10%',
  Twenty = '20%',
}

export enum ProductVolumeUnits {
  Cm = 'cm',
  Dm = 'dm',
  M = 'm',
}

export interface OrderProductEntity {
  _id?: string;
  id?: string;
  stock?: ProductStockEntity;
  count?: number;
  createdAt: string;
  updatedAt: string;
  totalPrice?: number;
  totalPriceWithDiscount?: number;
  responsible?: CounterpartAccountEntity;
  discount?: number;
  price?: number;
  buyingPrice?: number;
  priceWithDiscount?: number;
}

export interface ProductStockGroupEntity {
  _id: string;
  groupAvailable: number;
  groupProduct: ProductEntity;
  groupReserved: number;
  groupTotal: number;
  groupStock: ProductStockEntity[];
  groupWarehouse: WarehouseEntity;
}

export interface ProductStockEntity {
  _id: string;
  address: WarehouseAddressEntity;
  product: ProductEntity;
  stock: number;
  reserved: number;
  minStock: number;
  buyingPrice: number;
  count?: number;
}
