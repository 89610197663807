import { useNavigate } from 'react-router';

import AgentsListTable from './components/AgentsListTable';
import styles from './styles.module.scss';

import Header from '@/components/Header';
import IconAdd from '@/components/Icons/IconAdd';
import Meta from '@/components/Meta';
import Button from '@/components/UI/Button';
import { AgentType } from '@/features/Agent/constants';

const Agents = () => {
  const navigate = useNavigate();

  const handleCreateAgent = () => {
    navigate(`/agents/create/${AgentType.Individual}`);
  };

  return (
    <div className={styles.container}>
      <Meta title="Контрагенты" />
      <Header
        actions={
          <Button onClick={handleCreateAgent} endIcon={IconAdd}>
            Контрагент
          </Button>
        }
        showSearch={false}
        title="Контрагенты"
      />
      <AgentsListTable />
    </div>
  );
};

export default Agents;
