import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { ModalBodyProps } from './types';

const ModalBody = ({ children, className }: PropsWithChildren<ModalBodyProps>) => (
  <div className={cn(styles.modalBody, className)}>{children}</div>
);

export default ModalBody;
