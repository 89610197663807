import type { PropsWithChildren } from 'react';

import { arrayMoveImmutable } from 'array-move';
import cn from 'classnames';
import { isNumber } from 'lodash';
import { Dropdown } from 'rsuite';

import styles from './styles.module.scss';
import type { TableCellProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconArrowDown from '@/components/Icons/IconArrowDown';
import IconButton from '@/components/UI/IconButton';
import { EVENTS } from '@/constants';
import { ORDER_TABLE_COLUMNS } from '@/features/Order/components/OrderTableList/constants';
import { useCustomSearchParams } from '@/hooks';
import { triggerEvent } from '@/utils/event';
import { getStorage, setStorage } from '@/utils/storage';

const TableCell = ({
  index,
  children,
  className,
  colSpan,
  isLastCell,
  name,
  sortable = false,
  width,
}: PropsWithChildren<TableCellProps>) => {
  const [params, setParams] = useCustomSearchParams();
  const style: Record<string, unknown> = {
    maxWidth: width || 'auto',
  };

  if (isNumber(index)) {
    style.zIndex = 2 + index;
  }

  const handleSortable = (value: string) => {
    if (['moveToLeft', 'moveToRight'].includes(value)) {
      const columns = getStorage(ORDER_TABLE_COLUMNS, []);
      const newValue = value === 'moveToLeft' ? Number(index) - 1 : Number(index) + 1;
      setStorage(ORDER_TABLE_COLUMNS, arrayMoveImmutable(columns, Number(index), newValue));
      triggerEvent(EVENTS.UPDATE_COLUMNS_FROM_STORAGE);
    }

    if (['asc', 'desc'].includes(value)) {
      // @ts-ignore
      setParams({
        ...params,
        sortField: name,
        sortValue: value,
      });
    }
  };

  return (
    <td className={cn(styles.cell, className)} colSpan={colSpan} style={style}>
      <div className={styles.cellInner}>
        {children}
        <If condition={sortable}>
          <Dropdown
            renderToggle={renderButton}
            title="Dropdown"
            className={styles.dropdown}
            placement="bottomEnd"
            onSelect={handleSortable}
          >
            <Dropdown.Item eventKey="pin">Закрепить в начале</Dropdown.Item>
            {Number(index) > 0 && <Dropdown.Item eventKey="moveToLeft">Переместить влево</Dropdown.Item>}
            {!isLastCell && <Dropdown.Item eventKey="moveToRight">Переместить вправо</Dropdown.Item>}
            <Dropdown.Item eventKey="asc">Сортировка, A-Z</Dropdown.Item>
            <Dropdown.Item eventKey="desc">Сортировка, Z-A</Dropdown.Item>
          </Dropdown>
        </If>
      </div>
    </td>
  );
};

const renderButton = (props: any, ref: any) => (
  <IconButton className="p-0 hover:bg-bright-tertiary" variant="empty" {...props} ref={ref} icon={IconArrowDown} />
);

export default TableCell;
