import { Scrollbar } from 'react-scrollbars-custom';

import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';

import { If } from '@/components/ConditionalRendering/If';
import IconAdd from '@/components/Icons/IconAdd';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Table from '@/components/UI/Table';
import WarehouseAddressForm from '@/features/Warehouse/components/WarehouseAddressForm';
import styles from '@/features/Warehouse/components/WarehouseFormModal/styles.module.scss';
import type { WarehouseEntity } from '@/features/Warehouse/types';

const WarehouseAddresses = () => {
  const { setValues, values } = useFormikContext<WarehouseEntity>();

  const handleAddAddress = () => {
    setValues({
      ...values,
      warehouseAddresses: (values.warehouseAddresses || []).concat({
        // @ts-ignore
        _id: uuid(),
        title: '',
        type: null,
        floors: null,
        rowsPerFloor: null,
        racksPerRow: null,
        shelvesPerRack: null,
        cellsPerShelf: null,
      }),
    });
  };

  const handleRemoveAddress = (_id: string) => {
    setValues({
      ...values,
      warehouseAddresses: (values.warehouseAddresses || []).filter((item) => item._id !== _id),
    });
  };

  const renderAddresses = () =>
    values.warehouseAddresses?.map((address, index) => (
      <WarehouseAddressForm index={index} key={address._id} id={address._id as string} onRemove={handleRemoveAddress} />
    ));

  return (
    <Flex flexDirection="column" rowGap="md">
      <h3 className={styles.title}>Адреса на складе</h3>
      <div>
        <Button onClick={handleAddAddress} endIcon={IconAdd} variant="secondary">
          Добавить адрес
        </Button>
      </div>
      <Scrollbar style={{ height: 300 }}>
        <If condition={Boolean(values.warehouseAddresses?.length)}>
          <Table
            className={styles.table}
            header={
              <Table.Row>
                <Table.Cell>Название адреса на складе </Table.Cell>
                <Table.Cell>Вид адреса</Table.Cell>
                <Table.Cell>Этажей склада</Table.Cell>
                <Table.Cell>Рядов на этаже</Table.Cell>
                <Table.Cell>Стеллажей в ряду</Table.Cell>
                <Table.Cell>Полок в стеллаже</Table.Cell>
                <Table.Cell>Ячеек на полке</Table.Cell>
              </Table.Row>
            }
          >
            {renderAddresses()}
          </Table>
        </If>
      </Scrollbar>
    </Flex>
  );
};

export default WarehouseAddresses;
