import dayjs from '@/utils/dayjs';

export const getBaseDate = (input?: string | Date | null) => {
  if (!input) {
    return null;
  }

  return dayjs(input).format('DD MMMM YYYY');
};

export const getYearsDiff = (d1: Date, d2: Date) => {
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);

  return Math.floor(date1.diff(date2, 'year', true));
};

export const parseDate = (input?: string | null) => {
  if (!input) {
    return null;
  }

  return dayjs(input).startOf('day').toDate();
};

export const getDateTime = (input?: string | null | undefined | Date) => {
  if (!input) {
    return null;
  }
  const optionsDate = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const optionsTime = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const date = new Date(input);

  const formattedDate = date.toLocaleDateString('en-GB', optionsDate as any).replace(/\//g, '.');
  const formattedTime = date.toLocaleTimeString('en-GB', optionsTime as any);

  return `${formattedDate}, ${formattedTime}`;
};
