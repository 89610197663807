import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';

import type { OrderProductProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconCheck from '@/components/Icons/IconCheck';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import InputNumber from '@/components/UI/InputNumber';
import Table from '@/components/UI/Table';
import { EVENTS } from '@/constants';
import styles from '@/features/Order/components/OrderProducts/styles.module.scss';
import { updateOrder } from '@/features/Order/services';
import { ProductOrderModal } from '@/features/Product';
import type { OrderProductEntity } from '@/features/Product/types';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import { triggerEvent } from '@/utils/event';

export const OrderProduct = ({ index, order, product }: OrderProductProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [discount, setDiscount] = useState<number>(Number(product?.discount as number));
  const [price, setPrice] = useState<number>(product?.price as number);

  const handleHideProductModal = useCallback(() => {
    setShowProductModal(false);
  }, []);

  const handleUpdateOrder = async (field: string, value: number) => {
    // @ts-ignore
    (order?.products[index] as OrderProductEntity)[field] = value as number;

    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        products: order?.products.map(({ _id, id, createdAt, updatedAt, priceWithDiscount, ...product }) => ({
          ...product,
          stock: product.stock?._id,
        })),
      });

      toast.success('Сделка успешно изменена');
      triggerEvent(EVENTS.REFRESH_DATA);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <Table.Row className={styles.tableRow} key={product._id}>
        <Table.Cell>{product._id}</Table.Cell>
        <Table.Cell>{product.stock?.product?.title}</Table.Cell>
        <Table.Cell>{getAgentFullName(order?.responsible?.counterpartProfile)}</Table.Cell>
        <Table.Cell>{product.stock?.buyingPrice || 0} ₽</Table.Cell>
        <Table.Cell className={styles.tableCell}>
          <Flex columnGap="sm">
            <InputNumber
              onChange={(e) => setPrice(Number(e.currentTarget.value))}
              placeholder="Введите розничную цену"
              value={price}
            />
            <IconButton
              disabled={isFetching}
              icon={IconCheck}
              onClick={() => handleUpdateOrder('price', price)}
              variant="primary"
            />
          </Flex>
        </Table.Cell>
        <Table.Cell className={styles.tableCell}>
          <Flex columnGap="sm">
            <InputNumber
              onChange={(e) => setDiscount(Number(e.currentTarget.value))}
              placeholder="Введите скидку"
              value={discount}
              min={0}
              max={100}
              maxLength={3}
            />
            <IconButton
              disabled={isFetching}
              icon={IconCheck}
              onClick={() => handleUpdateOrder('discount', discount)}
              variant="primary"
            />
          </Flex>
        </Table.Cell>
        <Table.Cell>{product.priceWithDiscount || 0} ₽</Table.Cell>
      </Table.Row>

      <If condition={showProductModal}>
        <ProductOrderModal id={product?._id} isOpen={showProductModal} onClose={handleHideProductModal} order={order} />
      </If>
    </>
  );
};
