import type { CheckCanUploadInput } from './types';

export const checkCanUpload = ({ accept, item, file }: CheckCanUploadInput) => {
  if (!item && !file) {
    return false;
  }

  if (item && item.kind !== 'file') {
    return false;
  }

  if (!accept) {
    return true;
  }

  const mimeType = file?.type || item?.type || '';
  if (mimeType === 'application/x-moz-file') {
    return true;
  }

  const acceptItems = accept.split(',').map((d) => d.trim());

  return acceptItems.some((extensionOrMime) => {
    if (mimeType === extensionOrMime) {
      return true;
    }

    if (file && file.name.endsWith(extensionOrMime)) {
      return true;
    }

    const acceptedMimeRegex = new RegExp(extensionOrMime);
    if (acceptedMimeRegex.test(mimeType)) {
      return true;
    }

    return false;
  });
};
