import Meta from '@/components/Meta';
import JobView from '@/features/Job/components/JobView';

const Jobs = () => (
  <>
    <Meta title="Каталог работ" />
    <JobView />
  </>
);

export default Jobs;
