import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { ContainerProps } from './types';

const Container = ({ center, className, children }: PropsWithChildren<ContainerProps>) => (
  <div className={cn(styles.container, center && styles.containerCenter, className)}>{children}</div>
);

export default Container;
