const IconBriefcase = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3332 18.9583H6.66653C2.81653 18.9583 2.09986 17.1667 1.91653 15.425L1.29153 8.75C1.19986 7.875 1.17486 6.58333 2.04153 5.61666C2.79153 4.78333 4.0332 4.38333 5.8332 4.38333H14.1665C15.9749 4.38333 17.2165 4.79166 17.9582 5.61666C18.8249 6.58333 18.7999 7.875 18.7082 8.75833L18.0832 15.4167C17.8999 17.1667 17.1832 18.9583 13.3332 18.9583ZM5.8332 5.625C4.42486 5.625 3.4582 5.9 2.96653 6.45C2.5582 6.9 2.42486 7.59166 2.5332 8.625L3.1582 15.3C3.29986 16.6167 3.6582 17.7083 6.66653 17.7083H13.3332C16.3332 17.7083 16.6999 16.6167 16.8415 15.2917L17.4665 8.63333C17.5749 7.59166 17.4415 6.9 17.0332 6.45C16.5415 5.9 15.5749 5.625 14.1665 5.625H5.8332Z"
      fill="white"
    />
    <path
      d="M13.3337 5.62499C12.992 5.62499 12.7087 5.34166 12.7087 4.99999V4.33332C12.7087 2.84999 12.7087 2.29166 10.667 2.29166H9.33366C7.29199 2.29166 7.29199 2.84999 7.29199 4.33332V4.99999C7.29199 5.34166 7.00866 5.62499 6.66699 5.62499C6.32533 5.62499 6.04199 5.34166 6.04199 4.99999V4.33332C6.04199 2.86666 6.04199 1.04166 9.33366 1.04166H10.667C13.9587 1.04166 13.9587 2.86666 13.9587 4.33332V4.99999C13.9587 5.34166 13.6753 5.62499 13.3337 5.62499Z"
      fill="white"
    />
    <path
      d="M9.99967 13.9583C7.70801 13.9583 7.70801 12.5417 7.70801 11.6917V10.8333C7.70801 9.65833 7.99134 9.375 9.16634 9.375H10.833C12.008 9.375 12.2913 9.65833 12.2913 10.8333V11.6667C12.2913 12.5333 12.2913 13.9583 9.99967 13.9583ZM8.95801 10.625C8.95801 10.6917 8.95801 10.7667 8.95801 10.8333V11.6917C8.95801 12.55 8.95801 12.7083 9.99967 12.7083C11.0413 12.7083 11.0413 12.575 11.0413 11.6833V10.8333C11.0413 10.7667 11.0413 10.6917 11.0413 10.625C10.9747 10.625 10.8997 10.625 10.833 10.625H9.16634C9.09967 10.625 9.02467 10.625 8.95801 10.625Z"
      fill="white"
    />
    <path
      d="M11.6663 12.3083C11.3579 12.3083 11.0829 12.075 11.0496 11.7583C11.0079 11.4167 11.2496 11.1 11.5913 11.0583C13.7913 10.7833 15.8996 9.95001 17.6746 8.65834C17.9496 8.45001 18.3413 8.51667 18.5496 8.80001C18.7496 9.07501 18.6913 9.46667 18.4079 9.67501C16.4579 11.0917 14.1579 12 11.7413 12.3083C11.7163 12.3083 11.6913 12.3083 11.6663 12.3083Z"
      fill="white"
    />
    <path
      d="M8.33296 12.3167C8.30796 12.3167 8.28296 12.3167 8.25796 12.3167C5.97463 12.0583 3.74963 11.225 1.82463 9.90833C1.54129 9.71667 1.46629 9.325 1.65796 9.04167C1.84963 8.75833 2.24129 8.68333 2.52463 8.875C4.28296 10.075 6.30796 10.8333 8.39129 11.075C8.73296 11.1167 8.98296 11.425 8.94129 11.7667C8.91629 12.0833 8.64963 12.3167 8.33296 12.3167Z"
      fill="white"
    />
  </svg>
);

export default IconBriefcase;
