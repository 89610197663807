import { useLocation } from 'react-router-dom';

import type { PageTitleProps } from './types';

const PageTitle = ({ title }: PageTitleProps) => {
  const { state } = useLocation();

  if (title || state?.title) {
    return <h1 className="m-0 text-md font-medium">{title || state.title}</h1>;
  }

  return null;
};

export default PageTitle;
