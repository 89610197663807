export const menu = [
  {
    label: 'Поставщик',
  },
  {
    label: 'Клиент',
  },
  {
    label: 'Касса',
  },
  {
    label: 'Сотрудник',
  },
  {
    label: 'Юридическое лицо',
  },
];
