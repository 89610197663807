import { useFormikContext } from 'formik';

import Input from '@/components/UI/Input';
import { FormFields } from '@/features/Agent/components/AgentForm/constants';
import type { IndividualAgent } from '@/types';

const AgentEmail = () => {
  const {
    errors,
    handleChange,
    values: { email },
  } = useFormikContext<IndividualAgent>();

  return (
    <Input
      error={errors[FormFields.Email]}
      label="E-mail"
      name={FormFields.Email}
      onChange={handleChange}
      placeholder="Введите e-mail"
      value={email}
    />
  );
};

export default AgentEmail;
