import styles from './styles.module.scss';

import Meta from '@/components/Meta';
import { PATHS } from '@/constants';
import WarehouseArrivalForm from '@/features/Warehouse/components/WarehouseArrivalForm';
import Header from '@/pages/CreateAgent/components/Header';

const WarehouseArrivalDetail = () => (
  <div className={styles.content}>
    <Meta title="Склад - Поступления - Редактирование поступления" />
    <Header
      breadcrumbs={[{ label: 'Поступления', path: PATHS.ARRIVAL }, { label: 'Редактирование поступления' }]}
      title="Редактирование поступления"
    />
    <WarehouseArrivalForm />
  </div>
);

export default WarehouseArrivalDetail;
