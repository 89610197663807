import type { PropsWithChildren } from 'react';

import type { AppLayoutProps } from './types';

import { BurgerMenu } from '@/components/BurgerMenu';
import Logo from '@/components/Logo';
import Sidebar from '@/components/Sidebar';
import AgentWelcomeForm from '@/features/Agent/components/AgentWelcomeForm';

const AppLayout = ({ children }: PropsWithChildren<AppLayoutProps>) => (
  <>
    <div className="grid h-full md:grid-cols-[216px_1fr] grid-rows-[64px_1fr] md:grid-rows-none">
      <header className="flex md:hidden bg-dark-primary justify-between px-2">
        <Logo />
        <BurgerMenu />
      </header>
      <div className="md:grid hidden">
        <Sidebar />
      </div>
      <div className="h-full">{children}</div>
    </div>
    <AgentWelcomeForm />
  </>
);

export default AppLayout;
