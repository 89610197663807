const IconVk = () => (
  <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vk">
      <path
        id="bg"
        d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16Z"
        fill="white"
      />
      <g id="vk_2">
        <path
          id="Vector"
          d="M8 15.68C8 12.0587 8 10.2507 9.12 9.12C10.256 8 12.064 8 15.68 8H16.32C19.9413 8 21.7493 8 22.88 9.12C24 10.256 24 12.064 24 15.68V16.32C24 19.9413 24 21.7493 22.88 22.88C21.744 24 19.936 24 16.32 24H15.68C12.0587 24 10.2507 24 9.12 22.88C8 21.744 8 19.936 8 16.32V15.68Z"
          fill="#0077FF"
        />
        <path
          id="Vector_2"
          d="M16.5121 19.5253C12.8641 19.5253 10.7841 17.0293 10.6987 12.8693H12.5334C12.5921 15.92 13.9361 17.2107 15.0027 17.4773V12.8693H16.7254V15.4987C17.7761 15.3867 18.8854 14.1867 19.2587 12.864H20.9761C20.836 13.5487 20.5563 14.1971 20.1544 14.7688C19.7525 15.3405 19.2369 15.8232 18.6401 16.1867C19.3062 16.5182 19.8944 16.9872 20.366 17.5627C20.8376 18.1382 21.1819 18.8071 21.3761 19.5253H19.4827C19.0774 18.2613 18.0641 17.28 16.7254 17.1467V19.5253H16.5174H16.5121Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

export default IconVk;
