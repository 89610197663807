import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import AgentBankAccounts from './components/AgentBankAccounts';
import AgentCalendar from './components/AgentCalendar';
import AgentCallsListTable from './components/AgentCallsListTable';
import AgentNotes from './components/AgentNotes';
import AgentsDealsListTable from './components/AgentsDealsListTable';
import AgentsOrganizationsListTable from './components/AgentsOrganizationsListTable';
import Header from './components/Header';
import { tabs } from './constants';
import styles from './styles.module.scss';

import Container from '@/components/Container';
import PageLoader from '@/components/PageLoader';
import Tabs from '@/components/UI/Tabs';
import { PATHS } from '@/constants';
import { AgentForm } from '@/features/Agent';
import { fetchAgentById } from '@/pages/AgentDetail/service';
import { AgentDetailTabs } from '@/pages/AgentDetail/types';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import type { CounterpartTypeEnum, IndividualAgent, LegalAgent } from '@/types';

const AgentDetail = () => {
  const { agentId, agentType } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<AgentDetailTabs>(AgentDetailTabs.Main);
  const [agent, setAgent] = useState<LegalAgent | IndividualAgent>();

  useEffect(() => {
    fetchAgentById(String(agentId), agentType as CounterpartTypeEnum).then((res) => {
      setAgent(res);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={styles.content}>
      <Header
        breadcrumbs={[{ label: 'Контрагенты', path: PATHS.AGENTS }, { label: 'Просмотр контрагента' }]}
        title={getAgentFullName(agent as IndividualAgent)}
      />
      <Container className={styles.container} center>
        <Tabs value={currentTab} onSelect={setCurrentTab}>
          {tabs.map(({ label, value }) => (
            <Tabs.Item key={value} value={value}>
              {label}
            </Tabs.Item>
          ))}
        </Tabs>
      </Container>

      {currentTab === AgentDetailTabs.Main && (
        <AgentForm agentData={agent} agentType={agentType as CounterpartTypeEnum} isEdit />
      )}
      {currentTab === AgentDetailTabs.Organizations && <AgentsOrganizationsListTable />}
      {currentTab === AgentDetailTabs.Notes && <AgentNotes />}
      {currentTab === AgentDetailTabs.Calendar && <AgentCalendar />}
      {currentTab === AgentDetailTabs.Calls && <AgentCallsListTable />}
      {currentTab === AgentDetailTabs.Deals && <AgentsDealsListTable />}
      {currentTab === AgentDetailTabs.BankAccounts && <AgentBankAccounts />}
    </div>
  );
};

export default AgentDetail;
