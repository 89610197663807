import { useState } from 'react';
import { toast } from 'react-toastify';

import { AutoComplete } from 'rsuite';

import { OrderCreateIndividualAgentModal } from './OrderCreateIndividualAgentModal';
import type { OrderIndividualAgentFormProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import IconAdd from '@/components/Icons/IconAdd';
import IconEdit from '@/components/Icons/IconEdit';
import IconLoading from '@/components/Icons/IconLoading';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import IconButton from '@/components/UI/IconButton';
import { EVENTS } from '@/constants';
import { fetchSearchAgents } from '@/features/Agent/service';
import styles from '@/features/Order/components/OrderForm/styles.module.scss';
import { updateOrder } from '@/features/Order/services';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import type { Agent, IndividualAgent, UserType } from '@/types';
import { triggerEvent } from '@/utils/event';

export const OrderIndividualAgentForm = ({ client, order }: OrderIndividualAgentFormProps) => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAgentFormModal, setShowAgentFormModal] = useState(false);

  const handleSetAgent = async (client: string) => {
    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        client,
      });
      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success('Клиент добавлен');
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  const handleSearchAgents = async (query: string) => {
    try {
      const data = await fetchSearchAgents(query);

      setAgents(
        data?.map((item: UserType) => ({
          value: item._id,
          label: getAgentFullName(item.counterpartProfile),
        })),
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Grid.Item className={styles.bottomRow} col={3}>
        {isFetching ? (
          <IconLoading />
        ) : client ? (
          <Flex flexDirection="column" rowGap="zero" justifyContent="center" className={styles.textRow}>
            <Flex alignItems="center" className="text-primary" columnGap="zero">
              {getAgentFullName(order?.client?.counterpartProfile as IndividualAgent)}
              <IconButton icon={IconEdit} onClick={() => setShowAgentFormModal(true)} />
            </Flex>
            <span>{order?.client?.counterpartProfile?.phone}</span>
          </Flex>
        ) : (
          <Flex columnGap="xxs">
            <Flex flexDirection="column" rowGap="xxs" className={styles.textRow}>
              <AutoComplete
                data={agents}
                onSelect={handleSetAgent}
                onChange={handleSearchAgents}
                placeholder="ФИО клиента"
              />
              <Button
                className={styles.smallBtn}
                endIcon={() => <IconAdd size={16} />}
                onClick={() => setShowAgentFormModal(true)}
                variant="empty-primary"
              >
                Новый контрагент
              </Button>
            </Flex>
          </Flex>
        )}
      </Grid.Item>

      <If condition={showAgentFormModal}>
        <OrderCreateIndividualAgentModal
          agent={order?.client?.counterpartProfile!}
          isOpen={true}
          onClose={() => setShowAgentFormModal(false)}
        />
      </If>
    </>
  );
};
