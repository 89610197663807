import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@/store';
import type { UserType } from '@/types';

interface UserState {
  isAuthenticated: boolean | null;
  user: UserType | null;
}

const initialState: UserState = {
  isAuthenticated: null,
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserType>) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    resetUser(state) {
      state.isAuthenticated = null;
      state.user = null;
    },
  },
});

export const selectUser = (state: RootState) => state.user.user;

export const selectIsAuthenticated = (state: RootState) => state.user.isAuthenticated;

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
