import cn from 'classnames';

import styles from './styles.module.scss';
import type { OrderStatusesItemProps } from './types';

import { OrderStatusEnum } from '@/features/Order/types';

const OrderStatusesItem = ({ isActive = false, label, status, onClick }: OrderStatusesItemProps) => (
  <li>
    <button
      className={cn(
        styles.statusesBtn,
        status === OrderStatusEnum.Success && styles.statusesBtnGreen,
        status === OrderStatusEnum.Junk && styles.statusesBtnRed,
        status === OrderStatusEnum.Declined && styles.statusesBtnGray,
        isActive && styles.statusesBtnActive,
      )}
      disabled={isActive}
      onClick={() => onClick(status)}
    >
      {label}
    </button>
  </li>
);

export default OrderStatusesItem;
