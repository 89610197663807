import type { WarehouseArrivalEntity, WarehouseArrivalProduct, WarehouseEntity } from './types';

import { getAgentFullName } from '@/pages/AgentDetail/utils';
import type { CarServiceSubdivisionEntity, CounterpartAccountEntity, ResponseFromServer, UserType } from '@/types';
import { getBaseDate } from '@/utils/date';

export const warehouseArrivalSerialize = (data: ResponseFromServer<WarehouseArrivalEntity>) => ({
  items: data.items.map((item) => ({
    ...item,
    accountingDate: getBaseDate(item.accountingDate) as string,
    createdAt: getBaseDate(item.createdAt) as string,
    responsible: getAgentFullName((item.responsible as CounterpartAccountEntity)?.counterpartProfile as UserType),
    subdivision: (item.subdivision as CarServiceSubdivisionEntity)?.title,
    supplier: getAgentFullName((item.supplier as CounterpartAccountEntity)?.counterpartProfile) as string,
    products: (item.products as WarehouseArrivalProduct[])?.length as number,
    warehouse: (item.warehouse as WarehouseEntity)?.title as string,
  })),
  pagination: {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    totalItems: data.totalItems,
    totalPages: data.totalPages,
  },
});

export const warehouseSerialize = (data: ResponseFromServer<WarehouseEntity>) => ({
  items: data.items,
  pagination: {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    totalItems: data.totalItems,
    totalPages: data.totalPages,
  },
});
