import SalaryList from './components/SalaryList';

import Header from '@/components/Header';
import Meta from '@/components/Meta';

const WorkSchedule = () => (
  <div>
    <Meta title="Рабочий график" />
    <Header />
    <SalaryList />
  </div>
);

export default WorkSchedule;
