import { useState } from 'react';
import { toast } from 'react-toastify';

import type { AxiosError } from 'axios';
import { Form, Formik } from 'formik';

import { resetPasswordFormSchema } from './schema';
import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Input from '@/components/UI/Input';
import { requestPasswordReset } from '@/features/Auth/service';
import type { RequestResetPasswordFormData } from '@/features/Auth/types';
import { FormFields } from '@/pages/Auth/constants';
import { getErrorMessage } from '@/utils/error';

const initialValues = {
  email: '',
};

const ResetPasswordForm = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isReseted, setIsReseted] = useState(false);

  const handleResetPassword = async (values: RequestResetPasswordFormData) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await requestPasswordReset(values);
      setIsReseted(true);
      toast.success('Код успешно сброшен');
    } catch (err) {
      toast.error(getErrorMessage(err as AxiosError));
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleResetPassword}
        validationSchema={resetPasswordFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange }) => (
          <Form className={styles.form}>
            <h1 className="text-md lg:text-xxl m-0 font-medium">Код для сброса пароля</h1>
            <If condition={isReseted}>
              <p className={styles.formDescription}>
                На почту <span className={styles.formEmail}>{values.email}</span> отправлен <br /> код подтверждения для
                сброса пароля
              </p>
            </If>
            <Input
              error={errors.email}
              name={FormFields.Email}
              type="text"
              disabled={isFetching || isReseted}
              placeholder="Логин"
              onChange={handleChange}
              value={values.email}
            />
            <If condition={!isReseted}>
              <Flex columnGap="sm">
                <Button
                  className="whitespace-nowrap text-sm lg:w-1/2 w-full"
                  disabled={isFetching}
                  isLoading={isFetching}
                  type="submit"
                >
                  Подтвердить
                </Button>
              </Flex>
            </If>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
