import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';
import type { BreadcrumbProps } from './types';

import IconArrowRight from '@/components/Icons/IconArrowRight';

const Breadcrumbs = ({ items }: BreadcrumbProps) => {
  const renderBreadcrumbs = () =>
    items.map(({ label, path }, index) => (
      <li className={styles.breadcrumbsItem} key={index}>
        {path ? (
          <NavLink className={styles.breadcrumbsLink} to={path}>
            {label}
            <IconArrowRight />
          </NavLink>
        ) : (
          <span className={styles.breadcrumbsText}>{label}</span>
        )}
      </li>
    ));

  return (
    <nav aria-label="Breadcrumb" className={styles.breadcrumbs}>
      <ol className={styles.breadcrumbsList}>{renderBreadcrumbs()}</ol>
    </nav>
  );
};

export default Breadcrumbs;
