import api from '@/api';
import type { IndividualAgent, LegalAgent } from '@/types';
import { CounterpartTypeEnum } from '@/types';

export const fetchAgentById = async (id: string, type: CounterpartTypeEnum) => {
  const url = type === CounterpartTypeEnum.Individual ? '/individual-counterpart' : '/legal-counterpart';

  const response = await api.get<LegalAgent | IndividualAgent>(`${url}/${id}`);
  return response.data;
};
