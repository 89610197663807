import styles from './styles.module.scss';
import type { OrderCarDetailFormModalProps } from './types';
import { OrderCarFormModalProps } from './types';

import Modal from '@/components/UI/Modal';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import CarForm from '@/features/Car/components/CarForm';

export const OrderCarDetailFormModal = ({ car, isOpen, onClose }: OrderCarDetailFormModalProps) => (
  <Modal className={styles.modal} isOpen={isOpen}>
    <ModalHeader onClose={onClose}>
      <h2>Toyota</h2>
    </ModalHeader>
    <CarForm id="1" car={car} onRemove={() => null} onSuccess={() => null} showTitle={false} />
  </Modal>
);
