export enum AgentType {
  Legal = 'legal',
  Individual = 'individual',
}

export const agentTypes = {
  [AgentType.Individual]: 'Физическое лицо',
  [AgentType.Legal]: 'Юридическое лицо',
};

export const agentFormTitles = {
  [AgentType.Individual]: 'Добавить новое физ. лицо',
  [AgentType.Legal]: 'Добавить новое юр. лицо',
};
