import type { EVENTS } from '@/constants';
import { useOnce } from '@/hooks';

const useEventListener = (event: EVENTS, handler: (params: unknown) => void) => {
  useOnce(() => {
    document.addEventListener(event, handler);

    return () => document.removeEventListener(event, handler);
  });
};

export default useEventListener;
