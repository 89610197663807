const IconArrowLeft = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0509 13.5667C10.8926 13.5667 10.7342 13.5083 10.6092 13.3833L7.66758 10.4417C7.42591 10.2 7.42591 9.80001 7.66758 9.55834L10.6092 6.61667C10.8509 6.37501 11.2509 6.37501 11.4926 6.61667C11.7342 6.85834 11.7342 7.25834 11.4926 7.50001L8.99258 10L11.4926 12.5C11.7342 12.7417 11.7342 13.1417 11.4926 13.3833C11.3759 13.5083 11.2176 13.5667 11.0509 13.5667Z"
      fill="currentColor"
    />
  </svg>
);

export default IconArrowLeft;
