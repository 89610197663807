import * as Yup from 'yup';

import { FormFields } from './constants';

import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const postSchema: Yup.ObjectSchema<CarEntity> = Yup.object().shape({
  [FormFields.Title]: Yup.string().required(messages.required),
});
