import AuthForm from './components/AuthForm';

import Meta from '@/components/Meta';

const Auth = () => (
  <>
    <Meta title="Авторизация" />
    <AuthForm />
  </>
);

export default Auth;
