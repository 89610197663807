import { useState } from 'react';

import { useFormikContext } from 'formik';

import Grid from '@/components/UI/Grid';
import Input from '@/components/UI/Input';
import Section from '@/components/UI/Section';
import Select from '@/components/UI/Select';
import { FormFields } from '@/features/Agent/components/AgentForm/constants';
import { getSalaryFormulas } from '@/features/Salary/services';
import type { SalaryFormulaEntity } from '@/features/Salary/types';
import { useOnce } from '@/hooks';
import { useAppProvider } from '@/providers/AppProvider/hooks';
import type { IndividualAgent } from '@/types';

const AgentEmployeeForm = () => {
  const { subdivisions, userGroups } = useAppProvider();
  const { setFieldValue } = useFormikContext<IndividualAgent>();
  const [salaryFormulas] = useState<SalaryFormulaEntity[]>([]);

  useOnce(() => {
    getSalaryFormulas().then(() => {});
  });

  return (
    <Section>
      <Grid>
        <Grid.Item col={4}>
          <Input label="Почта" placeholder="Почта" type="email" />
        </Grid.Item>
        <Grid.Item col={4}>
          <Select
            name={FormFields.Subdivision}
            label="Подразделение"
            options={subdivisions.map((subdivision) => ({
              label: subdivision.title,
              value: subdivision._id,
            }))}
            onChange={(e) => {
              setFieldValue(FormFields.Subdivision, e?.value);
            }}
          />
        </Grid.Item>
        <Grid.Item col={4}>
          <Select
            name={FormFields.UserGroup}
            options={userGroups.map((userGroup) => ({
              label: userGroup.title,
              value: userGroup._id,
            }))}
            onChange={(e) => {
              setFieldValue(FormFields.UserGroup, e?.value);
            }}
            label="Группа пользователя"
          />
        </Grid.Item>
      </Grid>
      <Select
        name={FormFields.SalaryFormula}
        options={salaryFormulas.map((salaryFormula) => ({
          label: salaryFormula.title,
          value: salaryFormula._id,
        }))}
        onChange={(e) => {
          setFieldValue(FormFields.SalaryFormula, e?.value);
        }}
        label="Стандартная схема мотиваций расчета"
      />
    </Section>
  );
};

export default AgentEmployeeForm;
