import * as Yup from 'yup';

import { FormFields } from './constants';

import type { ProductEntity } from '@/features/Product/types';
import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const productFormSchema: Yup.ObjectSchema<ProductEntity> = Yup.object().shape({
  [FormFields.Article]: Yup.string().trim().required(messages.required),
  [FormFields.Title]: Yup.string().trim().required(messages.required),
  [FormFields.Brand]: Yup.string().trim().required(messages.required),
  [FormFields.ManufacturerCountry]: Yup.string().trim().required(messages.required),
  [FormFields.Title]: Yup.string().trim().required(messages.required),
});
