const IconEye = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99993 13.1083C8.28441 13.1083 6.8916 11.7155 6.8916 9.99993C6.8916 8.28441 8.28441 6.8916 9.99993 6.8916C11.7155 6.8916 13.1083 8.28441 13.1083 9.99993C13.1083 11.7155 11.7155 13.1083 9.99993 13.1083ZM9.99993 7.1416C8.42379 7.1416 7.1416 8.42379 7.1416 9.99993C7.1416 11.5761 8.42379 12.8583 9.99993 12.8583C11.5761 12.8583 12.8583 11.5761 12.8583 9.99993C12.8583 8.42379 11.5761 7.1416 9.99993 7.1416Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M10.0004 17.5167C6.86706 17.5167 3.90872 15.6834 1.87539 12.5001C0.992057 11.1251 0.992057 8.8834 1.87539 7.50007C3.91706 4.31673 6.87539 2.4834 10.0004 2.4834C13.1254 2.4834 16.0837 4.31673 18.1171 7.50007C19.0004 8.87507 19.0004 11.1167 18.1171 12.5001C16.0837 15.6834 13.1254 17.5167 10.0004 17.5167ZM10.0004 3.7334C7.30872 3.7334 4.73372 5.35007 2.93372 8.17507C2.30872 9.15007 2.30872 10.8501 2.93372 11.8251C4.73372 14.6501 7.30872 16.2667 10.0004 16.2667C12.6921 16.2667 15.2671 14.6501 17.0671 11.8251C17.6921 10.8501 17.6921 9.15007 17.0671 8.17507C15.2671 5.35007 12.6921 3.7334 10.0004 3.7334Z"
      fill="currentColor"
    />
  </svg>
);

export default IconEye;
