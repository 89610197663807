import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Scrollbar } from 'react-scrollbars-custom';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';
import Cars from 'src/features/Car/components/Cars';

import AgentEmail from '../AgentEmail';

import { FormFields, individualAgentFormInitialValues } from './constants';
import { agentFormSchema } from './schema';
import type { AgentIndividualFormProps } from './types';

import { If } from '@/components/ConditionalRendering/If';
import Container from '@/components/Container';
import LoadingIndicatorPage from '@/components/LoadingIndicatorPage';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import DatePicker from '@/components/UI/DatePicker';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import Input from '@/components/UI/Input';
import InputNumber from '@/components/UI/InputNumber';
import InputPhone from '@/components/UI/InputPhone';
import Radio from '@/components/UI/Radio';
import RadioGroup from '@/components/UI/RadioGroup';
import Select from '@/components/UI/Select';
import Switch from '@/components/UI/Switch';
import { PATHS } from '@/constants';
import AgentEmployeeForm from '@/features/Agent/components/AgentEmployeeForm';
import { agentsType } from '@/features/Agent/components/AgentForm/constants';
import styles from '@/features/Agent/components/AgentForm/styles.module.scss';
import { getAgentRole } from '@/features/Agent/components/AgentForm/utils';
import AgentRepresentative from '@/features/Agent/components/AgentRepresentative';
import AgentSocialMedia from '@/features/Agent/components/AgentSocialMedia';
import AvatarUpload from '@/features/Agent/components/AvatarUpload';
import { agentFormTitles, AgentType, agentTypes } from '@/features/Agent/constants';
import { createIndividualAgent, updateIndividualAgent } from '@/features/Agent/service';
import type { IndividualAgent } from '@/types';
import { SexEnum } from '@/types';

const AgentIndividualForm = ({ agentData, isEdit, showTitle = true }: AgentIndividualFormProps) => {
  const navigate = useNavigate();
  const [agent, setAgent] = useState<IndividualAgent>(
    // @ts-ignore
    individualAgentFormInitialValues,
  );
  const [isLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const goToAgentsListPage = () => navigate(PATHS.AGENTS);

  const changeAgentCreatePage = (agentType: AgentType) =>
    navigate(`/agents/create/${agentType}`, {
      state: {
        title: agentFormTitles[agentType],
      },
    });

  useEffect(() => {
    if (agentData) {
      setAgent(agentData);
    }
  }, [agentData]);

  const handleOnSubmit = async (values: IndividualAgent) => {
    if (isFetching) return;

    setIsFetching(true);

    const data = {
      ...values,
      cars: values.cars?.map((car) => car._id),
      representative: values.representative?.map((item) => item._id),
    };

    try {
      if (isEdit) {
        // @ts-ignore
        await updateIndividualAgent(String(agentData?._id), data);
        toast.success('Контрагент изменен');
      } else {
        // @ts-ignore
        await createIndividualAgent(data);
        toast.success('Контрагент добавлен');
      }

      goToAgentsListPage();
    } catch (err) {
      setIsFetching(false);

      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    }
  };

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <div className={styles.container}>
      <Formik
        initialValues={agent}
        enableReinitialize
        onSubmit={handleOnSubmit}
        validationSchema={agentFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className={styles.form}>
            <Scrollbar>
              <Container className={styles.formTop} center>
                <If condition={showTitle}>
                  <h2 className={styles.title}>Основная информация</h2>
                </If>
                <AvatarUpload
                  url={values.avatar?.link}
                  onUpload={(file) => {
                    setFieldValue(FormFields.Avatar, file._id);
                  }}
                />
                <Grid gap="sm">
                  <Grid.Item col={6}>
                    <Select
                      defaultValue={{
                        label: agentTypes[AgentType.Individual],
                        value: AgentType.Individual,
                      }}
                      label="Тип контрагента"
                      onChange={(e) => {
                        changeAgentCreatePage(e!.value);
                      }}
                      options={[
                        {
                          label: agentTypes[AgentType.Individual],
                          value: AgentType.Individual,
                        },
                        {
                          label: agentTypes[AgentType.Legal],
                          value: AgentType.Legal,
                        },
                      ]}
                      placeholder="Выберите из списка"
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Select
                      error={errors[FormFields.Role]}
                      label="Тип агента"
                      defaultValue={agentData ? { ...getAgentRole(agentData?.account?.role) } : null}
                      name={FormFields.Role}
                      onChange={(e) => {
                        setFieldValue(FormFields.Role, e?.value);
                      }}
                      placeholder="Выберите из списка"
                      options={agentsType}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      error={errors.firstName}
                      label="Имя"
                      maxLength={60}
                      onChange={handleChange}
                      name={FormFields.FirstName}
                      placeholder="Введите имя"
                      value={values.firstName}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      error={errors[FormFields.MiddleName]}
                      label="Отчество"
                      maxLength={60}
                      name={FormFields.MiddleName}
                      onChange={handleChange}
                      placeholder="Введите отчество"
                      value={values.middleName}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      error={errors[FormFields.LastName]}
                      label="Фамилия"
                      maxLength={60}
                      name={FormFields.LastName}
                      onChange={handleChange}
                      placeholder="Введите фамилию"
                      value={values.lastName}
                    />
                  </Grid.Item>
                </Grid>
                <Grid gap="sm">
                  <Grid.Item col={6}>
                    <DatePicker
                      error={errors[FormFields.BirthDate]}
                      name={FormFields.BirthDate}
                      maxDate={new Date()}
                      label="Дата рождения"
                      placeholder="Выберите дату рождения"
                      onChange={(value: Date | null) => {
                        setFieldValue(FormFields.BirthDate, value ? new Date(value) : null);
                      }}
                      date={values.birthDate}
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <RadioGroup
                      error={errors[FormFields.Sex]}
                      name={FormFields.Sex}
                      label="Пол"
                      value={values.sex}
                      onChange={(e) => setFieldValue(FormFields.Sex, e.target.value)}
                    >
                      <Radio label="Мужской" value={SexEnum.Male} />
                      <Radio label="Женский" value={SexEnum.Female} />
                    </RadioGroup>
                  </Grid.Item>
                </Grid>
                <Grid gap="sm">
                  <Grid.Item col={3}>
                    <InputPhone
                      error={errors[FormFields.Phone]}
                      label="Телефон"
                      name={FormFields.Phone}
                      onChange={handleChange}
                      placeholder="Введите телефон"
                      value={values.phone}
                    />
                  </Grid.Item>
                  <Grid.Item col={3}>
                    <AgentEmail />
                  </Grid.Item>
                  <Grid.Item col={3}>
                    <Input
                      error={errors[FormFields.PostAddress]}
                      label="Почтовый адрес"
                      name={FormFields.PostAddress}
                      onChange={handleChange}
                      placeholder="Введите почтовый адрес"
                      value={values[FormFields.PostAddress]}
                    />
                  </Grid.Item>
                  <Grid.Item col={3}>
                    <Input
                      error={errors[FormFields.Website]}
                      label="Сайт"
                      maxLength={80}
                      name={FormFields.Website}
                      onChange={handleChange}
                      placeholder="https://example.com"
                      value={values.website}
                    />
                  </Grid.Item>
                </Grid>

                <InputNumber
                  error={errors[FormFields.Discount]}
                  label="Скидка на работы/услуги"
                  name={FormFields.Discount}
                  placeholder="Введите размер скидки"
                  onChange={handleChange}
                  value={values.discount || 0}
                />
                <div className={styles.row}>
                  <Checkbox
                    checked={values.subscribeForNews}
                    name={FormFields.SubscribeForNews}
                    onChange={() => setFieldValue(FormFields.SubscribeForNews, !values.subscribeForNews)}
                    value="Согласие на рассылку"
                  />
                  <Switch
                    checked={values.isEmployee}
                    onChange={() => setFieldValue(FormFields.IsEmployee, !values.isEmployee)}
                    value="Является сотрудником организации"
                  />
                </div>
                <Flex className={styles.formFooter} rowGap="lg" flexDirection="column">
                  <If condition={Boolean(values.isEmployee)}>
                    <AgentEmployeeForm />
                  </If>
                  <AgentSocialMedia />
                  <Cars />
                  <AgentRepresentative title="Контактное лицо" />
                </Flex>
              </Container>
            </Scrollbar>

            <Flex className={styles.formActions} alignItems="center" columnGap="sm">
              <Button disabled={isFetching} isLoading={isFetching} type="submit">
                Сохранить
              </Button>
              <Button disabled={isFetching} onClick={goToAgentsListPage} variant="secondary">
                Закрыть
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AgentIndividualForm;
