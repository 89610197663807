import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import cn from 'classnames';
import { isString } from 'lodash';
import { AutoComplete } from 'rsuite';

import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import IconAdd from '@/components/Icons/IconAdd';
import IconArrowSubDown from '@/components/Icons/IconArrowSubDown';
import IconLoading from '@/components/Icons/IconLoading';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import Table from '@/components/UI/Table';
import { EVENTS } from '@/constants';
import type { JobEntity } from '@/features/Job/types';
import OrderCatalogJobModal from '@/features/Order/components/OrderCatalogJobModal';
import OrderJobForm from '@/features/Order/components/OrderJobForm';
import type { OrderJobsProps } from '@/features/Order/components/OrderJobs/types';
import { searchJobsByTitle, updateOrder } from '@/features/Order/services';
import { useDebounce } from '@/hooks';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import { triggerEvent } from '@/utils/event';

const OrderJobs = ({ order }: OrderJobsProps) => {
  const [search, setSearch] = useState<string>();
  const [showJobForm, setShowJobForm] = useState(false);
  const [showJobCatalogModal, setShowJobCatalogModal] = useState(false);
  const [jobs, setJobs] = useState<JobEntity[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const debounceSearch = useDebounce(search, 300);

  const handleCheckClientExist = useCallback(
    (cb: () => any) => {
      if (!order?.client) {
        toast.warning('Необходимо добавить клиента');
        return;
      }

      return cb();
    },
    [order?.client],
  );

  const handleOpenShowJobForm = useCallback(() => {
    setShowJobForm(true);
  }, []);

  const handleHideShowJobForm = useCallback(() => {
    setShowJobForm(false);
  }, []);

  const handleShowJobCatalogModal = useCallback(() => {
    setShowJobCatalogModal(true);
  }, []);

  const handleHideJobCatalogModal = useCallback(() => {
    setShowJobCatalogModal(false);
  }, []);

  const handleSearchJobs = async (query: string) => {
    if (!isString(query)) return;

    try {
      const data = await searchJobsByTitle(query);

      setJobs(
        data?.map((item: JobEntity) => ({
          value: item._id,
          label: item.title,
        })),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddJob = async (job: string) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        jobs: order?.jobs?.map((job) => ({ job: job?.job?._id })).concat({ job }),
      });

      setJobs([]);
      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success('Работа добавлена');
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (!debounceSearch?.trim().length) return;
    handleSearchJobs(debounceSearch);
  }, [debounceSearch]);

  return (
    <>
      <div className={styles.jobs}>
        <Grid className={styles.jobsTop}>
          <Grid.Item col={7}>
            <Flex alignItems="center" columnGap="xs">
              <span className={styles.jobsLabel}>Работы</span>
              {isFetching ? (
                <div className={styles.autocomplete}>
                  <IconLoading />
                </div>
              ) : (
                <AutoComplete
                  data={jobs}
                  onSelect={handleAddJob}
                  onChange={setSearch}
                  placeholder="Поиск"
                  className={styles.autocomplete}
                />
              )}
              <Button
                onClick={() => handleCheckClientExist(handleOpenShowJobForm)}
                endIcon={IconAdd}
                variant="secondary"
              >
                Создать
              </Button>
              <Button onClick={() => handleCheckClientExist(handleShowJobCatalogModal)} variant="secondary">
                Каталог работ
              </Button>
            </Flex>
          </Grid.Item>
        </Grid>
        <If condition={Boolean(order?.jobs?.length)}>
          <Table
            className={styles.table}
            header={
              <Table.Row>
                <Table.Cell>Название работы</Table.Cell>
                <Table.Cell>Исполнители/Ответственный</Table.Cell>
                <Table.Cell>Норма времени</Table.Cell>
                <Table.Cell>Цена 1 НЧ</Table.Cell>
                <Table.Cell>Без скидки</Table.Cell>
                <Table.Cell>Скидка</Table.Cell>
                <Table.Cell>Стоимость</Table.Cell>
              </Table.Row>
            }
          >
            {order?.jobs.map((job) => (
              <Table.Row key={job._id}>
                <Table.Cell>
                  <div className={cn(!job.job.isJob && styles.additionalJob)}>
                    <Flex columnGap="xxs">
                      <If condition={!job.job.isJob}>
                        <IconArrowSubDown />
                      </If>
                      {job?.job?.title}
                    </Flex>
                    <If condition={!job.job.isJob}>
                      <span className={styles.fixPrice}>Fix price</span>
                    </If>
                  </div>
                </Table.Cell>
                <Table.Cell>{getAgentFullName(job.responsible?.counterpartProfile)}</Table.Cell>
                <Table.Cell>{job?.job?.manHours}</Table.Cell>
                <Table.Cell>{job?.manHourPrice}</Table.Cell>
                <Table.Cell>{job?.priceWithDiscount}</Table.Cell>
                <Table.Cell>{job?.discount}</Table.Cell>
                <Table.Cell>{job?.price}</Table.Cell>
              </Table.Row>
            ))}
          </Table>
        </If>
      </div>

      <OrderJobForm isOpen={showJobForm} order={order} onClose={handleHideShowJobForm} />

      <OrderCatalogJobModal open={showJobCatalogModal} onClose={handleHideJobCatalogModal} order={order} />
    </>
  );
};

export default OrderJobs;
