import styles from './styles.module.scss';

import Meta from '@/components/Meta';
import { CalendarProvider, CalendarView } from '@/features/Calendar';

const Calendar = () => (
  <div className={styles.container}>
    <Meta title="Календарь" />
    <CalendarProvider>
      <CalendarView />
    </CalendarProvider>
  </div>
);

export default Calendar;
