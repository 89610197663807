import { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';

import { fetchAgents } from './service';
import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import Pagination from '@/components/UI/Pagination';
import Table from '@/components/UI/Table';
import { useAppProvider } from '@/providers/AppProvider/hooks';
import type { CounterpartAccountEntity } from '@/types';
import { getBaseDate } from '@/utils/date';

const AgentsListTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentSubdivision } = useAppProvider();
  const [agents, setAgents] = useState<CounterpartAccountEntity[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isFetching, setIsFetching] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const page = Number(queryParams.get('page')) || 1;
  const pageSize = Number(queryParams.get('pageSize')) || 20;

  const replaceQuery = () => {
    navigate({
      ...location,
      search: String(queryParams),
    });
  };

  const handleGetIndividualAgents = useCallback(() => {
    fetchAgents({
      pageNumber: page,
      pageSize,
    })
      .then((data) => {
        setAgents(data.items);
        setTotalPages(data.pagination.totalPages || 1);
        setTotalItems(data.pagination.totalItems || 0);
      })
      .catch(() => {
        setAgents([]);
        setTotalPages(1);
        setTotalItems(0);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [page, pageSize, currentSubdivision?._id]);

  useEffect(() => {
    setIsFetching(true);
    handleGetIndividualAgents();
  }, [page, pageSize, currentSubdivision?._id]);

  const handlePageChange = (nextPage: number) => {
    queryParams.set('page', String(nextPage));
    replaceQuery();
  };

  return (
    <>
      <Scrollbar>
        <Table
          className={styles.table}
          header={
            <Table.Row>
              <Table.Cell>Полное имя</Table.Cell>
              <Table.Cell>Номер телефона</Table.Cell>
              <Table.Cell>Дата рождения</Table.Cell>
              <Table.Cell>Размер скидки</Table.Cell>
              <Table.Cell>Баланс</Table.Cell>
              <Table.Cell>Оборот</Table.Cell>
            </Table.Row>
          }
          isEmpty={!agents.length}
          isFetching={isFetching}
        >
          {agents.map((agent) => (
            <Table.Row key={agent._id}>
              <Table.Cell>
                <NavLink
                  className={styles.tableUser}
                  to={`/agents/${agent.counterpartProfile?._id}/${agent.counterpartType}`}
                >
                  {agent.counterpartProfile?.firstName || 'Без имени'}
                </NavLink>
              </Table.Cell>
              <Table.Cell>{agent.counterpartProfile?.phone}</Table.Cell>
              <Table.Cell>{getBaseDate(agent.counterpartProfile?.birthDate)}</Table.Cell>
              <Table.Cell>
                {agent.counterpartProfile?.discount ? `${agent.counterpartProfile?.discount}%` : ''}
              </Table.Cell>
              <Table.Cell>{agent.wallet?.balance}</Table.Cell>
              <Table.Cell>{agent.wallet?.turnover}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Scrollbar>
      <If condition={Boolean(agents.length)}>
        <Pagination
          activePage={page}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>
    </>
  );
};

export default AgentsListTable;
