import { useParams } from 'react-router';

import Header from './components/Header';
import styles from './styles.module.scss';

import { PATHS } from '@/constants';
import { AgentForm } from '@/features/Agent';
import { CounterpartTypeEnum } from '@/types';

const CreateAgent = () => {
  const { agentType = CounterpartTypeEnum.Individual } = useParams<{
    agentType: CounterpartTypeEnum;
  }>();

  return (
    <div className={styles.content}>
      <Header
        breadcrumbs={[{ label: 'Контрагенты', path: PATHS.AGENTS }, { label: 'Новый контрагент' }]}
        title="Добавить контрагента"
      />
      <AgentForm agentType={agentType} />
    </div>
  );
};

export default CreateAgent;
