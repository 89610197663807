import { columns } from './constants';

import LoadingIndicatorPage from '@/components/LoadingIndicatorPage';
import TableBuilder from '@/components/TableBuilder';
import { EVENTS } from '@/constants';
import { warehouseArrivalSerialize } from '@/features/Warehouse/serializer';
import { getWarehouseArrival } from '@/features/Warehouse/services';
import type { WarehouseArrivalEntity } from '@/features/Warehouse/types';
import { useEventListener, useRequest } from '@/hooks';

const WarehouseArrivalTable = () => {
  const { data, isFetching, fetchData, totalPages, pageNumber, pageSize, totalItems } =
    useRequest<WarehouseArrivalEntity>(getWarehouseArrival, warehouseArrivalSerialize);

  useEventListener(EVENTS.REFRESH_DATA, fetchData);

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <TableBuilder
      columns={columns}
      data={data as WarehouseArrivalEntity[]}
      pageNumber={pageNumber}
      pageSize={pageSize}
      handlePageChange={() => null}
      totalItems={totalItems}
      totalPages={totalPages}
    />
  );
};

export default WarehouseArrivalTable;
