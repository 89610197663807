import * as Yup from 'yup';

import type { SocialMediaHandlerEntity } from '@/types';
import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const agentSocialMediaFormSchema: Yup.ObjectSchema<SocialMediaHandlerEntity> = Yup.object().shape({
  platform: Yup.string().required(messages.required),
  nickname: Yup.string().required(messages.required),
});
