import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { authFormSchema } from './schema';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Input from '@/components/UI/Input';
import InputPassword from '@/components/UI/InputPassword';
import { PATHS } from '@/constants';
import { authFormInitialValues, FormFields } from '@/pages/Auth/constants';
import { useAuthForm } from '../../hooks/useAuthForm';
import type { AuthFormData } from '@/pages/Auth/types';
import styles from './styles.module.scss';

const AuthForm = () => {
  const navigate = useNavigate();
  const { isLoading, mutateAsync: loginUser } = useAuthForm();

  const handleAuth = async (values: AuthFormData) => {
    await loginUser(values);
    navigate(PATHS.ORDER);
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={authFormInitialValues}
        onSubmit={handleAuth}
        validationSchema={authFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange }) => (
          <Form className="flex flex-col gap-4 md:min-w-[350px]">
            <h1 className="text-md lg:text-xxl m-0 font-medium">Авторизация</h1>
            <div className="p-4 text-sm bg-main-quinary rounded-lg border-l border-main-default text-bright-primary">
              Для входа в систему, вам необходимо
              <br /> использовать предоставленный пароль
            </div>
            <Input
              error={errors.email}
              name={FormFields.Email}
              type="text"
              placeholder="Логин"
              onChange={handleChange}
              value={values.email}
            />
            <InputPassword
              error={errors.password}
              name={FormFields.Password}
              placeholder="Пароль"
              onChange={handleChange}
              value={values.password}
            />
            <Flex className="flex-col md:flex-row md:items-center md:justify-center gap-3">
              <Button className="lg:w-1/2 whitespace-nowrap text-sm" isLoading={isLoading} type="submit">
                Авторизоваться
              </Button>
              <NavLink
                to={PATHS.RESET_PASSWORD}
                className="lg:w-1/2 h-10 bg-bright-secondary grid place-items-center px-2 rounded-xl whitespace-nowrap text-sm"
              >
                Восстановить пароль
              </NavLink>
            </Flex>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuthForm;
