import Meta from '@/components/Meta';
import { ResetPasswordForm } from '@/features/Auth';

const ResetPassword = () => (
  <>
    <Meta title="Восстановить пароль" />
    <ResetPasswordForm />
  </>
);

export default ResetPassword;
