import { WarehouseAddressTypeEnum } from '@/features/Warehouse/types';

export const warehouseAddresses = {
  [WarehouseAddressTypeEnum.PackingDesk]: 'Стол комплектации',
  [WarehouseAddressTypeEnum.ReceivingDesk]: 'Стол приемки',
  [WarehouseAddressTypeEnum.Wagon]: 'Телега',
  [WarehouseAddressTypeEnum.Shelf]: 'Полка',
  [WarehouseAddressTypeEnum.Cell]: 'Ячейка',
};

export enum FormFields {
  Address = 'address',
  CellsPerShelf = 'cellsPerShelf',
  Title = 'title',
  Section = 'section',
  WarehouseAddresses = 'warehouseAddresses',
  Floors = 'floors',
  RowsPerFloor = 'rowsPerFloor',
  RacksPerRow = 'racksPerRow',
  ShelvesPerRack = 'shelvesPerRack',
  Type = 'type',
}

export const initialValues = {
  [FormFields.Address]: '',
  [FormFields.Title]: '',
  [FormFields.WarehouseAddresses]: [],
};
