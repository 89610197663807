import styles from './styles.module.scss';

import Container from '@/components/Container';
import IconLoading from '@/components/Icons/IconLoading';

const LoadingIndicatorPage = () => (
  <Container className={styles.container} center>
    <IconLoading width={24} height={24} />
  </Container>
);

export default LoadingIndicatorPage;
