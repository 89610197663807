import * as Yup from 'yup';

import { FormFields } from './constants';

import type { BrandEntity } from '@/features/Brand/types';
import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const brandFormSchema: Yup.ObjectSchema<BrandEntity> = Yup.object().shape({
  [FormFields.Title]: Yup.string().trim().required(messages.required),
});
