import type { CarColorEnum } from '@/features/Car/types';
import type { JobEntity, OrderJobMaterialEntity } from '@/features/Job/types';
import type { Payment } from '@/features/Payment/types';
import type { OrderProductEntity } from '@/features/Product/types';
import type { CarServiceSubdivisionEntity } from '@/features/Subdivision/types';
import type { WalletEntity } from '@/features/Wallet/types';
import type {
  CarEngineTypeEnum,
  CarEntity,
  CounterpartRoleEnum,
  CounterpartTypeEnum,
  FileEntity,
  IndividualAgent,
  ResponseFromService,
} from '@/types';

export interface OrderEntity {
  _id: string;
  increment: number;
  status: OrderStatusEnum;
  closedAt: string;
  createdAt: string;
  jobsPriceWithDiscount: number;
  productsPriceWithDiscount: number;
  responsible: CounterpartAccountEntity;
  client: CounterpartAccountEntity | null;
  payer?: CounterpartAccountEntity;
  statusChanges: OrderStatusChange[];
  history: OrderHistoryEntry[];
  jobs: OrderJobEntity[];
  totalPrice: number;
  payments?: Payment[];
  totalPriceWithDiscount: number;
  products: OrderProductEntity[];
  createdBy: CounterpartAccountEntity;
  section: CarServiceSectionEntity;
  car: OrderCar;
  tags: string[];
  notes: OrderNoteEntity[];
}

export interface ServiceBay {
  _id?: string;
  title: string;
}

export interface CalendarGroup {
  _id: string;
  serviceBay: ServiceBay;
}

export interface KanbanEntity {
  status: OrderStatusEnum;
  items: OrderEntity[];
}

export interface OrderNoteEntity {
  _id?: string;
  text: string;
  attachments: FileEntity[];
}

export interface OrderCar {
  sourceCar: CarEntity;
  licensePlate: string;

  STS?: string;
  PTS?: string;
  ownerAddress?: string;
  ownerFIO?: string;
  owner: CounterpartAccountEntity;

  mileage?: number;
  color?: CarColorEnum;
  engineType?: CarEngineTypeEnum;

  maintenanceDate?: Date;
}

export interface CarServiceSectionEntity {
  _id: string;
  title: string;
  subdivision: CarServiceSubdivisionEntity;
}

export interface InnerOrderJobEntity {
  job: JobEntity;
  responsible: CounterpartAccountEntity;
  price: number;
  discount: number;
  manHours: number;
  manHourPrice: number;
  isFixedPayment: boolean;
  fixedPaymentAmount: number;
}

export interface OrderJobEntity {
  _id: string;
  job: JobEntity;
  responsible: CounterpartAccountEntity;
  additionalJobs: InnerOrderJobEntity[];
  price: number;
  discount: number;
  manHours: number;
  manHourPrice: number;
  materials: OrderJobMaterialEntity[];
  isFixedPayment: boolean;
  priceWithDiscount?: number;
  fixedPaymentAmount: number;
  notes: OrderNoteEntity[];
}

export enum OrderStatusChangeReasonEnum {
  NoAnswer = 'no-answer',
  ChangedMind = 'changed-mind-or-no-show',
  TechnicalConsultation = 'technical-consultation',
  ServiceNotProvided = 'service-not-provided',
  NoParts = 'no-parts',
  UnsuitableTime = 'unsuitable-time',
  UnsuitablePrice = 'unsuitable-price',
  WrongNumber = 'wrong-number',
  DuplicateDeal = 'duplicate-deal',
  SupplierOrEmployee = 'supplier-or-employee',
  Spam = 'spam',
}

export enum OrderHistoryEntryTypeEnum {
  StatusChange = 'status-change',
  PriceChange = 'price-change',
}

export interface OrderHistoryEntry {
  _id: string;
  createdAt: string;
  type: OrderHistoryEntryTypeEnum;
  comment: string;
}

export interface OrderStatusChange {
  reason: OrderStatusChangeReasonEnum;
  comment: string;
}

export enum OrderStatusEnum {
  Appointment = 'appointment',
  CallBack = 'call-back',
  Created = 'created',
  Consultation = 'consultation',
  Declined = 'declined',
  Junk = 'junk',
  Negotiations = 'negotiations',
  Ready = 'ready',
  Refund = 'refund',
  Success = 'success',
  Working = 'working',
}

export interface CounterpartAccountEntity {
  _id: string;
  email?: string;
  passwordHash?: string;
  role: CounterpartRoleEnum;
  recoveryToken?: string;
  subdivision?: CarServiceSubdivisionEntity;
  isDummy: boolean;
  wallet: WalletEntity;
  firstName?: string;
  lastName?: string;
  middleName?: string;

  cars: CarEntity[];
  counterpartType: CounterpartTypeEnum;
  counterpartProfile: IndividualAgent;
}

export interface GetOrdersParams {
  pageNumber?: number;
  pageSize?: number;
  sort?: {
    [field: string]: string;
  };
}

export interface GetOrdersInput {
  pageNumber?: number;
  pageSize?: number;
  sortField?: string;
  sortValue?: string;
}

export interface GetOrdersKanbanResponse {
  status: OrderStatusEnum;
  data: {
    items: OrderEntity[];
  };
}

export interface GetOrdersService {
  (input: GetOrdersInput): Promise<ResponseFromService<OrderEntity>>;
}
