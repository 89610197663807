import styles from './styles.module.scss';
import type { HeaderProps } from './types';

import Breadcrumbs from '@/components/UI/Breadcrumbs';

const Header = ({ breadcrumbs, title }: HeaderProps) => (
  <header className={styles.header}>
    <Breadcrumbs items={breadcrumbs} />
    <h1 className={styles.headerTitle}>{title}</h1>
  </header>
);

export default Header;
