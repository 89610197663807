import { useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';

import WarehouseAddresses from '../WarehouseAddresses';

import { FormFields, initialValues } from './constants';
import { warehouseFormSchema } from './schema';
import styles from './styles.module.scss';
import type { WarehouseFormModalProps } from './types';

import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import Input from '@/components/UI/Input';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import Select from '@/components/UI/Select';
import { EVENTS } from '@/constants';
import { createWarehouse } from '@/features/Warehouse/services';
import type { WarehouseEntity } from '@/features/Warehouse/types';
import { useAppProvider } from '@/providers/AppProvider/hooks';
import { dispatchEvent } from '@/utils/event';

const WarehouseFormModal = ({ open, onClose }: WarehouseFormModalProps) => {
  const [warehouse] = useState<WarehouseEntity>(() => initialValues);
  const [isFetching, setIsFetching] = useState(false);
  const { subdivisions } = useAppProvider();

  const handleSubmit = async (values: WarehouseEntity) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      const warehouseAddresses = values.warehouseAddresses?.map(({ _id, ...rest }) => ({
        ...rest,
      }));

      await createWarehouse({
        ...values,
        warehouseAddresses,
      });
      toast.success('Склад создан');
      dispatchEvent(EVENTS.REFRESH_DATA);
      onClose();
    } catch (err) {
      console.log(err);
      if (isAxiosError(err)) {
        toast.error(err?.response?.data || err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal className={styles.modal} isOpen={open}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={warehouse}
        onSubmit={handleSubmit}
        validationSchema={warehouseFormSchema}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Создание склада</h2>
            </ModalHeader>
            <ModalBody>
              <Flex flexDirection="column" rowGap="lg">
                <Grid>
                  <Grid.Item col={4}>
                    <Input
                      name={FormFields.Title}
                      error={errors[FormFields.Title]}
                      label="Название склада"
                      placeholder="Введите название"
                      onChange={handleChange}
                      value={values[FormFields.Title]}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      name={FormFields.Address}
                      error={errors[FormFields.Address]}
                      label="Адрес склада"
                      placeholder="Введите адрес"
                      onChange={handleChange}
                      value={values[FormFields.Address]}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Select
                      name={FormFields.Section}
                      error={errors[FormFields.Section]}
                      label="Подразделение"
                      options={subdivisions.map((subdivision) => ({
                        label: subdivision.title,
                        value: subdivision._id,
                      }))}
                      onChange={(e) => setFieldValue(FormFields.Section, e?.value)}
                    />
                  </Grid.Item>
                </Grid>

                <WarehouseAddresses />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button disabled={isFetching} isLoading={isFetching} type="submit">
                  Создать
                </Button>
                <Button disabled={isFetching} onClick={onClose} variant="secondary">
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default WarehouseFormModal;
