import { ENDPOINT } from './constants';
import type {
  CreatePaymentTerminalService,
  GetPaymentTerminalsRequestPayload,
  GetPaymentTerminalsService,
} from './types';

import api from '@/api';
import { paymentTerminalsSerialize } from '@/features/PaymentTerminal/serializer';

export const getPaymentTerminals: GetPaymentTerminalsService = async ({ pageNumber, pageSize }) => {
  const params: GetPaymentTerminalsRequestPayload = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 20,
  };

  const response = await api.get(ENDPOINT, {
    params,
  });

  return paymentTerminalsSerialize(response.data);
};

export const createPaymentTerminal: CreatePaymentTerminalService = async (body) => {
  const response = await api.post(ENDPOINT, body);

  return response.data;
};

export const editPayment = () => {};
