import type { ResponseFromServer } from '@/types';

export const serverResponseSerializer = <T>(data: ResponseFromServer<T>) => ({
  items: data.items,
  pagination: {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    totalItems: data.totalItems,
    totalPages: data.totalPages,
  },
});
