import type { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { ModalProps } from './types';

ReactModal.setAppElement('#modal');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    overflow: 'visible',
  },
};

const Modal = ({ children, isOpen, className }: PropsWithChildren<ModalProps>) => (
  <ReactModal isOpen={isOpen} style={customStyles}>
    <div className={cn(styles.modal, className)}>{children}</div>
  </ReactModal>
);

export default Modal;
