import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { GridItemProps } from './types';

const GridItem = ({ children, className = '', col = 1 }: PropsWithChildren<GridItemProps>) => (
  <div className={cn(styles[`grid-${col}`], className)}>{children}</div>
);

export default GridItem;
