import * as Yup from 'yup';

import { FormFields } from './constants';

import type { JobCategoryEntity } from '@/features/Job/types';
import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const jobCategoryFormSchema: Yup.ObjectSchema<JobCategoryEntity> = Yup.object().shape({
  [FormFields.Title]: Yup.string().required(messages.required),
});
