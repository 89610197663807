import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { GroupProps } from './types';
import { GroupGap } from './types';

const Group = ({ children, gap = GroupGap.SM }: PropsWithChildren<GroupProps>) => (
  <div className={cn(styles.group, styles[gap])}>{children}</div>
);

export default Group;
