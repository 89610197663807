import { map } from 'lodash';

import { CalendarMarker } from './CalendarMarker';
import { markers } from './constants';
import type { CalendarMarkersProps } from './types';

import Flex from '@/components/UI/Flex';
import Typography from '@/components/UI/Typography';

export const CalendarMarkers = ({ onSelect, variant }: CalendarMarkersProps) => {
  const renderMarkers = () =>
    map(markers, (marker) => (
      <CalendarMarker
        key={marker.color}
        onSelect={onSelect}
        isActive={variant === marker.color}
        variant={marker.color}
      />
    ));

  return (
    <Flex alignItems="center">
      <Typography variant="highlight">Добавить маркер</Typography>
      <Flex columnGap="sm">{renderMarkers()}</Flex>
    </Flex>
  );
};
