import styles from './styles.module.scss';

import IconLoading from '@/components/Icons/IconLoading';
import Flex from '@/components/UI/Flex';

const PageLoader = () => (
  <Flex className={styles.loader} alignItems="center" justifyContent="center">
    <IconLoading />
  </Flex>
);

export default PageLoader;
