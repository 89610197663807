export const setStorage = (name: string, value: unknown) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getStorage = (name: string, defaultValue?: unknown) => {
  try {
    return JSON.parse(localStorage.getItem(name) as string) || defaultValue;
  } catch (err) {
    return null;
  }
};
