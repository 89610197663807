import type { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';

import Button from '@/components/UI/Button';
import type { TabsItemProps } from '@/components/UI/Tabs/types';

const TabsItem = ({ active, children, className, onSelect, value }: PropsWithChildren<TabsItemProps>) => {
  const handleClick = () => {
    if (typeof onSelect !== 'function') {
      return;
    }

    onSelect(value);
  };

  return (
    <Button className={cn(styles.btn, active && styles.btnActive, className)} onClick={handleClick} variant="empty">
      {children}
    </Button>
  );
};

export default TabsItem;
