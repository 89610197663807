import * as Yup from 'yup';

import { FormFields } from '@/features/Agent/components/AgentForm/constants';
import type { IndividualAgent } from '@/types';
import * as expect from '@/utils/validationExpect';
import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const individualAgentFormSchema: Yup.ObjectSchema<IndividualAgent> = Yup.object().shape({
  [FormFields.FirstName]: Yup.string().trim().required(messages.required),
  [FormFields.Phone]: Yup.string().test('phone', messages.validNumber, expect.phoneNumber).required(messages.required),
});

// @ts-ignore
export const carFormSchema: Yup.ObjectSchema<IndividualAgent> = Yup.object().shape({
  [FormFields.Brand]: Yup.string().trim().required(messages.required),
  [FormFields.MiddleName]: Yup.string().trim().required(messages.required),
});
