import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import cn from 'classnames';

import styles from './styles.module.scss';
import type { OrderStatusesProps } from './types';

import Flex from '@/components/UI/Flex';
import OrderStatusChangeModal from '@/features/Order/components/OrderStatusChangeModal';
import OrderStatusesItem from '@/features/Order/components/OrderStatuses/OrderStatusesItem';
import { orderStatuses } from '@/features/Order/constants';
import { changeStatus } from '@/features/Order/services';
import { OrderStatusEnum } from '@/features/Order/types';

const OrderStatuses = ({ currentStatus }: OrderStatusesProps) => {
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [newStatus, setNewStatus] = useState<OrderStatusEnum>(currentStatus);
  const [newCandidateStatus, setNewCandidateStatus] = useState<OrderStatusEnum>();
  const [showChangeStatusNodal, setShowChangeStatusNodal] = useState(false);

  const handleCloseStatusModal = (status?: OrderStatusEnum) => {
    setShowChangeStatusNodal(false);

    if (status) {
      setNewStatus(status as OrderStatusEnum);
    }
  };

  const handleChangeStatus = async (status: OrderStatusEnum) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await changeStatus(id as string, {
        newStatus: status,
      });
      setNewStatus(status);
      toast.success('Статус сделки изменен');
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (currentStatus) {
      setNewStatus(currentStatus);
    }
  }, [currentStatus]);

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <ul className={styles.statuses}>
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Created}
            status={OrderStatusEnum.Created}
            label={orderStatuses[OrderStatusEnum.Created]}
            onClick={handleChangeStatus}
          />
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Consultation}
            label={orderStatuses[OrderStatusEnum.Consultation]}
            status={OrderStatusEnum.Consultation}
            onClick={handleChangeStatus}
          />
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Negotiations}
            label={orderStatuses[OrderStatusEnum.Negotiations]}
            status={OrderStatusEnum.Negotiations}
            onClick={handleChangeStatus}
          />
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.CallBack}
            label={orderStatuses[OrderStatusEnum.CallBack]}
            status={OrderStatusEnum.CallBack}
            onClick={handleChangeStatus}
          />
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Appointment}
            label={orderStatuses[OrderStatusEnum.Appointment]}
            status={OrderStatusEnum.Appointment}
            onClick={handleChangeStatus}
          />
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Working}
            label={orderStatuses[OrderStatusEnum.Working]}
            status={OrderStatusEnum.Working}
            onClick={handleChangeStatus}
          />
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Ready}
            label={orderStatuses[OrderStatusEnum.Ready]}
            status={OrderStatusEnum.Ready}
            onClick={handleChangeStatus}
          />
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Refund}
            label={orderStatuses[OrderStatusEnum.Refund]}
            status={OrderStatusEnum.Refund}
            onClick={handleChangeStatus}
          />
        </ul>
        <ul className={cn(styles.statuses, styles.statusesRight)}>
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Success}
            status={OrderStatusEnum.Success}
            label={orderStatuses[OrderStatusEnum.Success]}
            onClick={(value) => {
              setNewCandidateStatus(value);
              setShowChangeStatusNodal(true);
            }}
          />
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Junk}
            status={OrderStatusEnum.Junk}
            label={orderStatuses[OrderStatusEnum.Junk]}
            onClick={(value) => {
              setNewCandidateStatus(value);
              setShowChangeStatusNodal(true);
            }}
          />
          <OrderStatusesItem
            isActive={newStatus === OrderStatusEnum.Declined}
            status={OrderStatusEnum.Declined}
            label={orderStatuses[OrderStatusEnum.Declined]}
            onClick={(value) => {
              setNewCandidateStatus(value);
              setShowChangeStatusNodal(true);
            }}
          />
        </ul>
      </Flex>
      {showChangeStatusNodal && (
        <OrderStatusChangeModal
          isOpen={showChangeStatusNodal}
          onClose={handleCloseStatusModal}
          currentStatus={currentStatus}
          newStatus={newCandidateStatus as OrderStatusEnum}
        />
      )}
    </>
  );
};

export default OrderStatuses;
