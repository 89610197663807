import Meta from '@/components/Meta';
import WarehouseArrivalView from '@/features/Warehouse/components/WarehouseArrivalView';

const WarehouseArrival = () => (
  <>
    <Meta title="Склад - Поступления" />
    <WarehouseArrivalView />
  </>
);

export default WarehouseArrival;
