import type { CounterpartAccountEntity } from '@/features/Order/types';
import type { IndividualAgent, LegalAgent, UserType } from '@/types';

export const getAgentFullName = (
  agent: IndividualAgent | LegalAgent | UserType | CounterpartAccountEntity,
  useMiddleName = true,
) => {
  if (!agent) {
    return '';
  }

  const { lastName, firstName, middleName } = agent;
  const data = [lastName, firstName];

  if (useMiddleName) {
    data.push(middleName);
  }

  return data.filter(Boolean).join(' ');
};
