import { first } from 'lodash';

import { If } from '@/components/ConditionalRendering/If';
import LoadingIndicatorPage from '@/components/LoadingIndicatorPage';
import Pagination from '@/components/UI/Pagination';
import Table from '@/components/UI/Table';
import { EVENTS } from '@/constants';
import WarehouseStatus from '@/features/Warehouse/components/WarehouseTable/WarehouseStatus';
import { warehouseSerialize } from '@/features/Warehouse/serializer';
import { getWarehouses } from '@/features/Warehouse/services';
import type { WarehouseEntity } from '@/features/Warehouse/types';
import { useEventListener, useRequest } from '@/hooks';

const WarehouseTable = () => {
  const { data, isFetching, fetchData, totalPages, pageNumber, pageSize, totalItems, handlePageChange } =
    useRequest<WarehouseEntity>(getWarehouses, warehouseSerialize);

  useEventListener(EVENTS.REFRESH_DATA, fetchData);

  const renderData = () =>
    data?.map((item) => {
      const warehouse = first(item.warehouseAddresses);

      return (
        <Table.Row key={item._id}>
          <Table.Cell>{item.title}</Table.Cell>
          <Table.Cell>{item.section?.subdivision?.address}</Table.Cell>
          <Table.Cell>{item.address}</Table.Cell>
          <Table.Cell>{warehouse?.floors}</Table.Cell>
          <Table.Cell>{warehouse?.rowsPerFloor}</Table.Cell>
          <Table.Cell>{warehouse?.racksPerRow}</Table.Cell>
          <Table.Cell>{warehouse?.shelvesPerRack}</Table.Cell>
          <Table.Cell>{warehouse?.cellsPerShelf}</Table.Cell>
          <Table.Cell>
            <WarehouseStatus id={item._id as string} isActive={item.isActive as boolean} />
          </Table.Cell>
        </Table.Row>
      );
    });

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <>
      <Table
        header={
          <Table.Row>
            <Table.Cell>Наименование</Table.Cell>
            <Table.Cell>Подразделение</Table.Cell>
            <Table.Cell>Адрес склада</Table.Cell>
            <Table.Cell>Этажей склада</Table.Cell>
            <Table.Cell>Рядов на этаже</Table.Cell>
            <Table.Cell>Стеллажей в ряду</Table.Cell>
            <Table.Cell>Полок в стеллаже</Table.Cell>
            <Table.Cell>Ячеек на полке</Table.Cell>
            <Table.Cell>Статус склада</Table.Cell>
          </Table.Row>
        }
        isFetching={isFetching}
        isEmpty={!data?.length}
      >
        {renderData()}
      </Table>
      <If condition={Boolean(data?.length)}>
        <Pagination
          activePage={pageNumber}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>
    </>
  );
};

export default WarehouseTable;
