import { useState } from 'react';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';

import { FormFields, initialValues } from './constants';
import { jobCategoryFormSchema } from './schema';
import styles from './styles.module.scss';
import type { JobCategoryFormProps } from './types';

import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Input from '@/components/UI/Input';
import Modal from '@/components/UI/Modal';
import ModalBody from '@/components/UI/Modal/ModalBody';
import ModalFooter from '@/components/UI/Modal/ModalFooter';
import ModalHeader from '@/components/UI/Modal/ModalHeader';
import { EVENTS } from '@/constants';
import { createJobCategory } from '@/features/Job/services';
import type { JobCategoryEntity } from '@/features/Job/types';
import { dispatchEvent } from '@/utils/event';

const JobCategoryForm = ({ isOpen, onClose }: JobCategoryFormProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [jobCategory] = useState(initialValues);

  const handleSubmit = async (values: JobCategoryEntity) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await createJobCategory(values);
      toast.success('Раздел добавлен');
      dispatchEvent(EVENTS.REFRESH_DATA);
      onClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <Formik
        enableReinitialize
        initialValues={jobCategory}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validationSchema={jobCategoryFormSchema}
      >
        {({ values, handleChange, errors }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Добавить раздел</h2>
            </ModalHeader>
            <ModalBody>
              <Input
                error={errors[FormFields.Title]}
                label="Раздел"
                name={FormFields.Title}
                onChange={handleChange}
                placeholder="Введите название раздела"
                value={values[FormFields.Title]}
              />
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button disabled={isFetching} isLoading={isFetching} onChange={handleChange} type="submit">
                  Сохранить
                </Button>
                <Button disabled={isFetching} onClick={onClose} variant="secondary">
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default JobCategoryForm;
