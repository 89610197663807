import cn from 'classnames';

import styles from './styles.module.scss';
import type { IconButtonProps } from './types';

import IconLoading from '@/components/Icons/IconLoading';

const IconButton = ({
  className,
  icon: Icon,
  disabled,
  isLoading,
  label,
  onClick,
  size = 'md',
  iconSize,
  type = 'button',
  variant = 'empty',
}: IconButtonProps) => (
  <button
    aria-label={label}
    className={cn(
      styles.button,
      styles[`button--${variant}`],
      styles[`button--size-${size}`],
      iconSize && `[&>svg]:w-[${iconSize}px] ![&>svg]:h-[${iconSize}px]`,
      className,
    )}
    disabled={disabled}
    onClick={onClick}
    type={type}
  >
    {isLoading ? <IconLoading /> : <Icon />}
  </button>
);

export default IconButton;
