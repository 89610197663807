const IconArrowRight = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.94909 13.5667C8.79075 13.5667 8.63242 13.5083 8.50742 13.3833C8.26576 13.1417 8.26576 12.7417 8.50742 12.5L11.0074 10L8.50742 7.5C8.26576 7.25833 8.26576 6.85833 8.50742 6.61667C8.74909 6.375 9.14909 6.375 9.39076 6.61667L12.3324 9.55833C12.5741 9.8 12.5741 10.2 12.3324 10.4417L9.39076 13.3833C9.26576 13.5083 9.10742 13.5667 8.94909 13.5667Z"
      fill="currentColor"
    />
  </svg>
);

export default IconArrowRight;
