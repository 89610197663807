import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { EVENTS, PATHS } from '@/constants';
import { useAppDispatch } from '@/store';
import { resetUser } from '@/store/slices/userSlice';

const useCheckAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handler = () => {
      dispatch(resetUser());
      navigate(PATHS.AUTH);
    };

    document.addEventListener(EVENTS.AUTH_ERROR, handler);

    return () => document.removeEventListener(EVENTS.AUTH_ERROR, handler);
  }, [location.pathname]);
};

export default useCheckAuth;
