import Meta from '@/components/Meta';
import { SettingsDirectoryView } from '@/features/Settings/components/SettingsDirectoryView';

const SettingsDirectory = () => (
  <>
    <Meta title="Конфигуратор" />
    <SettingsDirectoryView />
  </>
);

export default SettingsDirectory;
