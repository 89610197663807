import { useState } from 'react';

import { useFormikContext } from 'formik';
import { ValidationError } from 'yup';

import { FormFields } from './constants';
import { agentSocialMediaFormSchema } from './schema';
import styles from './styles.module.scss';
import type { AgentSocialMediaFormProps } from './types';

import IconLink from '@/components/Icons/IconLink';
import IconTrash from '@/components/Icons/IconTrash';
import Button from '@/components/UI/Button';
import IconButton from '@/components/UI/IconButton';
import Input from '@/components/UI/Input';
import Select from '@/components/UI/Select';
import { socialMedias } from '@/constants';
import type { IndividualAgent, SocialMediaHandlerEntity } from '@/types';
import { getYupInnerErrors } from '@/utils/getYupInnerErrors';

const initialErrors = {
  [FormFields.NickName]: null,
  [FormFields.Platform]: null,
};

const AgentSocialMediaForm = ({ _id, index, onRemove }: AgentSocialMediaFormProps) => {
  const { errors: formikErrors, setFieldValue, setErrors: setFormikErrors } = useFormikContext<IndividualAgent>();
  const [socialMediaItem, setSocialMediaItem] = useState<SocialMediaHandlerEntity>({
    [FormFields.NickName]: null,
    [FormFields.Platform]: null,
  });
  const [errors, setErrors] = useState<SocialMediaHandlerEntity>(initialErrors);

  const handleValidate = async () => {
    setErrors(initialErrors);

    try {
      await agentSocialMediaFormSchema.validate(socialMediaItem, {
        abortEarly: false,
      });

      setFieldValue(`socialMedia.${index}.${FormFields.Platform}`, socialMediaItem.platform);
      setFieldValue(`socialMedia.${index}.${FormFields.NickName}`, socialMediaItem.nickname);

      setFormikErrors({
        ...formikErrors,
        socialMedia: [],
      });

      onRemove(_id);
    } catch (err) {
      if (err instanceof ValidationError) {
        // @ts-ignore
        setErrors(getYupInnerErrors(err));
      }
    }
  };

  const handleChange = (field: FormFields, value: string) => {
    setSocialMediaItem((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div className={styles.container}>
      <fieldset className={styles.formFieldset}>
        <Select
          error={errors?.[FormFields.Platform] as string}
          name={FormFields.Platform}
          placeholder="Выберите соц. сеть"
          options={socialMedias.map(({ id, value }) => ({
            label: value,
            value: id,
          }))}
          onChange={(e) => handleChange(FormFields.Platform, e!.value)}
        />
        <Input
          error={errors?.[FormFields.NickName] as string}
          name={FormFields.NickName}
          placeholder="Контакт"
          onChange={(e) => {
            handleChange(FormFields.NickName, e.currentTarget.value);
          }}
          type="text"
        />
        <Button large onClick={handleValidate} type="button" endIcon={IconLink}>
          Привязать
        </Button>
        <div>
          <IconButton
            icon={IconTrash}
            large
            size="xl"
            label="Удалить форму добавление социальной сети"
            type="button"
            variant="stroke"
            onClick={() => onRemove(_id)}
          />
        </div>
      </fieldset>
    </div>
  );
};

export default AgentSocialMediaForm;
