const IconArrowSubDown = ({ fill = '#0B0A10', size = 16 }: { fill?: string; size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.6665 7.33333L11.9998 10.6667M11.9998 10.6667L8.6665 14M11.9998 10.6667H6.79778C6.0525 10.6667 5.67931 10.6667 5.39437 10.5215C5.14349 10.3937 4.93966 10.1893 4.81183 9.93839C4.6665 9.65318 4.6665 9.28007 4.6665 8.53333V2"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconArrowSubDown;
