import FinanceStatementListTable from './components/FinanceStatementListTable';
import FinanceStatementNavigation from './components/FinanceStatementNavigation';
import styles from './styles.module.scss';

import Header from '@/components/Header';
import Meta from '@/components/Meta';

const FinanceStatement = () => (
  <div>
    <Meta title="Ведомость по контрагентам" />
    <Header title="Ведомость по контрагентам" />
    <div className={styles.content}>
      <FinanceStatementNavigation />
      <FinanceStatementListTable />
    </div>
  </div>
);

export default FinanceStatement;
