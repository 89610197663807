import api from '@/api';
import type { AuthResponse, AuthService, RegisterService } from '@/pages/Auth/types';
import type { UserType } from '@/types';

export const loginUser: AuthService = async (credentials) => {
  const response = await api.post<UserType>('/auth/login', credentials);
  return response.data;
};

export const registerUser: RegisterService = async (credentials) => {
  const response = await api.post<AuthResponse>('/auth/register', credentials);
  return response.data;
};
