import { paymentsSerialize } from './serializer';
import type { GetPaymentsRequestPayload, GetPaymentsService, PaymentsFromServer } from './types';

import api from '@/api';

export const getPayments: GetPaymentsService = async ({ pageNumber, pageSize }) => {
  const params: GetPaymentsRequestPayload = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 20,
  };

  const response = await api.get<PaymentsFromServer>('/payment', {
    params,
  });

  return paymentsSerialize(response.data);
};

export const getWallets = async () => {
  const response = await api.get('/wallet');
  return response.data;
};
