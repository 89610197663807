import api from '@/api';

export const fetchGetCountries = async () => {
  const response = await api.get('/origin-country');
  return response.data;
};

export const fetchCreateCountry = async (body: unknown) => {
  const response = await api.post('/origin-country', body);
  return response.data;
};

export const fetchUpdateCountry = async (id: string, body: unknown) => {
  const response = await api.patch(`/origin-country/${id}`, body);
  return response.data;
};

export const fetchRemoveCountry = async (id: string) => {
  const response = await api.delete(`/origin-country/${id}`);
  return response.data;
};
