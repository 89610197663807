import styles from './styles.module.scss';

import IconSearch from '@/components/Icons/IconSearch';
import Input from '@/components/UI/Input';

const SearchForm = () => (
  <div className={styles.search}>
    <IconSearch />
    <Input placeholder="Поиск" variant="small" />
  </div>
);

export default SearchForm;
