import Meta from '@/components/Meta';
import WarehouseTransferView from '@/features/Warehouse/components/WarehouseTransferView';

const WarehouseTransfer = () => (
  <>
    <Meta title="Склад - Перемещения" />
    <WarehouseTransferView />
  </>
);

export default WarehouseTransfer;
