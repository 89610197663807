import CallsListTable from './components/CallsListTable';

import Header from '@/components/Header';
import Meta from '@/components/Meta';

const Calls = () => (
  <div>
    <Meta title="Звонки" />
    <Header title="Звонки" showSearch={false} />
    <CallsListTable />
  </div>
);

export default Calls;
