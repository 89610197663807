const IconPrint = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9582 6.45829H6.0415C5.69984 6.45829 5.4165 6.17496 5.4165 5.83329V4.16663C5.4165 2.03329 6.40817 1.04163 8.5415 1.04163H11.4582C13.5915 1.04163 14.5832 2.03329 14.5832 4.16663V5.83329C14.5832 6.17496 14.2998 6.45829 13.9582 6.45829ZM6.6665 5.20829H13.3332V4.16663C13.3332 2.74996 12.8748 2.29163 11.4582 2.29163H8.5415C7.12484 2.29163 6.6665 2.74996 6.6665 4.16663V5.20829Z"
      fill="currentColor"
    />
    <path
      d="M10.8332 18.9583H9.1665C7.14984 18.9583 6.0415 17.85 6.0415 15.8333V12.5C6.0415 12.1583 6.32484 11.875 6.6665 11.875H13.3332C13.6748 11.875 13.9582 12.1583 13.9582 12.5V15.8333C13.9582 17.85 12.8498 18.9583 10.8332 18.9583ZM7.2915 13.125V15.8333C7.2915 17.15 7.84984 17.7083 9.1665 17.7083H10.8332C12.1498 17.7083 12.7082 17.15 12.7082 15.8333V13.125H7.2915Z"
      fill="currentColor"
    />
    <path
      d="M15 15.625H13.3333C12.9917 15.625 12.7083 15.3417 12.7083 15V13.125H7.29167V15C7.29167 15.3417 7.00833 15.625 6.66667 15.625H5C2.98333 15.625 1.875 14.5167 1.875 12.5V8.33337C1.875 6.31671 2.98333 5.20837 5 5.20837H15C17.0167 5.20837 18.125 6.31671 18.125 8.33337V12.5C18.125 14.5167 17.0167 15.625 15 15.625ZM13.9583 14.375H15C16.3167 14.375 16.875 13.8167 16.875 12.5V8.33337C16.875 7.01671 16.3167 6.45837 15 6.45837H5C3.68333 6.45837 3.125 7.01671 3.125 8.33337V12.5C3.125 13.8167 3.68333 14.375 5 14.375H6.04167V12.5C6.04167 12.1584 6.325 11.875 6.66667 11.875H13.3333C13.675 11.875 13.9583 12.1584 13.9583 12.5V14.375Z"
      fill="currentColor"
    />
    <path
      d="M14.1668 13.125H5.8335C5.49183 13.125 5.2085 12.8417 5.2085 12.5C5.2085 12.1583 5.49183 11.875 5.8335 11.875H14.1668C14.5085 11.875 14.7918 12.1583 14.7918 12.5C14.7918 12.8417 14.5085 13.125 14.1668 13.125Z"
      fill="currentColor"
    />
    <path
      d="M8.3335 9.79163H5.8335C5.49183 9.79163 5.2085 9.50829 5.2085 9.16663C5.2085 8.82496 5.49183 8.54163 5.8335 8.54163H8.3335C8.67516 8.54163 8.9585 8.82496 8.9585 9.16663C8.9585 9.50829 8.67516 9.79163 8.3335 9.79163Z"
      fill="currentColor"
    />
  </svg>
);

export default IconPrint;
